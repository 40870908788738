import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';

const LIST_NEWSLETTER        = 'LIST_NEWSLETTER';
const ADD_NEWSLETTER         = 'ADD_NEWSLETTER';
const VIEW_NEWSLETTER        = 'VIEW_NEWSLETTER';
const EDIT_NEWSLETTER        = 'EDIT_NEWSLETTER';
const UPDATE_NEWSLETTER_DATA = 'UPDATE_NEWSLETTER_DATA';
const DELETE_NEWSLETTER      = 'DELETE_NEWSLETTER'

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})


// Action types for Newsletter management
export function AC_LIST_NEWSLETTER() {
  Loader();
 return function(dispatch) {
   return authAxios.get(URL.API.ListNewsLetter)
     .then(({ data }) => {
       swal.close()
       if(data.status===403){
         localStorage.removeItem("token");
         window.location='/';
       }
       dispatch({type: LIST_NEWSLETTER, payload:data});
   });
 };
}

export function AC_DELETE_NEWSLETTER(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteNewsLetter,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_NEWSLETTER, payload:data});
      }
    });
  };
}

export function AC_ADD_NEWSLETTER(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddNewsLetter,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_NEWSLETTER, payload:data});
      }
    });
  };
}

export function AC_VIEW_NEWSLETTER(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewNewsLetter,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_NEWSLETTER, payload:data});
    });
  };
}

export function AC_EDIT_NEWSLETTER(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditNewsLetter,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_NEWSLETTER, payload:data});
      }
    });
  };
}
export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_NEWSLETTER_DATA, name:name,value:value})
  };
}
