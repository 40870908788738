import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_TRIP, AC_TRIPS_ACCEPT } from '../../actions/tripaction';

class ViewTrips extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tripId: ''
    }
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({
      tripId: id
    })
    this.props.ViewTrip(id);
  }

  onConfirmed(event) {
    console.log(this.state.tripId)
    this.props.confirmTrip(this.state.tripId)
  }

  render() {
    const tripInfo = this.props.TripReducer.tripInfo;
    console.log('Informations sur les locations')
    // owner.mobilenumber
    console.log(tripInfo)
    if (tripInfo?.customer) {
      var customer = tripInfo.customer.firstname + " " + tripInfo.customer?.lastname;
      var customerEmail = tripInfo?.customer?.email
      var mobilenumber = tripInfo?.customer?.mobilenumber
      var price = tripInfo?.price
      var owner = tripInfo?.owner?.firstname + " " + tripInfo.owner?.lastname;
      var mobilenumerOwner = tripInfo?.owner?.mobilenumber;
      var carname = tripInfo?.brand?.brandname + " " + tripInfo?.model;
      var number = tripInfo?.car?.number?.number;
      var checkin = tripInfo?.checkin;
      var checkout = tripInfo?.checkout;
      var snapshot = tripInfo?.car.snapshot; // Est-ce que le véhicule est mode réservation instantanée
    }
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>View Trip</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">View Trip</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Détail Location</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                      <i className="fas fa-minus"></i></button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label >Nom du client</label>
                    <input type="text" id="firstname" className="form-control" name="firstname" value={customer} disabled />
                  </div>
                  {mobilenumber && <div className="form-group">
                    <label>Email du client</label>
                    <input type="text" id="firstname" className="form-control" name="firstname" value={customerEmail} disabled />
                  </div>}
                  <div className="form-group">
                    <label>Numéro du client</label>
                    <input type="text" id="clientNumber" className="form-control" name="firstname" value={mobilenumber} disabled />
                  </div>

                  {price && <div className="form-group">
                    <label>Prix total estimé</label>
                    <input type="text" id="firstname" className="form-control" name="firstname" value={price} disabled />
                  </div>}

                  <div className="form-group">
                    <label >Nom du propriétaire</label>
                    <input type="text" id="lastname" className="form-control" name="lastname" value={owner} disabled />
                  </div>
                  <div className="form-group">
                    <label >Numéro du propriétaire</label>
                    <input type="text" id="mobilenumerOwner" className="form-control" name="mobilenumerOwner" value={mobilenumerOwner} disabled />
                  </div>
                  <div className="form-group">
                    <label >Description véhicule</label>
                    <input type="text" id="cartype" className="form-control" name="cartype" value={carname} disabled />
                  </div>
                  <div className="form-group">
                    <label >Immatriculation</label>
                    <input type="text" id="cartype" className="form-control" name="cartype" value={number} disabled />
                  </div>
                  <div className="form-group">
                    <label >Date début de location</label>
                    <input type="text" id="checkin" className="form-control" name="checkin" value={checkin} disabled />
                  </div>
                  <div className="form-group">
                    <label >Date fin de location</label>
                    <input type="text" id="checkout" className="form-control" name="checkout" value={checkout} disabled />
                  </div>
                  <div className="form-group">
                    <label >Status Location</label>

                    {tripInfo.tripStatus === 1 ? <select type="text" id="checkout" className="form-control" onChange={(event) => this.onConfirmed(event)}>
                      <option >En attente de confirmation</option>
                      <option value="confirm">Confirmer</option>
                    </select> : ""}
                  </div>
                  {snapshot !== null && <div className="form-group">
                    <label>Reservation instantanée</label>
                    <input type="text" id="snapshot" className="form-control" name="snapshot" value={snapshot ?"Oui" : "Non"} disabled />
                  </div>}
                  <div className="form-group">
                    {tripInfo.tripStatus === 1 ? <div className="callout callout-warning" style={{ marginTop: "20px" }} >
                      <h6>Pending Owner Approval!</h6>
                    </div> : ""}
                    {tripInfo.tripStatus === 0 ? <div className="callout callout-danger" style={{ marginTop: "20px" }}>
                      <h6>Declined by Owner!</h6>
                    </div> : ""}
                    {tripInfo.tripStatus === 3 ? <div className="callout callout-success" style={{ marginTop: "20px" }} >
                      <h6>Owner Accepted!</h6>
                    </div> : ""}
                    {tripInfo.tripStatus === 2 ? <div className="callout callout-success" style={{ marginTop: "20px" }} >
                      <h6>Trip Completed!</h6>
                    </div> : ""}
                    {tripInfo.tripStatus === 4 ? <div className="callout callout-warning" style={{ marginTop: "20px" }} >
                      <h6>Customer Cancelled!</h6>
                    </div> : ""}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">

          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    TripReducer: state.TripReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ViewTrip: AC_VIEW_TRIP, confirmTrip: AC_TRIPS_ACCEPT }, dispatch)
}
const ViewTripComponent = connect(mapStateToProps, mapDispatchToProps)(ViewTrips);

export default ViewTripComponent;
