import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_FAQ } from '../../actions/faqaction';

class AddFaq extends React.Component {
  constructor(props){
    super(props);
    this.state=
        {
          faq                     : '',
          description             : '',
          faqtype                 : '1',
          status                  : '1',
          faqError                : false,
          descriptionerror        : false,
          descriptionvalueError   : false,
        }
        this.validateform = this.validateform.bind(this);
      }

    //validtion
    validateform() {
      var faq              = this.state.faq;
      var description      = this.state.description;
      const faqtype        = this.state.faqtype;
      const status         = this.state.status;
      if(faq) {
        this.setState({faqError:false})
      } else {
        this.setState({faqError:true})
      }
      if(description) {
         this.setState({descriptionerror:false})
      } else {
         this.setState({descriptionerror:true})
      }
      faq           =  faq.charAt(0).toUpperCase() + faq.slice(1);
      description   =  description.charAt(0).toUpperCase() + description.slice(1);
      if(faq && description && status) {
        //Formdata to send Api
          this.setState({faq:"",description:"",faqtype:"1",status:"1"});
          const faqFormData = {
                                question : faq,
                                answer   : description,
                                faqtype  : faqtype,
                                status   : status
                              }
          this.props.AddFaq(faqFormData);
       }
    }
    //onchange values
    onchangeValue(event) {
      const name        = event.target.name;
      const value       = event.target.value;
      if(name ==='faq') {
        if(value){
          this.setState({faq:value,faqError:false})
        } else {
          this.setState({faq:value,faqError:true})
        }
     }
      if(name ==='description') {
      if(value){
      
        this.setState({description:value,descriptionerror:false,descriptionvalueError:false})
       
      }else{
          this.setState({description:value,descriptionerror:true,descriptionvalueError:false})
      }

      }
      if(name ==='faqtype') {
        if(value) {
          this.setState({faqtype:value})
        } else {
          this.setState({faqtype:value})
        }
      }
      if(name ==='status') {
        if(value) {
          this.setState({status:value})
        } else {
          this.setState({status:value})
        }
      }
    }
  render() {
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Add FAQ</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Add FAQ</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add FAQ</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                      <i className="fas fa-minus"></i></button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">FAQ Name</label><span style={{color:"red"}}>*</span>
                    <input type="text" id="inputName" className="form-control" placeholder="Enter FAQ" name="faq" value={this.state.faq} onChange={(e)=>this.onchangeValue(e)}/>
                    {this.state.faqError?<label style={{color:"red"}}>Question is Required</label>:""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputDescription">Description</label><span style={{color:"red"}}>*</span>
                    <textarea id="inputDescription" className="form-control" rows="4" name="description" placeholder="Enter description" value={this.state.description} onChange={(e)=>this.onchangeValue(e) }></textarea>
                    {this.state.descriptionerror?<label style={{color:"red"}}>Description is Required</label>:""}
                    {this.state.descriptionvalueError?<label style={{color:"red"}}>Maximum limit exceeded</label>:""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">faqtype</label><span style={{color:"red"}}>*</span>
                     <select name = "faqtype" className="form-control" value={this.state.faqtype}onChange ={(e) => this.onchangeValue(e)}>
                       <option value = "1">Driver Help</option>
                       <option value = "0">Owner Help</option>
                     </select>
                   </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label><span style={{color:"red"}}>*</span>
                     <select name = "status" className="form-control" value={this.state.status}onChange ={(e) => this.onchangeValue(e)}>
                       <option value = "1">Active</option>
                       <option value = "0">Inactive</option>
                     </select>
                   </div>
                    <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    FaqReducer : state.FaqReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddFaq: AC_ADD_FAQ},dispatch)
}
const AddFaqComponent = connect(mapStateToProps,mapDispatchToProps)(AddFaq);

export default AddFaqComponent;
