const initialState = {
  countryList  : [],
  countryCount : 0,
  countryInfo  : {
    countryname : '',
    countrycode : '',
    status      : '',
    id          : ''
  }
}

function CountryReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_COUNTRY":
      return Object.assign({}, state, {
        countryList  : action.payload.data,
        countryCount : action.payload.count
      })
    case "VIEW_COUNTRY":
      return Object.assign({}, state, {
        countryInfo  : action.payload.data,
      })
    case "DELETE_COUNTRY":
      return Object.assign({}, state, {
        countryList  : action.payload.data,
        countryCount : action.payload.count
      })
    case "EDIT_COUNTRY":
      return Object.assign({}, state, {
        countryInfo  : action.payload.data,
      })
    case "UPDATE_COUNTRY_DATA":
      return editCountryInfo(action,state);
    default:
      return state
  }
}
// functions to update edit countryinfo data
function editCountryInfo(action,state) {
  if(action.name === 'country_name') {
    return Object.assign({}, state, {
        countryInfo  : {
                    ...state.countryInfo,
                     countryname : action.value
                   }
    })
  }
  if(action.name === 'country_code') {
    return Object.assign({}, state, {
        countryInfo  : {
                    ...state.countryInfo,
                     countrycode : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        countryInfo  : {
                    ...state.countryInfo,
                     status : action.value
                   }
    })
  }

  return 1;
}

export default CountryReducer;
