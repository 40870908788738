import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success, Loader, Error } from '../common/swal';
///MANAGEMENT OF USER IN ACTION TYPE
const LIST_USER = 'LIST_USER';
const LIST_OWNER = 'LIST_OWNER';
const VIEW_USER = 'VIEW_USER';
const VIEW_OWNER = 'VIEW_OWNER';
const EDIT_USER = 'EDIT_USER';
const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
const DELETE_USER = 'DELETE_USER';
const UPDATE_VERIFY = 'UPDATE_VERIFY';
const UPDATE_LICENSE = 'UPDATE_LICENSE';
const VIEW_PARKING = 'VIEW_PARKING';
const VIEW_DETAIL_CAR = 'VIEW_DETAIL_CAR';

// const AC_SEND_EMAIL_CAMPAIGN = '';
const accessToken = localStorage.getItem("token");

const authAxios = axios.create({
  headers: {
    Authorization: 'bearer ' + accessToken
  }
})

export function AC_SEND_EMAIL_CAMPAIGN(){
  return function (dispatch){
    Loader();
    return authAxios.post(URL.API.SendEmailCampaign, {}).then(({data}) => {
      swal.close();
      if(data.status === 403) {
        localStorage.removeItem("token");
        window.location = '/';
      }
      else if (data.status === 1) {
        Success(data.message)
      }
           // dispatch({ type: VIEW_DETAIL_CAR, payload: data });
    })
  }
}

export function AC_VIEW_CAR_DETAILS(formData) {
  const id = { id: formData }
  return function (dispatch) {
    Loader();
    return authAxios.post(URL.API.GetCarDetails, id)
      .then(({ data }) => {
        swal.close();
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        }
        dispatch({ type: VIEW_DETAIL_CAR, payload: data });
      });
  };
}

export function AC_VIEW_PARKING(formData) {
  const id = { id: formData }
  return function (dispatch) {
    Loader();
    return authAxios.post(URL.API.GetParking, id)
      .then(({ data }) => {
        swal.close();
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        }
        dispatch({ type: VIEW_PARKING, payload: data });
      });
  };
}

export function AC_USER_VERIFY(data) {
  return function (dispatch) {
    return authAxios.post(URL.API.UpdateUserVerify, data)
      .then(({ data }) => {
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        } else {
          dispatch({ type: UPDATE_VERIFY, payload: data });
        }
      });
  };
}


export function AC_ADD_LICENSE(data) {
  return function (dispatch) {
    return authAxios.post(URL.API.UpdateLicense, data)
      .then(({ data }) => {
        if (data.status) {
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        } else {
          dispatch({ type: UPDATE_LICENSE, payload: data });
        }
      });
  };
}
export function AC_LIST_USER() {
  Loader();
  return function (dispatch) {
    return authAxios.get(URL.API.ListUser)
      .then(({ data }) => {
        swal.close()
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        }
        dispatch({ type: LIST_USER, payload: data });
      });
  };
}

export function AC_LIST_OWNER() {
  Loader();
  return function (dispatch) {
    return authAxios.get(URL.API.ListOwner)
      .then(({ data }) => {
        swal.close()
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        }
        dispatch({ type: LIST_OWNER, payload: data });
      });
  };
}

export function AC_VIEW_USER(formData) {
  const id = { id: formData }
  return function (dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewUser, id)
      .then(({ data }) => {
        swal.close();
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        }
        dispatch({ type: VIEW_USER, payload: data });
      });
  };
}

export function AC_VIEW_OWNER(formData) {
  const id = { id: formData }
  return function (dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewOwner, id)
      .then(({ data }) => {
        swal.close();
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        }
        dispatch({ type: VIEW_OWNER, payload: data });
      });
  };
}

export function AC_DELETE_USER(id) {
  var formData = { id: id }
  return function (dispatch) {
    return authAxios.post(URL.API.DeleteUser, formData)
      .then(({ data }) => {
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        } else {
          if (data.status) {
            Success(data.message)
          }
          else {
            Error(data.message)
          }
          dispatch({ type: DELETE_USER, payload: data });
        }
      });
  };
}


export function AC_EDIT_USER(formData) {
  return function (dispatch) {
    return authAxios.post(URL.API.EditUser, formData)
      .then(({ data }) => {
        if (data.status === 403) {
          localStorage.removeItem("token");
          window.location = '/';
        } else {
          if (data.status) {
            Success(data.message)
          }
          else {
            Error(data.message)
          }
          dispatch({ type: EDIT_USER, payload: data });
        }
      });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name, value) {
  return function (dispatch) {
    dispatch({ type: UPDATE_USER_DATA, name: name, value: value })
  };
}
