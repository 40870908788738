import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Emailvalidate } from '../../common/validate';
import { AC_FORGOT_PASSWORD } from '../../actions/settings';

class Forgotpassword extends React.Component {
  // Constructor
  constructor(props){
    super(props);
    this.state={
      email           : "",
      emailerror      : false,
      emailcheckerror : false
    }
  }
  //Validation
  validateform(){
          const email     = this.state.email;
          var emailcheck  = 0;
          if(email){
                if(!Emailvalidate(email)){
                  this.setState({emailcheckerror:true});
                }
                else{
                  emailcheck=1;
                  this.setState({emailcheckerror:false});
                }
          }
          if(email){
              this.setState({emailerror:false});
          }
          else{
              this.setState({emailerror:true});
          }
          if(email && emailcheck===1){
            const formData = {
              email          : email
            }
            localStorage.setItem("recoveremail",email);
            this.props.forgotPassword(formData);
            //this.props.history.push("/recover")
          }
  }
  // Onchange event
  onchangeValue(event){
          const value   = event.target.value;
          const name    = event.target.name;
          if(name==='email'){
            this.setState({email:value,emailerror:false})
          }
  }
  render() {
    return(

      <body className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <b>Car</b>&nbsp;Rental
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password</p>
              <div className="input-group mb-3">
                <input type="email" className="form-control" placeholder="Email" name="email" value={this.state.email} onChange={(e)=>this.onchangeValue(e)}/>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              {this.state.emailerror?<label style={{color:'red'}}>Email Required</label>:""}
              {this.state.emailcheckerror?<label style={{color:'red'}}>Enter valid email</label>:""}
              <div className="row">
                <div className="col-12">
                    <button type="submit" className="btn btn-primary btn-block" onClick={this.validateform.bind(this)}>Request new password</button>
                </div>
              </div>
              <div className="mt-3 mb-1">
              <Link to='/' className="mt-3 mb-1">
                <a href="">Login</a>
              </Link>
              </div>
          </div>
        </div>
      </div>
    </body>

    )
  }
}
function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({forgotPassword:AC_FORGOT_PASSWORD},dispatch)
}
const ForgotpasswordComponent = connect(mapStateToProps,mapDispatchToProps)(Forgotpassword);

export default ForgotpasswordComponent;
