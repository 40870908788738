import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';

// Action types for Currency management
const LIST_CURRENCY         = 'LIST_CURRENCY';
const ADD_CURRENCY          = 'ADD_CURRENCY';
const EDIT_CURRENCY         = 'EDIT_CURRENCY';
const VIEW_CURRENCY         = 'VIEW_CURRENCY';
const UPDATE_CURRENCY_DATA  = 'UPDATE_CURRENCY_DATA';
const DELETE_CURRENCY       = 'DELETE_CURRENCY';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})
export function AC_UPDATE_CURRENCYSTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateCurrencyStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: LIST_CURRENCY, payload:data});
       }
    });
  };
}

export function AC_LIST_CURRENCY() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListCurrency,{})
      .then(({ data }) => {
        swal.close();
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_CURRENCY, payload:data});
    });
  };
}

export function AC_DELETE_CURRENCY(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteCurrency,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_CURRENCY, payload:data});
      }
    });
  };
}

export function AC_ADD_CURRENCY(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddCurrency,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_CURRENCY, payload:data});
      }
    });
  };
}
export function AC_VIEW_CURRENCY(formData) {
  const id = { id : formData }
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewCurrency,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_CURRENCY, payload:data});
    });
  };
}
export function AC_EDIT_CURRENCY(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditCurrency,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_CURRENCY, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_CURRENCY_DATA, name:name,value:value})
  };
}
