import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE,AC_UPDATE_ADMIN_SETTINGS } from '../../actions/settings';
import {Imagevalidation} from '../../common/validate.js';
import API from '../../common/api';

class SiteSettings extends React.Component {
  constructor(props){
    super(props);
    this.state=
    {
      imgSrc           : 'profile.jpg',
      imgSource        : 'icon.jpg',
      faviconError     : false,
      numberError      : false,
      logoError        : false,
      emailError       : false,
      faviconsrcvalue  : false,
      logosrcvalue     : false,
      faviconcheck     : false,
      logocheck        : false
    }
    this.validateform=this.validateform.bind(this);
  }
    //validation
    validateform() {
        const siteInfo      = this.props.SettingsReducer.siteInfo;
        const id            = this.props.SettingsReducer.settingId;
        const faviconcheck  = this.state.faviconcheck;
        const logocheck     = this.state.logocheck;
        var logo            = siteInfo.logo;
        var favicon         = siteInfo.favicon;
        if(!faviconcheck && !logocheck) {
            const formData = new FormData();
            formData.append('sitename', siteInfo.sitename);
            formData.append('sitecontactemail', siteInfo.sitecontactemail);
            formData.append('sitecontactnumber', siteInfo.sitecontactnumber);
            formData.append('footercontent', siteInfo.footercontent);
            formData.append('favicon', favicon);
            formData.append('logo', logo);
            this.props.updateAdminSettings(formData,id,'Site');
        }
    }
    handleKeyPress(event){
        const value       =  event.target.value;
        if((value.toString().length)>=10){
          event.preventDefault();
        }
    }
    //onchange values
    onChangeValue(event)  {
         const name   = event.target.name;
         var value    = event.target.value;
         if(name==="favicon"){
           value = event.target.files[0];
           this.setState({faviconvalue:value});
           if(value){
               const imgvalidate   =  Imagevalidation(value);
               if(imgvalidate) {
                     var reader          = new FileReader();
                     var url             = reader.readAsDataURL(value);
                     reader.onloadend    = function(e){
                     this.setState({imgSrc : [reader.result],faviconsrcvalue:true,faviconcheck:false})
                     }.bind(this);
              } else {
                this.setState({imgSrc : "",faviconcheck:true})
              }
          }
        }
        if(name==="logo"){
           value = event.target.files[0];
           this.setState({logovalue:value});
           if(value){
               const imgvalidate   =  Imagevalidation(value);
               if(imgvalidate) {
                     var reader          = new FileReader();
                     var url             = reader.readAsDataURL(value);
                     reader.onloadend    = function(e){
                     this.setState({imgSource : [reader.result],logosrcvalue:true,logocheck:false})
                     }.bind(this);
               } else {
                  this.setState({imgSource : "",logocheck:true})
               }
            }
        }
         this.props.handleInputChange(name,value,'Site');
    }

  render() {
    const siteInfo  = this.props.SettingsReducer.siteInfo;
    var name       = "";
    var email      = "";
    var number     = "";
    var favicon    = "";
    var footer     = "";
    var logo       = "";

    if(siteInfo) {
       name       = siteInfo.sitename;
       email      = siteInfo.sitecontactemail;
       number     = siteInfo.sitecontactnumber;
       favicon    = siteInfo.favicon;
       footer     = siteInfo.footercontent;
       logo       = siteInfo.logo;
    }
        return(
              <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                       <div className="col-sm-6">
                          <h1>Site Settings</h1>
                       </div>
                      <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-right">
                              <li className="breadcrumb-item">Home</li>
                              <li className="breadcrumb-item active">Site Settings</li>
                          </ol>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card card-primary">
                        <div className="card-header">
                          <h3 className="card-title">Site Settings</h3>
                        </div>

                            <div className="card-body">
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Site Contact Email</label>
                                <input type="text" name="sitecontactemail" className="form-control"  placeholder="Site Contact Email" value={email}  onChange={(e)=>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Site Name</label>
                                <input type="text" name="sitename" className="form-control" placeholder="Site Name" value={name}onChange={(e)=>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Site Contact Number</label>
                                <input type="number" name="sitecontactnumber" className="form-control"  placeholder="Site Contact Number" value={number} onKeyPress={(e)=>this.handleKeyPress(e)} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Footer Content </label>
                                <input type="text" name="footercontent" className="form-control"  placeholder="Footer Content" value={footer}onChange={(e)=>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Site Logo </label>
                                <div className="input-group">
                                  <div className="custom-file">
                                    <input type="file" name="logo" className="custom-file-input" id="exampleInputFile" onChange={(e)=>this.onChangeValue(e)}/>
                                    <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                  </div>
                                 <div className="input-group-append">
                                <button className="input-group-text" id="">Upload</button>
                              </div>
                            </div>
                            {this.state.logosrcvalue? <img src={this.state.imgSource} style={{height:"120px",width:"160px",padding:"10px 1px"}} alt=""/> :logo? <img src={API.LIVEURL+"uploads/"+logo} style={{height:"120px",width:"160px",padding:"10px 1px"}} alt=""/>:<img src="profile.jpg" style={{height:"120px",width:"160px" ,padding:"10px 1px"}} alt=""/>}
                            {this.state.logocheck? <label style={{color:'red'}}>Image format is Invalid</label>:""}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Site Favicon </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input type="file" name="favicon" className="custom-file-input" id="exampleInputFile" onChange={(e)=>this.onChangeValue(e)}/>
                              <img src={this.state.file} alt=""/>
                              <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text" id="">Upload</span>
                            </div>
                          </div>
                         {this.state.faviconsrcvalue? <img src={this.state.imgSrc} style={{height:"120px",width:"160px",padding:"10px 1px"}} alt=""/> :favicon? <img src={API.LIVEURL+"uploads/"+favicon} style={{height:"120px",width:"160px",padding:"10px 1px"}} alt=""/>:<img src="profile.jpg" style={{height:"120px",width:"160px",padding:"10px 1px"}} alt=""/>}
                         {this.state.faviconcheck? <label style={{color:'red'}}>Image format is Invalid</label>:""}
                       </div>
                       <div className="card-footer">
                        <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                       </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      )
   }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({handleInputChange:AC_HANDLE_INPUT_CHANGE,updateAdminSettings:AC_UPDATE_ADMIN_SETTINGS},dispatch)
}
const SiteSettingsComponent = connect(mapStateToProps,mapDispatchToProps)(SiteSettings);

export default SiteSettingsComponent;
