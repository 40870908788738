const initialState = {
  settingId        : "",
  auth             : false,
  socialMediaInfo  : {},
  webMasterInfo    : {},
  smtpInfo         : {},
  tripInfo         : {},
  profileInfo      : {},
  siteInfo         : {},
  appSettingInfo   : {},
  isTokenExpired   : false
}

function SettingsReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ADMIN_SETTINGS":
      return Object.assign({}, state, {
        settingId        : action.payload._id,
        socialMediaInfo  : action.payload.socialmedia,
        webMasterInfo    : action.payload.webmaster,
        smtpInfo         : action.payload.smtp,
        profileInfo      : action.payload.profile,
        siteInfo         : action.payload.site,
        appSettingInfo   : action.payload.appsettinginfo
      })
    case "AUTHORIZATION":
        return Object.assign({}, state, {
          auth           : action.payload.status,
        })
    case "HANDLE_INPUT_CHANGE":
    if(action.module === "Social media") {
      return {
          ...state,
          socialMediaInfo : {
              ...state.socialMediaInfo,
              [action.name] : action.value
          }
        }
      } else if(action.module === "webmaster") {
      return {
          ...state,
          webMasterInfo : {
              ...state.webMasterInfo,
              [action.name] : action.value
          }
      }
    } else if(action.module === "Smtp") {
      return {
          ...state,
          smtpInfo : {
              ...state.smtpInfo,
              [action.name] : action.value
          }
        }
    } else if(action.module === "pasttrip") {
      return {
          ...state,
          tripInfo : {
              ...state.tripInfo,
              [action.name] : action.value
          }
        }
    }
    else if(action.module === "newtrip") {
      return {
          ...state,
          tripInfo : {
              ...state.tripInfo,
              [action.name] : action.value
          }
        }
    } else if(action.module === "Profile") {
      return {
          ...state,
          profileInfo : {
              ...state.profileInfo,
              [action.name] : action.value
          }
        }
    } else if(action.module === "Site") {
      return {
          ...state,
          siteInfo : {
              ...state.siteInfo,
              [action.name] : action.value
          }
        }
    } else if(action.module === "Appsetting") {
    return {
        ...state,
        appSettingInfo : {
            ...state.appSettingInfo,
            [action.name] : action.value
        }
      }
  }
    case "UPDATE_ADMIN_SETTINGS":
      return Object.assign({}, state, {
        socialMediaInfo  : action.payload.socialmedia,
        webMasterInfo    : action.payload.webmaster,
        tripInfo         : action.payload.tripinfo,
        smtpInfo         : action.payload.smtp,
        profileInfo      : action.payload.profile,
        siteInfo         : action.payload.site,
        appSettingInfo   : action.payload.appsettinginfo
      })
    default:
      return state
  }
}

export default SettingsReducer;
