import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_NOTIFICATION,AC_EDIT_NOTIFICATION,AC_HANDLE_INPUT_CHANGE} from '../../actions/notificationaction';
class EditNotification extends React.Component {
  constructor(props) {
     super(props);
     this.state = {
                     nameError            : false,
                     descriptionError     : false,
                   }
      this.validateform=this.validateform.bind(this);
   }
       componentWillMount() {
       const id = this.props.match.params.id;
       this.props.ViewNotification(id);
   }
   //validtion
  validateform(){
    var name               = this.props.NotificationReducer.notificationInfo.name;
    var description        = this.props.NotificationReducer.notificationInfo.description;
    var emailnotifier      = this.props.NotificationReducer.notificationInfo.emailnotifier;
    var smsnotifier        = this.props.NotificationReducer.notificationInfo.smsnotifier;
    var appnotifier        = this.props.NotificationReducer.notificationInfo.appnotifier;
    const id               = this.props.NotificationReducer.notificationInfo.id;
    if(name)
    {
        this.setState({nameError:false})
    }
    else{
        this.setState({nameError:true})
    }
    if(description)
    {
        this.setState({descriptionError:false})
    }
    else{
        this.setState({descriptionError:true})
    }
    name               =  name.charAt(0).toUpperCase() + name.slice(1);
    description        =  description.charAt(0).toUpperCase() + description.slice(1);
    if(name && description ) {
      // Formdata to send Api
      const notificationFormData = {
                            name          : name,
                            description   : description,
                            emailnotifier : emailnotifier,
                            smsnotifier   : smsnotifier,
                            appnotifier   : appnotifier,
                            id            : id
                          }
      this.props.EditNotification(notificationFormData);
      this.props.ViewNotification(id);
    }
  }
  //onchange values
  onChangeValue(event){
    const name        =  event.target.name;
    var value         =  event.target.value;
    if(name === "emailnotifier" || name === "smsnotifier" || name === "appnotifier"){
      value=event.target.checked;
    }
    
    this.props.HandleInputChange(name,value)
   }
   render() {

     const notificationInfo   = this.props.NotificationReducer.notificationInfo;
     const name               = notificationInfo.name;
     const description        = notificationInfo.description;
     const emailnotifier      = notificationInfo.emailnotifier;
     const smsnotifier        = notificationInfo.smsnotifier;
     const appnotifier        = notificationInfo.appnotifier;
     return(
        <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit Notification</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Edit Notification</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Edit Notification</h3>
                  </div>
                  
                    <div className="card-body">
                      <div className="form-group">
                        <label >Notification Name</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="name" id="inputname" placeholder="Enter Name" value={name} onChange={(e)=> this.onChangeValue(e)}/>
                        {this.state.nameError?<label style={{color:'red'}}>Notification Name is Required</label>:""}
                      </div>
                      <div className="form-group">
                        <label>Description</label><span style={{color:"red"}}>*</span>
                        <textarea className="form-control" name="description" id="inputcode" placeholder="Enter Description" value={description} onChange={(e)=> this.onChangeValue(e)}/>
                        {this.state.descriptionError?<label style={{color:'red'}}>Description is Required</label>:""}
                      </div>
                      <label>Email Notifier<input type="checkbox" name="emailnotifier" id="emailnotifier"  checked={emailnotifier === true} className="form-control" style={{width:"25px"}}  onChange={(e)=> this.onChangeValue(e)}/></label>
                      <br/>
                      <label>SMS Notifier<input type="checkbox" name="smsnotifier" id="smsnotifier"  checked={smsnotifier === true} className="form-control" style={{width:"25px"}}  onChange={(e)=> this.onChangeValue(e)}/></label>
                      <br/>
                      <label>App Notifier<input type="checkbox" name="appnotifier" id="appnotifier"  checked={appnotifier === true} className="form-control" style={{width:"25px"}}  onChange={(e)=> this.onChangeValue(e)}/></label>
                      </div>
                      <div className="card-footer">
                      <button type="button" className="btn btn-primary" onClick={this.validateform}>Update</button>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    NotificationReducer : state.NotificationReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewNotification: AC_VIEW_NOTIFICATION,EditNotification:AC_EDIT_NOTIFICATION,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditNotificationComponent = connect(mapStateToProps,mapDispatchToProps)(EditNotification);

export default EditNotificationComponent;
