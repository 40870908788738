const initialState = {
  rentList  : [],
  rentCount : 0,
  rentInfo  : {
    brandname : '',
    image     : '',
    year      : '',
    rating    : '',
    country   : '',
    price     : '',
    status    : '',
  }
}

function RentReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_RENTEDCAR":
      return Object.assign({}, state, {
        rentList  : action.payload.data,
        rentCount : action.payload.count
      })
    case "VIEW_RENTEDCAR":
      return Object.assign({}, state, {
        rentInfo  : action.payload.data,
      })
    case "EDIT_RENTEDCAR":
        return Object.assign({}, state, {
          rentInfo : action.payload.data,
      })
    case "UPDATE_RENTEDCAR_DATA":
      return editrentInfo(action,state);
    default:
      return state
  }
}

// functions to update edit rent data
function editrentInfo(action,state) {
  if(action.name === 'brandname') {
    return Object.assign({}, state, {
        rentInfo  : {
                    ...state.rentInfo,
                     brandname  : action.value
                   }
    })
  }
  if(action.name === 'image') {
    return Object.assign({}, state, {
        rentInfo : {
                    ...state.rentInfo,
                     image : action.value
                   }
    })
  }
  if(action.name === 'year') {
    return Object.assign({}, state, {
        rentInfo  : {
                    ...state.rentInfo,
                      year : action.value
                   }
    })
  }
  if(action.name === 'rating') {
    return Object.assign({}, state, {
        rentInfo  : {
                    ...state.rentInfo,
                      rating : action.value
                   }
    })
  }

  return 1;
}

export default RentReducer;
