import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_PAYEMENTGATEWAY,AC_EDIT_PAYEMENTGATEWAY,AC_HANDLE_INPUT_CHANGE} from '../../actions/payementgatewayaction';

class EditPayementGateway extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
                      nameerror   : false,
                      modeerror   : false,
                      apikeyerror : false,
                      statuserror : false,
                    }
                    this.validateform = this.validateform.bind(this);
    }

    componentWillMount() {
      const id = this.props.match.params.id;
      this.props.ViewPayementGateway(id);
    }

    //validtion
    validateform() {
      const name      = this.props.PayementgatewayReducer.payementgatewayInfo.name;
      const mode      = this.props.PayementgatewayReducer.payementgatewayInfo.mode;
      const apikey    = this.props.PayementgatewayReducer.payementgatewayInfo.apikey;
      const status    = this.props.PayementgatewayReducer.payementgatewayInfo.status;
      const id        = this.props.PayementgatewayReducer.payementgatewayInfo.id;
      if(name) {
        this.setState({nameError:false})
      } else {
        this.setState({nameError:true})
      }
      if(mode) {
         this.setState({modeerror:false})
      } else {
         this.setState({modeerror:true})
      }
      if(apikey) {
         this.setState({apikeyerror:false})
      } else {
         this.setState({apikeyerror:true})
      }

      if(name && mode && apikey) {
        // Formdata to send Api
          const formData = {
                                name   : name,
                                mode   : mode,
                                apikey : apikey,
                                status : status,
                                id     : id
                              }
          this.props.EditPayementGateway(formData);
      }
    }

    //onchange values
    onchangeValue(event) {
       const name        = event.target.name;
       const value       = event.target.value;
       this.props.HandleInputChange(name,value)
    }
  render() {
    const payementgatewayInfo  = this.props.PayementgatewayReducer.payementgatewayInfo;
    const name    = payementgatewayInfo.name;
    const mode    = payementgatewayInfo.mode;
    const apikey  = payementgatewayInfo.apikey;
    const status  = payementgatewayInfo.status;
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit Payement Gateway</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Edit Payement Gateway</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Edit Payement Gateway</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                      <i className="fas fa-minus"></i></button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">Name</label><span style={{color:"red"}}>*</span>
                    <input type="text" id="inputName" className="form-control" name="name" value={name} onChange={(e)=>this.onchangeValue(e)}/>
                    {this.state.nameerror?<label style={{color:"red"}}>Name is Required</label>:""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">Payement Mode</label>
                     <select name = "mode" value={mode} className="form-control" onChange ={(e) => this.onchangeValue(e)}>
                       <option value="Sandbox" >Sandbox</option>
                       <option value="Live" >Live</option>
                     </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputDescription">Api key</label>
                    <input type="text" id="inputDescription" className="form-control" rows="4" name="apikey" value={apikey} onChange={(e)=>this.onchangeValue(e)}/>
                    {this.state.apikeyerror?<label style={{color:"red"}}>Api key is Required</label>:""}
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                     <select name = "status" className="form-control" onChange ={(e) => this.onchangeValue(e)}>
                       <option value={1} selected={status === 1}>Active</option>
                       <option value={0} selected={status === 0}>Inactive</option>
                     </select>
                   </div>
                  <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateform}>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    PayementgatewayReducer : state.PayementgatewayReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewPayementGateway: AC_VIEW_PAYEMENTGATEWAY,EditPayementGateway:AC_EDIT_PAYEMENTGATEWAY,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditPayementGatewayComponent = connect(mapStateToProps,mapDispatchToProps)(EditPayementGateway);

export default EditPayementGatewayComponent;
