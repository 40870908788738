const initialState = {
  configurationList  : [],
  configurationCount : 0,
  configoptionList   : [],
  configoptionCount  : 0,
  configurationInfo  : {
    name         : '',
    slug         : '',
    description  : '',
    id           : ''
  },
  }

function ConfigurationReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_CONFIGURATION":
      return Object.assign({}, state, {
        configurationList  : action.payload.data,
        configurationCount : action.payload.count
      })
    case "LIST_CONFIGURATION":
      return Object.assign({}, state, {
          configurationList  : action.payload.data,
          configurationCount : action.payload.count
      })
    case "VIEW_CONFIGURATION":
      return Object.assign({}, state, {
          configurationInfo  : action.payload.data,
          configoptionList   : action.payload.optionList.length > 0 ? action.payload.optionList:[],
          configoptionCount  : action.payload.count
      })
    case "DELETE_CONFIGURATION":
      return Object.assign({}, state, {
          configurationList  : action.payload.data,
          configurationCount : action.payload.count
      })
    case "EDIT_CONFIGURATION":
      return Object.assign({}, state, {
          configurationInfo  : action.payload.data,
      })
      case "EDIT_CONFIGOPTION":
        return Object.assign({}, state, {
            configoptionList  : action.payload.data,
            configoptionCount : action.payload.count
        })
    case "UPDATE_CONFIGURATION_DATA":
      return editConfigurationInfo(action,state);
    case "ADD_CONFIGOPTION":
      return Object.assign({}, state, {
        configoptionList  : action.payload.data,
        configoptionCount : action.payload.count
      })
    case "LIST_CONFIGOPTION":
      return Object.assign({}, state, {
          configoptionList  : action.payload.data,
          configoptionCount : action.payload.count
      })
    case "DELETE_CONFIGOPTION":
    return Object.assign({}, state, {
      configoptionList  : action.payload.data,
      configoptionCount : action.payload.count
    })
    default:
      return state
  }
}

// functions to update edit Configurationinfo data
function editConfigurationInfo(action,state) {
  if(action.name === 'name') {
    return Object.assign({}, state, {
      configurationInfo  : {
                    ...state.configurationInfo,
                     name : action.value
                   }
    })
  }
  if(action.name === 'description') {
    return Object.assign({}, state, {
      configurationInfo  : {
                    ...state.configurationInfo,
                     description : action.value
                   }
    })
  }
  if(action.name === 'slug') {
    return Object.assign({}, state, {
      configurationInfo  : {
                    ...state.configurationInfo,
                     slug : action.value
                   }
    })
  }


  return 1;
}

export default ConfigurationReducer;
