import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_COUNTRY} from '../../actions/countryaction';

class AddCountry extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        countryname         :   '',
        countrycode         :   '',
        status              :   '1',
        countrynameError    :   false,
        countrycodeError    :   false,
    }
    this.validateform=this.validateform.bind(this);
  }
  //validtion
  validateform(){
    var country_name      =   this.state.countryname;
    var country_code      =   this.state.countrycode;
    const status          =   this.state.status;
    if(country_name)
    {
        this.setState({countrynameError:false})
    }
    else {
        this.setState({countrynameError:true})
    }
    if(country_code)
    {
        this.setState({countrycodeError:false})
    }
    else{
        this.setState({countrycodeError:true})
    }
    country_name  =  country_name.charAt(0).toUpperCase() + country_name.slice(1);
    country_code  =  country_code.toUpperCase();
    if(country_name && country_code && status) {
          // Formdata to send Api
        this.setState({countryname:"",countrycode:"",status:"1"});
        const countryFormData = {
                              countryname   : country_name,
                              countrycode   : country_code,
                              status        : status
                            }
        this.props.AddCountry(countryFormData);
    }
  }
    //onchange values
    onChangeValue(event) {
      const name  = event.target.name;
      const value = event.target.value;
      if(name === 'countryname') {
        if(value){
          this.setState({countryname:value,countrynameError:false})
        } else {
          this.setState({countryname:value,countrynameError:true})
        }
      }
      if(name === 'countrycode') {
        if(value){
          this.setState({countrycode:value,countrycodeError:false})
        } else {
          this.setState({countrycode:value,countrycodeError:true})
        }
      }
      if(name === 'status') {
        if(value !== -1) {
          this.setState({status:value})
        }
      }
  }
  render() {
    return(
        <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Add Country</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Add Country</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Country</h3>
                  </div>
                  
                    <div className="card-body">
                      <div className="form-group">
                        <label >Country Name</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="countryname" id="countryname" placeholder="Country Name" value={this.state.countryname} onChange={(e)=> this.onChangeValue(e)}/>
                        {this.state.countrynameError?<label style={{color:'red'}}>Country name is Required</label>:""}
                      </div>
                      <div className="form-group">
                        <label>Country Code</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="countrycode" id="countrycode" placeholder="Country Code" value={this.state.countrycode} onChange={(e)=> this.onChangeValue(e)}/>
                        {this.state.countrycodeError?<label style={{color:'red'}}>Country code is Required</label>:""}
                      </div>
                      <div className="form-group">
                         <label>Status</label><span style={{color:"red"}}>*</span>
                         <select name="status" className="form-control" value={this.state.status}onChange ={(e) => this.onChangeValue(e)}>
                           <option value="1">Active</option>
                           <option value="0">Inactive</option>
                         </select>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CountryReducer : state.CountryReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddCountry: AC_ADD_COUNTRY},dispatch)
}
const AddCountryComponent = connect(mapStateToProps,mapDispatchToProps)(AddCountry);

export default AddCountryComponent;
