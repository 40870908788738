const initialState = {
  payementgatewayList  : [],
  payementgatewayCount : 0,
  payementgatewayInfo  : {
                  name   : '',
                  mode   : '',
                  apikey : '',
                  status : '',
                  id     : ''
  }
}

function PayementgatewayReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_PAYEMENTGATEWAY":
      return Object.assign({}, state, {
        payementgatewayList  : action.payload.data,
        payementgatewayCount : action.payload.count
      })
    case "LIST_PAYEMENTGATEWAY":
      return Object.assign({}, state, {
        payementgatewayList  : action.payload.data,
        payementgatewayCount : action.payload.count
      })
    case "VIEW_PAYEMENTGATEWAY":
      return Object.assign({}, state, {
        payementgatewayInfo  : action.payload.data,
      })
    case "DELETE_PAYEMENTGATEWAY":
      return Object.assign({}, state, {
        payementgatewayList  : action.payload.data,
        payementgatewayCount : action.payload.count,

      })
    case "EDIT_PAYEMENTGATEWAY":
      return Object.assign({}, state, {
        payementgatewayInfo  : action.payload.data,
      })
    case "UPDATE_PAYEMENTGATEWAY_DATA":
      return editPayementgatewayInfo(action,state);
    default:
      return state
    }
}

// functions to update edit brand management
function editPayementgatewayInfo(action,state) {
  if(action.name === 'name') {
    return Object.assign({}, state, {
        payementgatewayInfo  : {
                    ...state.payementgatewayInfo,
                     name : action.value
                   }
    })
  }
  if(action.name === 'mode') {
    return Object.assign({}, state, {
        payementgatewayInfo  : {
                    ...state.payementgatewayInfo,
                    mode : action.value
                   }
    })
  }
  if(action.name === 'apikey') {
    return Object.assign({}, state, {
        payementgatewayInfo  : {
                    ...state.payementgatewayInfo,
                    apikey : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        payementgatewayInfo  : {
                    ...state.payementgatewayInfo,
                     status : action.value
                   }
    })
  }
  return 1;
}

export default PayementgatewayReducer;
