const initialstate = {
  pagesList : [],
  pagesCount : 0,
  pagesInfo  : {
    name         :'',
    slug         :'',
    description  :'',
    id           :''
  }
}

// Pages  Reducer
function PagesReducer (state = initialstate, action) {
  switch (action.type) {
    case "VIEW_PAGES":
      return Object.assign({}, state, {
        pagesInfo  : action.payload.data,
      });

    case 'LIST_PAGES':
    return Object.assign({}, state, {
      pagesList  : action.payload.data,
      pagesCount : action.payload.count
    });

    case 'EDIT_PAGES':
      return Object.assign({}, state, {
        pagesInfo  : action.payload.data,
      });

    case "DELETE_PAGES":
      return Object.assign({}, state, {
        pagesList  : action.payload.data,
        pagesCount : action.payload.count
      })
    case "UPDATE_PAGES_DATA":
      return editPagesInfo(action,state);

    default:
      return state
  }
}
function editPagesInfo(action,state) {
  if(action.name === 'name') {
    return Object.assign({}, state, {
        pagesInfo  : {
                    ...state.pagesInfo,
                     name : action.value
                   }
    })
  }
  if(action.name === 'slug') {
    return Object.assign({}, state, {
        pagesInfo  : {
                    ...state.pagesInfo,
                     slug : action.value
                   }
    })
  }
  if(action.name === 'subject') {
    return Object.assign({}, state, {
        pagesInfo  : {
                    ...state.pagesInfo,
                     description : action.value
                   }
    })
  }
  return 1;
}

export default PagesReducer;
