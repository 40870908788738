import React from 'react';
import { Redirect  } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { AC_LIST_LANGUAGE,AC_DELETE_LANGUAGE,AC_UPDATE_LANGUAGESTATUS} from '../../actions/languageaction';


class LanguageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs :[
        { "header" : "#",field:"#", valueGetter :"node.rowIndex+1",width:"120",
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { "header" : "languagename", field :"languagename",width:"250",
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { "header" : "languagecode", field :"languagecode",width:"250",
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { "header" : "status", field :"status",width:"300",
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
          },
          cellRenderer: function(params) {
           if(params.data.status) {
             return '<span class="badge badge-success" data-action-type="Status">Active</span>';
           } else {
             return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Inactive</span>';
           }
         }},
        { headerName: 'Actions', width: "300",sortable: false,filter:false,
          template:
           `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           View
           </div>
           <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Edit">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Edit"></i>
           Edit
           </div>
           <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
           Delete
           </div>
           </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ]
    }
  }
  componentDidMount() {
    this.props.ListLanguage();
  }
  updateStatus(event){
    const data = {
       id     : event._id,
       status : !event.status
    }
    console.log("===data===",data);
    this.props.UpdateLanguageStatus(data);
  }
  deleteLanguage(id) {
    swal({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this imaginary file!",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.DeleteLanguage(id);
         } else {
           console.log('-=-=-=-=-=cancel-=-=-=-=',);
         }
   });
  }
  onSortChanged = e => {
   e.api.refreshCells();
 }
  onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    console.log("===data 1===",event.data);
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
    if(value === 'Edit'){
      this.setState({redirect:'Edit',id:event.data._id})
    }
    if(value === 'Delete'){
      this.deleteLanguage(event.data._id)
    }
    if(value === 'Status'){
      this.updateStatus(event.data);
    }
  }
  render() {
    // const languageCount     = this.props.LanguageReducer.languageCount;
    const languageList      = this.props.LanguageReducer.languageList;
    // const languageListArray = [];
    if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/editLanguage/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/viewLanguage/'+this.state.id}/>
    }
    return(
            <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Language List</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">Language List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Languages</h3>
                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button>
                      <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                        <i className="fas fa-times"></i></button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                  <div className="ag-theme-balham" style={ {height: '600px', width: 'auto'} }>
                    <AgGridReact
                        rowHeight={50}
                        rowClass ={'centerAlign'}
                        onSortChanged={this.onSortChanged}
                        rowSelection={'single'}
                        onRowClicked={(e)=>this.onRowClicked(e)}
                        enableSorting
                        enableFilter
                        suppressCellSelection={true}
                        enableColResize
                        pagination={true}
                        paginationAutoPageSize={true}
                        columnDefs={this.state.columnDefs}
                        rowData={languageList}>
                    </AgGridReact>
                  </div>
                  </div>
                </div>
              </section>
            </div>
          )
      }
  }
function mapStateToProps(state) {

  return {
    LanguageReducer : state.LanguageReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({UpdateLanguageStatus:AC_UPDATE_LANGUAGESTATUS,ListLanguage: AC_LIST_LANGUAGE,DeleteLanguage:AC_DELETE_LANGUAGE},dispatch)
}
const LanguageComponent = connect(mapStateToProps,mapDispatchToProps)(LanguageList);

export default LanguageComponent;
