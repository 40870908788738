import React from 'react';
import ReactTooltip from "react-tooltip";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Validpassword } from '../../common/validate';
import { AC_CHANGE_PASSWORD } from '../../actions/settings';

class Changepassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword         : '',
      newpassword         : '',
      repeatpassword      : '',
      oldpassworderror    : false,
      newpassworderror    : false,
      validpassworderror  : false,
      validrepassworderror: "",
      repeatpassworderror : false,
    }
    this.validation = this.validation.bind(this);
  }
  validation() {
    const oldpassword         = this.state.oldpassword;
    const newpassword         = this.state.newpassword;
    const repeatpassword      = this.state.repeatpassword;
    const id                  = this.props.SettingsReducer.settingId;
    const validpassworderror    = this.state.validpassworderror;

    if(oldpassword) {
      this.setState({oldpassworderror : false})
    } else {
      this.setState({oldpassworderror : true})
    }
    if(newpassword) {
        this.setState({newpassworderror : false})
    } else {
        this.setState({newpassworderror : true})
    }
    if(newpassword === repeatpassword && repeatpassword.length!==0) {
        this.setState({repeatpassworderror : false})
    } else {
        this.setState({repeatpassworderror : true})
    }
    if(oldpassword && newpassword && !validpassworderror && newpassword === repeatpassword) {
      const password = { password : newpassword,oldpassword:oldpassword };
      this.props.changePassword(password,id)
      this.setState({oldpassword : "",newpassword : "",repeatpassword : ""})
    }
  }
  onChangeValue(event){
    const name=event.target.name;
    const value=event.target.value;
    if(name ==="oldpassword"){
      this.setState({oldpassword:value,oldpassworderror:false})
    }
    if(name ==='newpassword'){
     const isvalid=Validpassword(value);
     if(isvalid){
      this.setState({newpassword:value,newpassworderror:false,validpassworderror:false,passwordvalue:true})
      }
    else{
      this.setState({newpassword:value,newpassworderror:true,validpassworderror:true,passwordvalue:false})
      }
     this.setState({newpassword:value,newpassworderror:false})
    }
    if(name ==='repeatpassword'){
       if(this.state.newpassword===value) {
         this.setState({repeatpassword:value,repeatpassworderror:false,confirmpasswordvalue:true})
       }
       else{
         this.setState({repeatpassword:value,repeatpassworderror:true,confirmpasswordvalue:false})
       }
    }
}
  render() {
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
              <h1>Change Password</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Change Password</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Change Password</h3>
                  </div>
                 
                    <div className="card-body">
                      <div className="form-group">
                        <label>Old Password</label><span style={{color:"red"}}>*</span>
                        <input type="password" className="form-control" name="oldpassword" placeholder="Enter Old Password"  value={this.state.oldpassword} onChange ={(e) => this.onChangeValue(e)}/>
                        {this.state.oldpassworderror ? <label style={{color:"red"}}>Password is Incorrect</label>:""}
                      </div>
                      <div className="form-group">
                        <label>New Password</label><span style={{color:"red"}}>*</span>
                        <div data-tip="Password pattern should be minimum 8 characters,one uppercase,one lowercase,one number,one special character" data-for='toolTip1' data-place='top'><input type="password" className="form-control" name="newpassword" placeholder="Enter New Password"  value={this.state.newpassword} onChange ={(e) => this.onChangeValue(e)}/></div><ReactTooltip id="toolTip1" />
                        {this.state.newpassworderror ? <label style={{color:"red"}}>New Password is Required</label>:""}
                        {this.state.validpassworderror?<label style={{color:"red"}}>Password is Invalid</label>:""}
                      </div>
                      <div>
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label><span style={{color:"red"}}>*</span>
                        <div data-tip="Confirm passsword should be same as new password" data-for='toolTip1' data-place='top'><input type="password" className="form-control" name="repeatpassword" placeholder="Enter Confirm Password" value={this.state.repeatpassword} onChange ={(e) => this.onChangeValue(e)}/></div><ReactTooltip id="toolTip1" />
                        {this.state.repeatpassworderror ?<label style={{color:"red"}}> Password doesn't Match</label>: "" }
                      </div>
                        <button type="button" className="btn btn-primary" onClick={this.validation}>Submit</button>
                      </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({changePassword:AC_CHANGE_PASSWORD},dispatch)
}
const ChangepasswordComponent = connect(mapStateToProps,mapDispatchToProps)(Changepassword);

export default ChangepasswordComponent;
