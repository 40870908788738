import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import API from '../../common/api';
import { AC_VIEW_NOTIFICATION} from '../../actions/notificationaction';

class ViewNotification extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewNotification(id);
  }
render()
{
      const notificationInfo  = this.props.NotificationReducer.notificationInfo;
      // console.log("------------------------notiinfo",notificationInfo);
      const name              = notificationInfo.name;
      const description       = notificationInfo.description;
      const emailnotifier     = notificationInfo.emailnotifier;
      const smsnotifier       = notificationInfo.smsnotifier;
      const appnotifier       = notificationInfo.appnotifier;
      // const LIVEURL           = API.LIVEURL;
      return(
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1>View Notification</h1>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">Home</li>
                    <li class="breadcrumb-item active">View Notification</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="row">
              <div class="col-md-6">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 className="card-title">View Notification</h3>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label >Notification Name</label>
                      <input type="text" id="inputName" class="form-control"  name="name" value={name} disabled />
                    </div>
                    <div className="form-group">
                       <label>Description</label>
                       <textarea className="form-control" name="description"  value={description} disabled/>
                    </div>
                    <label>Email Notifier<input type="checkbox" className="form-control" style={{width:"25px"}} checked={emailnotifier === true} disabled/></label>
                    <br/>
                    <label>SMS Notifier<input type="checkbox" className="form-control" style={{width:"25px"}} checked={smsnotifier === true} disabled/></label>
                    <br/>
                    <label>App Notifier<input type="checkbox" className="form-control" style={{width:"25px"}} checked={appnotifier === true} disabled/></label>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

    )
  }
}
function mapStateToProps(state) {
  return {
    NotificationReducer : state.NotificationReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewNotification: AC_VIEW_NOTIFICATION},dispatch)
}
const ViewNotificationComponent = connect(mapStateToProps,mapDispatchToProps)(ViewNotification);

export default ViewNotificationComponent;
