import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect  } from "react-router-dom";
import swal from 'sweetalert';
import { AC_LIST_NEWSLETTER,AC_DELETE_NEWSLETTER } from '../../actions/newsletteraction';

class ListNewsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '120',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Template Name", field: "templatename", width: '400',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        { headerName: "Email Subject", field: "emailsubject", width: '450',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { width: "280",sortable: false,filter:false,
          template:
           `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           View
           </div>
           <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Edit">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Edit"></i>
           Edit
           </div>
           <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
           Delete
           </div>
           </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ],

    }
  }
  componentDidMount(){
    this.props.listNewsletter();
  }

  deleteNewsLetter(id) {
    swal({
         title      : "Are you sure?",
         text       : "Once deleted, you will not be able to recover this imaginary file!",
         icon       : "warning",
         buttons    : true,
         dangerMode : true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.DeleteNewsLetter(id);
           this.props.listNewsletter();
         }
   });
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
    if(value === 'Edit'){
      this.setState({redirect:'Edit',id:event.data._id})
    }
    if(value === 'Delete'){
      this.deleteNewsLetter(event.data._id)
    }
  }
  render() {
    //  const newsLetterCount     = this.props.NewsletterReducer.newsLetterCount;
     const newsLetterList      = this.props.NewsletterReducer.newsLetterList;
    //  const newsLetterListArray = [];
     if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/EditNewsLetter/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/ViewNewsLetter/'+this.state.id}/>
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>List Newsletter</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">List Newsletter</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Newsletter</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  rowData={newsLetterList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    NewsletterReducer :state.NewsletterReducer
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({listNewsletter: AC_LIST_NEWSLETTER,DeleteNewsLetter : AC_DELETE_NEWSLETTER},dispatch);
}

const ListNewsletterComponent = connect(mapStateToProps, mapDispatchToProps)(ListNewsletter);

export default ListNewsletterComponent;
