import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success , Loader, Error} from '../common/swal';



const ADD_CARLIST   = 'ADD_CARLIST';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_CAR_LIST(formData) {;
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ListyourCar,formData)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_CARLIST, payload:data});
      }
    });
  };
}
