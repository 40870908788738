import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import API from '../../common/api';
import { AC_VIEW_BRAND} from '../../actions/brandaction';

class ViewBrand extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewBrand(id);
  }


render()
{
      const brandInfo   = this.props.BrandReducer.brandInfo;
      const brandname   = brandInfo.brandname;
      const logo        = brandInfo.logo;
      const status      = brandInfo.status;
      const LIVEURL     = API.LIVEURL;
      return(
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1>View Brand</h1>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">Home</li>
                    <li class="breadcrumb-item active">View Brand</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="row">
              <div class="col-md-6">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 className="card-title">View Brand</h3>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label >Brand Name</label>
                      <input type="text" id="inputName" class="form-control"  name="brand" value={brandname} disabled />
                    </div>
                    <div class="form-group">
                      <label  htmlFor="exampleInputFile">Brand Logo</label>
                      <div>
                           <img src={LIVEURL+logo} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>
                         </div>
                         <div className="form-group">
                        <label htmlFor="exampleInputFile">Status</label>
                        <select name = "status"  className="form-control" disabled>
                          <option  selected={status === 1}>Active</option>
                          <option  selected={status === 0}>InActive</option>
                       </select>
                         </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

    )
  }
}
function mapStateToProps(state) {
  return {
    BrandReducer : state.BrandReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewBrand: AC_VIEW_BRAND},dispatch)
}
const ViewBrandComponent = connect(mapStateToProps,mapDispatchToProps)(ViewBrand);

export default ViewBrandComponent;
