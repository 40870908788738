import axios from "axios";
import URL from "../common/api";
import { Success } from '../common/swal';

const LIST_DASHBOARD_NOTIFS = "LIST_DASHBOARD_NOTIFS";
const MAKE_AS_READ          =  "MAKE_AS_READ";

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
    headers:{
      Authorization:'bearer '+accessToken
    }
})


export function AC_LIST_DASHBOARD_NOTIFS() {
    return function(dispatch) {
      return authAxios.post(URL.API.DashboardNotifications,{})
        .then(({ data }) => {
          if(data.status===403){
            localStorage.removeItem("token");
            window.location='/';
          }

          
          dispatch({type: LIST_DASHBOARD_NOTIFS, payload:data});
      });
    };
}

export function AC_MAKE_AS_READ(form) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateDashNotif,form)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        Success(data.message)
        dispatch({type: MAKE_AS_READ, payload:data});
    });
  };
}
