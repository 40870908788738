import React from 'react';

class ContactUs extends React.Component {
  render() {
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Contact Us</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Contact Us</h3>
                  </div>
                  
                    <div className="card-body" style={{padding:"50px"}}>
                      <div className="form-group">
                        <i className="fa fa-user-circle-o" style={{fontSize:"18px",marginRight: "10px"}}></i>
                        <label htmlFor="name" style={{color:"blue",marginRight: "10px"}}>Name:</label>
                        <label >Bein</label>
                      </div>
                      <div className="form-group">
                        <i className="fa fa-envelope-o" style={{fontSize:"18px",marginRight: "10px"}}></i>
                        <label htmlFor="email" style={{color:"blue",marginRight: "10px"}}>Email Id:</label>
                        <label>carrental@gmail.com</label>
                      </div>
                      <div className="form-group">
                        <i className="fa fa-volume-control-phone" style={{fontSize:"18px",marginRight: "10px"}}></i>
                        <label htmlFor="phonenumber" style={{color:"blue",marginRight: "10px"}}>Phone Number:</label>
                        <label>123456789</label>
                      </div>
                    </div>
                 
               </div>
            </div>
          </div>
        </div>
       </section>
      </div>
    )
  }
}
export default ContactUs;
