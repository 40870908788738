const initialState = {
  faqList  : [],
  faqCount : 0,
  faqInfo  : {
    question : '',
    answer   : '',
    faqtype  : '',
    status   : '',
    id       : ''
  },
  isTokenExpired   : false
}

function FaqReducer(state = initialState, action) {
  switch (action.type) {
    case "ADD_FAQ":
      return Object.assign({}, state, {
        faqList  : action.payload.data,
        faqCount : action.payload.count
      })
    case "LIST_FAQ":
      return Object.assign({}, state, {
        faqList          : action.payload.data,
        faqCount         : action.payload.count,
      })
    case "VIEW_FAQ":
      return Object.assign({}, state, {
        faqInfo  : action.payload.data,
      })
    case "DELETE_FAQ":
      return Object.assign({}, state, {
        faqList  : action.payload.data,
        faqCount : action.payload.count
      })
    case "UPDATE_STATUS":
        return Object.assign({}, state, {
          faqList  : action.payload.data,
        })
    case "EDIT_FAQ":
      return Object.assign({}, state, {
        faqInfo  : action.payload.data,
      })
    case "UPDATE_FAQ_DATA":
      return editFaqInfo(action,state);
    default:
      return state
  }
}

// functions to update edit faqinfo data
function editFaqInfo(action,state) {
  if(action.name === 'faq') {
    return Object.assign({}, state, {
        faqInfo  : {
                    ...state.faqInfo,
                     question : action.value
                   }
    })
  }
  if(action.name === 'description') {
    return Object.assign({}, state, {
        faqInfo  : {
                    ...state.faqInfo,
                     answer : action.value
                   }
    })
  }
  if(action.name === 'faqtype') {
    return Object.assign({}, state, {
        faqInfo  : {
                    ...state.faqInfo,
                     faqtype : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        faqInfo  : {
                    ...state.faqInfo,
                     status : action.value
                   }
    })
  }

  return 1;
}

export default FaqReducer;
