const initialState = {
  currencyList : [],
  currencyListCount : 0,
  currencyInfo : {
    currencyname :'',
    symbol       :'',
    status       :'',
    id           : ''

  }
}

function CurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_CURRENCY":
      return Object.assign({}, state, {
        currencyList       : action.payload.data,
        currencyListCount  : action.payload.count
      })
    case "VIEW_CURRENCY":
      return Object.assign({}, state, {
        currencyInfo  : action.payload.data,
      })
      case "DELETE_CURRENCY":
        return Object.assign({}, state, {
          currencyList      : action.payload.data,
          currencyListCount : action.payload.count
        })
    case "EDIT_CURRENCY":
      return Object.assign({}, state, {
        currencyInfo  : action.payload.data,
      })
    case "UPDATE_CURRENCY_DATA":
        return editCurrencyInfo(action,state);
    default:
      return state
  }
}

function editCurrencyInfo(action,state) {
  if(action.name === 'currencyname') {
    return Object.assign({}, state, {
        currencyInfo  : {
                    ...state.currencyInfo,
                     currencyname : action.value
                   }
    })
  }
  if(action.name === 'symbol') {
    return Object.assign({}, state, {
        currencyInfo  : {
                    ...state.currencyInfo,
                     symbol : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        currencyInfo  : {
                    ...state.currencyInfo,
                     status : action.value
                   }
    })
  }
}

export default CurrencyReducer;
