import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';

// Action types for Notification management
const ADD_NOTIFICATION          = 'ADD_NOTIFICATION';
const LIST_NOTIFICATION         = 'LIST_NOTIFICATION';
const EDIT_NOTIFICATION         = 'EDIT_NOTIFICATION';
const VIEW_NOTIFICATION         = 'VIEW_NOTIFICATION';
const UPDATE_NOTIFICATION_DATA  = 'UPDATE_NOTIFICATION_DATA';
const DELETE_NOTIFICATION       = 'DELETE_NOTIFICATION';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})


export function AC_ADD_NOTIFICATION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddNotification,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_NOTIFICATION, payload:data});
      }
    });
  };
}

export function AC_LIST_NOTIFICATION() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListNotification,{})
      .then(({ data }) => {
        swal.close();
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_NOTIFICATION, payload:data});
    });
  };
}

export function AC_DELETE_NOTIFICATION(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteNotification,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_NOTIFICATION, payload:data});
      }
    });
  };
}

export function AC_VIEW_NOTIFICATION(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewNotification,id)
      .then(({ data }) => {
        swal.close()
        dispatch({type: VIEW_NOTIFICATION, payload:data});
    });
  };
}

export function AC_EDIT_NOTIFICATION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditNotification,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_NOTIFICATION, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_NOTIFICATION_DATA, name:name,value:value})
  };
}