const initialState = {
  carList      : [],
  ownerInfo: {
    firstname: '',
    lastname: '',
    email:'',
    image: '',
    userverified: null
  },
  carListCount : 0,
  carId        : '',
  carInfo : {
    carmodel : '',
    year     : '',
    country  : '',
    status   : '',
    id       : ''
  }
}

function HostCarReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_OWNER_INFO":
      return Object.assign({}, state, {
        owner      : action.payload.data,
      })
    case "LIST_CAR":
      return Object.assign({}, state, {
        carList      : action.payload.data,
        carListCount : action.payload.count
      })
    case "VIEW_CAR":
      return Object.assign({}, state, {
        ownerInfo: action.payload.ownerInfo,
        carInfo  : action.payload.data,
      })
    case "DELETE_CAR":
      return Object.assign({}, state, {
        carList      : action.payload.data,
        carListCount : action.payload.count
      })
    case "EDIT_CAR":
      return Object.assign({}, state, {
        carInfo  : action.payload.data,
      })
    case "UPDATE_CAR_DATA":
      return editCarInfo(action,state);
    case "UPDATE_HOST_DETAILS":
      return Object.assign({}, state, {
        carList      : action.payload.data,
        carListCount : action.payload.count
      })
    default:
      return state
  }
}
// functions to update edit carinfo data
function editCarInfo(action,state) {
  if(action.name === 'carmodel') {
    return Object.assign({}, state, {
        carInfo  : {
                    ...state.carInfo,
                     carmodel : action.value
                   }
    })
  }
  if(action.name === 'year') {
    return Object.assign({}, state, {
        carInfo  : {
                    ...state.carInfo,
                     year : action.value
                   }
    })
  }
  if(action.name === 'country') {
    return Object.assign({}, state, {
        carInfo  : {
                    ...state.carInfo,
                     country : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        carInfo  : {
                    ...state.carInfo,
                     status : action.value
                   }
    })
  }

  return 1;
}
export default HostCarReducer;
