const initialState = {
    notificationList : [],
    notificationCount : 0,
    notificationInfo : {
      name          : '',
      description   : '',
      emailnotifier : '',
      smsnotifier   : '',
      appnotifier   : '',
      id            : ''
    }
  }
  
  function NotificationReducer(state = initialState, action) {
    switch (action.type) {
      case "LIST_NOTIFICATION":
        return Object.assign({}, state, {
          notificationList       : action.payload.data,
          notificationCount      : action.payload.count
        })
      case "VIEW_NOTIFICATION":
        return Object.assign({}, state, {
          notificationInfo  : action.payload.data,
        })
        case "DELETE_NOTIFICATION":
          return Object.assign({}, state, {
            notificationList      : action.payload.data,
            notificationCount     : action.payload.count
          })
        case "EDIT_NOTIFICATION":
           return Object.assign({}, state, {
            notificationInfo  : action.payload.data,
           })
        case "UPDATE_NOTIFICATION_DATA":
          return editnotificationInfo(action,state);
        default:
         return state
    }
  }
  
  function editnotificationInfo(action,state) {
    if(action.name === 'name') {
      return Object.assign({}, state, {
          notificationInfo  : {
                      ...state.notificationInfo,
                       name : action.value
                     }
      })
    }
    if(action.name === 'description') {
      return Object.assign({}, state, {
          notificationInfo  : {
                      ...state.notificationInfo,
                       description : action.value
                     }
      })
    }
    if(action.name === 'emailnotifier') {
      return Object.assign({}, state, {
          notificationInfo  : {
                      ...state.notificationInfo,
                       emailnotifier : action.value
                     }
      })
    }
    if(action.name === 'smsnotifier') {
      return Object.assign({}, state, {
          notificationInfo  : {
                      ...state.notificationInfo,
                       smsnotifier : action.value
                     }
      })
    }
    if(action.name === 'appnotifier') {
      return Object.assign({}, state, {
          notificationInfo  : {
                      ...state.notificationInfo,
                       appnotifier : action.value
                     }
      })
    }
    
    return 1;
  }
  
  export default NotificationReducer;
  