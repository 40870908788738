import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_CAR,AC_EDIT_CAR,AC_HANDLE_INPUT_CHANGE} from '../../actions/carlistaction';

class EditCar extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
        modelerror   : false,
        yearerror    : false,
        countryerror : false,
        statuserror  : ''

       }
       this.validate=this.validate.bind(this);
  }
  componentWillMount() {
  const id = this.props.match.params.id;
  this.props.ViewCar(id);
 }
 // Validation
 validate() {
   const carmodel     = this.props.HostCarReducer.carInfo.carmodel;
   const year         = this.props.HostCarReducer.carInfo.year;
   const country      = this.props.HostCarReducer.carInfo.country;
   const status       = this.props.HostCarReducer.carInfo.status;
   const id           = this.props.HostCarReducer.carInfo.id;

   if(carmodel) {
     this.setState({modelerror : false})
   }
   else{
     this.setState({modelerror : true})
   }
   if(year) {
     this.setState({yearerror : false})
   }
   else{
     this.setState({yearerror : true})
   }
   if(country) {
     this.setState({countryerror : false})
   }
   else{
     this.setState({countryerror : true})
   }
   if(carmodel && year && country) {
   // Formdata to send Api
   const carFormData = {
                         carmodel   : carmodel,
                         year       : year,
                         country    : country,
                         status     : status,
                         id         : id
                       }
   this.props.EditCar(carFormData);
 }
 }
 // Onchange
 onChangeValue(event){
   const name  = event.target.name;
   const value = event.target.value;
   this.props.HandleInputChange(name,value)
 }
render() {
  const carInfo      = this.props.HostCarReducer.carInfo;
  const carmodel     = carInfo.carmodel;
  const year         = carInfo.year;
  const country      = carInfo.country;
  const status       = carInfo.status;
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Car List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Edit Car List</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Edit Car List</h3>
                </div>
                <form>
                  <div className="card-body">
                     <div className="form-group">
                        <label>Car Model</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="carmodel" placeholder="Enter Car Model" pattern="[A-Za-z]" value={carmodel} onChange ={(e) => this.onChangeValue(e)}/>
                        {this.state.modelerror ? <label style={{color:"red"}}>Car Model is Required</label> : ""}
                        {this.state.modelinvalid ? <label style={{color:"red"}}>Car Model is Invalid</label> : ""}
                     </div>
                     <div className="form-group">
                        <label>Car Year</label><span style={{color:"red"}}>*</span>
                        <select name = "year" className="form-control" onChange ={(e) => this.onChangeValue(e)}>

                        <option value="2010" selected={year === 2010} >2010</option>
                        <option value="2011" selected={year === 2011}>2011</option>
                        <option value="2012" selected={year === 2012} >2012</option>
                        <option value="2013" selected={year === 2013}>2013</option>
                        <option value="2014" selected={year === 2014} >2014</option>
                        <option value="2015" selected={year === 2015}>2015</option>
                        <option value="2016" selected={year === 2016} >2016</option>
                        <option value="2017" selected={year === 2017}>2017</option>
                        <option value="2018" selected={year === 2018} >2018</option>
                        <option value="2019" selected={year === 2019}>2019</option>
                        <option value="2020" selected={year === 2010} >2020</option>

                         </select>

                        {this.state.yearerror ? <label style={{color:"red"}}>car year is Required</label> : ""}
                     </div>
                     <div className="form-group">
                        <label>Car Country</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="country" placeholder="Enter Car Year" value={country} onChange ={(e) => this.onChangeValue(e)}/>
                        {this.state.countryerror ? <label style={{color:"red"}}>Car Country is Required</label> : ""}
                     </div>
                     <div className="form-group">
                            <label>Status</label>
                            <select name = "status" className="form-control" onChange ={(e) => this.onChangeValue(e)}>

                            <option value="1" selected={status === 1} >Active</option>
                            <option value="0" selected={status === 0}>Inactive</option>
                            </select>
                            {this.state.statuserror ? <label style={{color:"red"}}>Status is Required</label> : ""}
                         </div>
                     <div className="card-footer">
                        <button type="button" className="btn btn-primary" onClick={this.validate}>Update</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
   )
 }
}

function mapStateToProps(state) {
  return {
    HostCarReducer : state.HostCarReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCar: AC_VIEW_CAR,EditCar:AC_EDIT_CAR,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditCarComponent = connect(mapStateToProps,mapDispatchToProps)(EditCar);

export default EditCarComponent;
