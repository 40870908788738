import React from 'react';
import ReactTooltip from "react-tooltip";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE,AC_UPDATE_ADMIN_SETTINGS } from '../../actions/settings';

class SmtpSettings extends React.Component {
  constructor(props){
    super(props);
    this.validateform       = this.validateform.bind(this);
  }
  //validation part
  validateform() {
    const smtpInfo    = this.props.SettingsReducer.smtpInfo;
    const id          = this.props.SettingsReducer.settingId;
    this.props.updateAdminSettings(smtpInfo,id,"Smtp");
  }

  //onchange   input values
  onChangeInput(event) {
     const name   = event.target.name;
     const value  = event.target.value;
     this.props.handleInputChange(name,value,"Smtp")
  }
  render() {
    const smtpInfo   = this.props.SettingsReducer.smtpInfo;
    var email        = "";
    var portnumber   = "";
    var hostname     = "";
    var password     = "";
    if(smtpInfo) {
       email        = smtpInfo.contactemail;
       portnumber   = smtpInfo.portnumber;
       hostname     = smtpInfo.hostname;
       password     = smtpInfo.password;
    }

    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>SMTP Settings</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Admin</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                  <h3 className="card-title">SMTP form</h3>
                  </div>
                 
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="hostname">SMTP Host name</label>
                        <input name="hostname" type="text" className="form-control" id="hostname" placeholder="SMTP Host name"  value={hostname} onChange={(e) => this.onChangeInput(e)}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="portnumber">SMTP Port number</label>
                        <input name="portnumber" type="number" className="form-control" id="portnumber" placeholder="SMTP Port number" value={portnumber} onKeyDown={(evt) => ["e", "E", "+", "-",'.'].includes(evt.key) && evt.preventDefault()} onChange={(e) => this.onChangeInput(e)}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">E-mail</label>
                        <input type="text" name="contactemail" className="form-control"  placeholder="Enter E-mail" value={email}  onChange={(e)=>this.onChangeInput(e)}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div data-tip="Password pattern should be minimum 8 characters,one uppercase,one lowercase,one number,one special character" data-for='toolTip1' data-place='top'>
                          <input name="password" type="password" className="form-control" id="password" placeholder="Password" value={password}  onChange={(e) => this.onChangeInput(e)}/>
                        </div><ReactTooltip id="toolTip1" />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({handleInputChange:AC_HANDLE_INPUT_CHANGE,updateAdminSettings:AC_UPDATE_ADMIN_SETTINGS},dispatch)
}
const SmtpSettingsComponent = connect(mapStateToProps,mapDispatchToProps)(SmtpSettings);

export default SmtpSettingsComponent;
