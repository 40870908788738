import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_LANGUAGE,AC_EDIT_LANGUAGE,AC_HANDLE_INPUT_CHANGE} from '../../actions/languageaction';


class EditLanguage extends React.Component {
       // Constructor
     constructor(props) {
      super(props);
      this.state = {
                      nameerror   : false,
                      codeerror   : false,
                      nameinvalid : false,
                      statuserror : '',
              }
      this.validate = this.validate.bind(this);
     }

     componentWillMount() {
     const id = this.props.match.params.id;
     this.props.ViewLanguage(id);
   }
   // Validation
   validate() {
   var name               = this.props.LanguageReducer.languageInfo.languagename;
   var code               = this.props.LanguageReducer.languageInfo.languagecode;
   const status           = this.props.LanguageReducer.languageInfo.status;
   const id               = this.props.LanguageReducer.languageInfo.id;
   if(name) {
     this.setState({nameerror : false})
   }
   else{
     this.setState({nameerror : true})
   }
   if(code) {
     this.setState({codeerror : false})
   }
   else{
     this.setState({codeerror : true})
   }
   name    =  name.charAt(0).toUpperCase() + name.slice(1);
   code    =  code.toUpperCase();
   if(name && code) {
     // Formdata to send Api
       const languageFormData = {
                             languagename   : name,
                             languagecode   : code,
                             status         : status,
                             id             : id
                           }
       this.props.EditLanguage(languageFormData);
   }
 }
    // Onchange
    onChangeValue(event){
     const name        = event.target.name;
     const value       = event.target.value;
     this.props.HandleInputChange(name,value)
   }

    render() {
      const languageInfo   = this.props.LanguageReducer.languageInfo;
      const languagename   = languageInfo.languagename;
      const languagecode   = languageInfo.languagecode;
      const status         = languageInfo.status;

   return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Edit Language</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">Edit Language</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Edit Language</h3>
                    </div>
                    <form>
                      <div className="card-body">
                         <div className="form-group">
                            <label>Language Name</label><span style={{color:"red"}}>*</span>
                            <input type="text" className="form-control" name="name" placeholder="Enter Language Name" value={languagename} pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                            {this.state.nameerror ? <label style={{color:"red"}}>Language name is Required</label> : ""}
                            {this.state.nameinvalid ? <label style={{color:"red"}}>Language name is Invalid</label> : ""}
                         </div>
                         <div className="form-group">
                            <label>Language Code</label><span style={{color:"red"}}>*</span>
                            <input type="text" className="form-control" name="code" placeholder="Enter Language Code" value={languagecode}onChange ={(e) => this.onChangeValue(e)}/>
                            {this.state.codeerror ? <label style={{color:"red"}}>Language code is Required</label> : ""}
                         </div>
                         <div className="form-group">
                            <label>Status</label><span style={{color:"red"}}>*</span>
                            <select name = "status" className="form-control" onChange ={(e) => this.onChangeValue(e)}>

                            <option value={1} selected={status === 1} >Active</option>
                            <option value={0} selected={status === 0}>Inactive</option>
                            </select>
                            {this.state.statuserror ? <label style={{color:"red"}}>Status is Required</label> : ""}
                         </div>
                         <div className="card-footer">
                            <button type="button" className="btn btn-primary" onClick={this.validate}>Update</button>
                         </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
       )
     }
    }

function mapStateToProps(state) {
  return {
    LanguageReducer : state.LanguageReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewLanguage: AC_VIEW_LANGUAGE,EditLanguage:AC_EDIT_LANGUAGE,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditlanguageComponent = connect(mapStateToProps,mapDispatchToProps)(EditLanguage);

export default EditlanguageComponent;
