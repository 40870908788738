import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';
// Action types for Country management

const LIST_COUNTRY        = 'LIST_COUNTRY';
const ADD_COUNTRY         = 'ADD_COUNTRY';
const EDIT_COUNTRY        = 'EDIT_COUNTRY';
const VIEW_COUNTRY        = 'VIEW_COUNTRY';
const UPDATE_COUNTRY_DATA = 'UPDATE_COUNTRY_DATA';
const DELETE_COUNTRY      = 'DELETE_COUNTRY';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})
export function AC_UPDATE_COUNTRYSTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateCountryStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: DELETE_COUNTRY, payload:data});
       }
    });
  };
}

export function AC_DELETE_COUNTRY(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteCountry,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_COUNTRY, payload:data});
      }
    });
  };
}

export function AC_LIST_COUNTRY() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListCountry)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_COUNTRY, payload:data});
    });
  };
}

export function AC_ADD_COUNTRY(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddCountry,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_COUNTRY, payload:data});
      }
    });
  };
}

export function AC_VIEW_COUNTRY(formData) {
  const id = { id : formData }
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewCountry,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_COUNTRY, payload:data});
    });
  };
}

export function AC_EDIT_COUNTRY(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditCountry,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_COUNTRY, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_COUNTRY_DATA, name:name,value:value})
  };
}
