import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';

const LIST_PAGES        = 'LIST_PAGES';
const ADD_PAGES         = 'ADD_PAGES';
const VIEW_PAGES        = 'VIEW_PAGES';
const EDIT_PAGES        = 'EDIT_PAGES';
const UPDATE_PAGES_DATA = 'UPDATE_PAGES_DATA';
const DELETE_PAGES      = 'DELETE_PAGES'

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})


// Action types for Pages management
export function AC_LIST_PAGES() {
  Loader();
 return function(dispatch) {
   return authAxios.get(URL.API.ListPages)
     .then(({ data }) => {
       swal.close()
       if(data.status===403){
         localStorage.removeItem("token");
         window.location='/';
       }
       dispatch({type: LIST_PAGES, payload:data});
   });
 };
}

export function AC_DELETE_PAGES(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeletePages,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_PAGES, payload:data});
      }
    });
  };
}

export function AC_ADD_PAGES(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddPages,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_PAGES, payload:data});
      }
    });
  };
}

export function AC_VIEW_PAGES(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewPages,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_PAGES, payload:data});
    });
  };
}

export function AC_EDIT_PAGES(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditPages,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_PAGES, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_PAGES_DATA, name:name,value:value})
  };
}
