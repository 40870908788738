import React from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect  } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { AC_LIST_CURRENCY,AC_DELETE_CURRENCY,AC_UPDATE_CURRENCYSTATUS } from '../../actions/currencyaction';

class ListCurrency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '120',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Currency Name", field: "currencyname", width: '250',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Currency Symbol", field: "symbol", width: '250',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        { headerName: "Status", field: "status", width: '200',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
          },
          cellRenderer: function(params) {
           if(params.data.status) {
             return '<span class="badge badge-success" data-action-type="Status">Active</span>';
           } else {
             return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Inactive</span>';
           }
         }
        },
         { headerName: 'Actions', width: "300",sortable: false,filter:false,
           template:
           `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           View
           </div>
           <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Edit">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Edit"></i>
           Edit
           </div>
           <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
           Delete
           </div>
           </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ],

    }
  }

  componentDidMount() {
    this.props.ListCurrency();
  }
  deleteCurrency(id) {
    swal({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this imaginary file!",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.DeleteCurrency(id);
         }
   });
  }
  updateStatus(event){
    const data = {
       id     : event._id,
       status : !event.status
    }
    this.props.UpdateCurrencyStatus(data);
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
    if(value === 'Status'){
      this.updateStatus(event.data);
    }
    if(value === 'Edit'){
      this.setState({redirect:'Edit',id:event.data._id})
    }
    if(value === 'Delete'){
      this.deleteCurrency(event.data._id)
    }
  }

  render() {
    const currencyList          = this.props.CurrencyReducer.currencyList;
    // const currencyListCount     = this.props.CurrencyReducer.currencyListCount;
    if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/editCurrency/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/viewCurrency/'+this.state.id}/>
    }
      return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>List Currency</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">List Currency</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Currency</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={100}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  rowData={currencyList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CurrencyReducer : state.CurrencyReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({UpdateCurrencyStatus:AC_UPDATE_CURRENCYSTATUS,ListCurrency: AC_LIST_CURRENCY,DeleteCurrency:AC_DELETE_CURRENCY},dispatch)
}
const ListCurrencyComponent = connect(mapStateToProps,mapDispatchToProps)(ListCurrency);

export default ListCurrencyComponent;
