import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_CURRENCY} from '../../actions/currencyaction';

class ViewCurrency extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewCurrency(id);
  }
  render(){
      const currencyInfo = this.props.CurrencyReducer.currencyInfo;
      const currencyname = currencyInfo.currencyname;
      const symbol       = currencyInfo.symbol;
      const status       = currencyInfo.status;
      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                <h1>View Currency</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">View Currency</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">View Currency</h3>
                    </div>
                    <form>
                      <div className="card-body">
                        <div className="form-group">
                           <label>Currency Name</label>
                           <input type="text" className="form-control" name="currencyname" placeholder="Enter Currency Name" value={currencyname} disabled/>
                        </div>
                        <label htmlFor="exampleInputFile">Symbol</label>
                        <div>
                           <input type="text" className="form-control" name="symbol" placeholder="Enter Currency Symbol" value={symbol} disabled/>
                         </div>
                        <div className="form-group">
                        <label htmlFor="exampleInputFile">Status</label>
                        <select name = "status"  className="form-control" disabled>
                          <option value={1}  selected={status === 1}>Active</option>
                          <option value={0}  selected={status === 0}>InActive</option>
                       </select>
                         </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    CurrencyReducer : state.CurrencyReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCurrency: AC_VIEW_CURRENCY},dispatch)
}
const ViewCurrencyComponent = connect(mapStateToProps,mapDispatchToProps)(ViewCurrency);

export default ViewCurrencyComponent;
