import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_RENTEDCAR } from '../../actions/rentaction';
import API from '../../common/api';

class ViewRentedCar extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewRent(id);
  }

  render() {
      const rentInfo  = this.props.RentReducer.rentInfo;
      const brandname = rentInfo.brandname;
      const image     = rentInfo.image;
      const year      = rentInfo.year;
      const rating    = rentInfo.rating;
      const LIVEURL   = API.LIVEURL+"uploads/";
      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>View Rented Car</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">View Rented Car</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View Rented Car</h3>
                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label >Brandname</label>
                      <input type="text" id="firstname" className="form-control"  name="firstname" value={brandname} disabled />
                    </div>
                    <label htmlFor="exampleInputFile">Image</label>
                    <div>
                       <img src={LIVEURL+image} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>
                    </div>
                    <div className="form-group">
                      <label >Year</label>
                      <input type="text" id="cartype" className="form-control"  name="cartype" value={year} disabled />
                    </div>
                    <div className="form-group">
                      <label >Ratings</label>
                      <input type="text" id="checkin" className="form-control"  name="checkin" value={rating} disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    RentReducer : state.RentReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewRent: AC_VIEW_RENTEDCAR},dispatch)
}
const ViewRentedCarComponent = connect(mapStateToProps,mapDispatchToProps)(ViewRentedCar);

export default ViewRentedCarComponent;
