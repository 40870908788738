const initialState = {
    subscriptionList  : [],
    subscriptionCount : 0,
    subscriptionInfo  : {
      email    : '',
      status   : ''
    }
  }
  
  function SubscriptionReducer(state = initialState, action) {
    switch (action.type) {
      case "LIST_SUBSCRIPTION":
        return Object.assign({}, state, {
          subscriptionList  : action.payload.data,
          subscriptionCount : action.payload.count
        })
      default:
        return state
    }
  }
  export default SubscriptionReducer;
  