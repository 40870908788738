import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect } from "react-router-dom";
import swal from 'sweetalert';
import { AC_LIST_COUNTRY,AC_DELETE_COUNTRY,AC_UPDATE_COUNTRYSTATUS} from '../../actions/countryaction';

class ListCountry  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '50',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Country Name", field: "countryname", width: '300',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        { headerName: "Country Code", field: "countrycode", width: '300',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { headerName: "Status", field: "status", width: '300',
          cellStyle: function(params) {
          return {'font-size': '16px','margin-top': '5px'};
        },
          cellRenderer: function(params) {
           if(params.data.status) {
             return '<span class="badge badge-success" data-action-type="Status">Active</span>';
           } else {
             return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Inactive</span>';
           }
         }
       },
       { headerName: 'Actions', width: "300",sortable: false,filter:false,
       template:
       `<div>
       <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
       <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
       View
       </div>
       <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Edit">
       <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Edit"></i>
       Edit
       </div>
       <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
       <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
       Delete
       </div>
       </div>`,
       cellStyle: function(params) {
              return {'margin-top' :'8px'};
      },
    },
      ],

    }
  }
  componentDidMount() {
    this.props.ListCountry();
  }

    deleteCountry(id) {
      swal({
           title: "Are you sure?",
           text: "Once deleted, you will not be able to recover this imaginary file!",
           icon: "warning",
           buttons: true,
           dangerMode: true,
         })
         .then((willDelete) => {
           if (willDelete) {
             this.props.DeleteCountry(id);
           } else {
             console.log('-=-=-=-=-=cancel-=-=-=-=',);
           }
     });
    }
    onSortChanged = e => {
      e.api.refreshCells();
    }
    updateStatus(event){
      const data = {
         id     : event._id,
         status : !event.status
      }
      this.props.UpdateCountryStatus(data);
    }
    onRowClicked = event =>{
      const rowValue = event.event.target;
      const value    = rowValue.getAttribute('data-action-type');
      if(value === 'View'){
        this.setState({redirect:'View',id:event.data._id})
      }
      if(value === 'Edit'){
        this.setState({redirect:'Edit',id:event.data._id})
      }
      if(value === 'Delete'){
        this.deleteCountry(event.data._id)
      }
      if(value === 'Status'){
        this.updateStatus(event.data);
      }
    }
  render() {
    // const countryCount     = this.props.CountryReducer.countryCount;
    const countryList      = this.props.CountryReducer.countryList;
    // const countryListArray = [];
    if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/EditCountry/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/ViewCountry/'+this.state.id}/>
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Country List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Country List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Countries</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  enableFilter
                  suppressCellSelection={true}
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  rowData={countryList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    CountryReducer :state.CountryReducer
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({UpdateCountryStatus:AC_UPDATE_COUNTRYSTATUS,ListCountry: AC_LIST_COUNTRY,DeleteCountry:AC_DELETE_COUNTRY},dispatch)
}

const ListCountryComponent = connect(mapStateToProps, mapDispatchToProps)(ListCountry)

export default ListCountryComponent;
