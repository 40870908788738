import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Loader } from '../common/swal';
//import { Success} from '../common/swal';

const VIEW_RENTEDCAR        = 'VIEW_RENTEDCAR';
const LIST_RENTEDCAR        = 'LIST_RENTEDCAR';
const UPDATE_RENTEDCAR_DATA = 'UPDATE_RENTEDCAR_DATA';

const accessToken = localStorage.getItem("token");

const authAxios = axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_VIEW_RENTEDCAR(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewRentedCar,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_RENTEDCAR, payload:data});
    });
  };
}

export function AC_RENTEDCAR_LIST(rentstatus) {
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.RentList,rentstatus)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_RENTEDCAR, payload:data});
    });
  };
}


export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_RENTEDCAR_DATA, name:name,value:value})
  };
}
