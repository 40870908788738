import React from 'react';
// import {Link,Redirect  } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { AC_DELETE_NOTIFICATION,AC_LIST_NOTIFICATION, } from '../../actions/notificationaction';
import {AC_MAKE_AS_READ} from "../../actions/DashboardNotification"
// import { param } from 'jquery';


class ListDashboardNotification extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      columnDefs :[
        { "header" : "#",field:"#", valueGetter :"node.rowIndex+1",width:"150",
        cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         },
         },
         { "header" : "Date",field:"date",width:"150",
            cellStyle: function(params) {
                return {'font-size': '16px','margin-top': '5px'};
            },
         },
        { "header" : "Description", field :"log",width:"250",
        cellStyle: function(params) {
              
               return {'font-size': '16px','margin-top': '5px'};
        }
         },
        

        { headerName: 'Actions', width: "300",sortable: false,filter:false,
         template:
           `<div>
                <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
                    <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
                    Marquer comme lu
                </div>
               
           </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ]
    }
  }



  
   componentDidMount() {
   this.props.ListNotification();
   }
  deleteNotification(id) {
    swal({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this imaginary file!",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.DeleteNotification(id);
         } else {
           console.log('-=-=-=-=-=cancel-=-=-=-=',);
         }
   });
  }
  onSortChanged = e => {
   e.api.refreshCells();
  }
  onRowClicked = event => {
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
     if(value === 'View'){
        if(!event.data.hasRead){

        swal({
                title: "Ête-vous sûre ?",
                text: "Vous confirmer avoir lu ce message !",
                icon: "warning",
                buttons: true,
                dangerMode: false,
              })
              .then((willDelete) => {
                if (willDelete) {
                    this.props.MakeAsRead({hasRead : true,_id:event.data._id})
                } else {
                  console.log('-=-=-=-=-=cancel-=-=-=-=',);
                }
          });

        }
        
    
     
     }
    //  if(value === 'Edit' ){
    //    this.setState({redirect:'Edit',id:event.data._id})
    //  }
    // if(value === 'Delete'){
    //   this.deleteNotification(event.data._id)
    // }
  }

  render()  {
    const dashboardNotificationList     = this.props.DashboardNotifsReduccer.dashboardNotifsList;

    //  if(this.state.redirect === 'Edit'){
    //    return <Redirect to ={'/editNotification/'+this.state.id}/>
    //  }
    //  if(this.state.redirect === 'View'){
    //    return <Redirect to ={'/viewNotification/'+this.state.id}/>
    //  }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1> Dashboard Notifications List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Dashboard Notifications List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Dashboard Notifications</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div className="ag-theme-balham" style={ {height: '600px', width: 'auto'} }>
              <AgGridReact
                  rowHeight={50}
                  rowClass ={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDefs}
                  rowData={dashboardNotificationList}>
              </AgGridReact>
            </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    NotificationReducer      : state.NotificationReducer,
    DashboardNotifsReduccer  :state.DashboardNotifsReduccer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
      {
        ListNotification      : AC_LIST_NOTIFICATION,
        DeleteNotification    : AC_DELETE_NOTIFICATION,
        MakeAsRead            : AC_MAKE_AS_READ
    },dispatch)
}
const ListNotificationComponent = connect(mapStateToProps,mapDispatchToProps)(ListDashboardNotification);

export default ListNotificationComponent;
