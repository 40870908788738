import React from 'react';
import { Redirect  } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { AC_DELETE_CONFIGURATION,AC_LIST_CONFIGURATION } from '../../actions/configurationaction';


class ListConfiguration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      columnDefs :[
        { "header" : "#",field:"#", valueGetter :"node.rowIndex+1",width:"50",
        cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         },
         },
        { "header" : "slug", field :"slug",width:"300",
        cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         },
         cellRenderer: function(params) {
           return '<a href="javascript:void(0)" data-action-type="Edit">' + params.value + '</a>';
         }
        },
        { "header" : "name", field :"name",width:"300",
        cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }
        },
        { "header" : "description", field :"description",width:"300",
        cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
        }
         },

        { headerName: 'Actions', width: "300",sortable: false,filter:false,
         template:
           `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           View
           </div>
           <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Edit">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Edit"></i>
           Edit
           </div>
           <div class="btn btn-danger btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Delete">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="Delete"></i>
           Delete
           </div>
           </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ]
    }
  }
   componentDidMount() {
   this.props.ListConfiguration();
   }
  deleteConfiguration(id) {
    swal({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this imaginary file!",
         icon: "warning",
         buttons: true,
         dangerMode: true,
       })
       .then((willDelete) => {
         if (willDelete) {
           this.props.DeleteConfiguration(id);
         } else {
           console.log('-=-=-=-=-=cancel-=-=-=-=',);
         }
   });
  }
  onSortChanged = e => {
   e.api.refreshCells();
  }
  onRowClicked = event => {
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
    if(value === 'Edit' ){
      this.setState({redirect:'Edit',id:event.data._id})
    }
    if(value === 'Delete'){
      this.deleteConfiguration(event.data._id)
    }
  }

  render()  {
    // const configurationCount     = this.props.ConfigurationReducer.configurationCount;
    const configurationList      = this.props.ConfigurationReducer.configurationList;
    // const configurationListArray = [];
    if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/editConfiguration/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/viewConfiguration/'+this.state.id}/>
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Configuration List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Configuration List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Configuration</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div className="ag-theme-balham" style={ {height: '600px', width: 'auto'} }>
              <AgGridReact
                  rowHeight={50}
                  rowClass ={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDefs}
                  rowData={configurationList}>
              </AgGridReact>
            </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer : state.ConfigurationReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ListConfiguration : AC_LIST_CONFIGURATION,DeleteConfiguration:AC_DELETE_CONFIGURATION},dispatch)
}
const ListConfigurationComponent = connect(mapStateToProps,mapDispatchToProps)(ListConfiguration);

export default ListConfigurationComponent;
