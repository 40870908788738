import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_COUNTRY } from '../../actions/countryaction';


class ViewCountry extends React.Component {

    componentDidMount() {
      const id = this.props.match.params.id;
      this.props.ViewCountry(id);
}

  render() {
    const countryInfo  = this.props.CountryReducer.countryInfo;
    const countryname = countryInfo.countryname;
    const countrycode   = countryInfo.countrycode;
    const status             = this.props.CountryReducer.countryInfo.status;
    return(
        <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>View Country</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">View Country</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View Country</h3>
                  </div>

                    <div className="card-body">
                      <div className="form-group">
                        <label >Country Name</label>
                        <input type="text" className="form-control" disabled name="countryname" id="countryname" placeholder="Country Name" value={countryname}/>
                      </div>
                      <div className="form-group">
                        <label>Country Code</label>
                        <input type="text" className="form-control" disabled name="countrycode" id="countrycode" placeholder="Country Code" value={countrycode} />

                        </div>
                      <div className="form-group">
                         <label>Status</label>
                         <select name = "status" className="form-control" disabled>
                         <option value="0" selected={status === 1}>Active</option>
                         <option value="1" selected={status === 0}>Inactive</option>
                         </select>
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CountryReducer : state.CountryReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCountry: AC_VIEW_COUNTRY},dispatch)
}
const ViewCountryComponent = connect(mapStateToProps,mapDispatchToProps)(ViewCountry);

export default ViewCountryComponent;
