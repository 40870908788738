import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_CARTYPE } from '../../actions/cartypeaction';
import { Imagevalidation } from '../../common/validate';

class AddCarType extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    cartype      : '',
    cartypeerror : false,
    status       : '1',
    image        : '',
    imgSrc       : 'profile.jpg',
    imageerror   : false,
    imagecheck   : false
  }
  this.validate=this.validate.bind(this);
 }
 // validation
 validate() {
    const cartype     = this.state.cartype;
    const image       = this.state.imagedata;
    const status      = this.state.status;
    const imagecheck  = this.state.imagecheck;
    if(cartype) {
       this.setState({cartypeerror : false})
    } else {
       this.setState({cartypeerror : true})
    }
    if(image) {
       this.setState({imageerror : false})
    } else {
       this.setState({imageerror : true})
    }

    if(cartype && image &&!imagecheck) {
         this.setState({ cartype:"",imagedata:"",status:"1",imgSrc : "profile.jpg"});
         const formData = new FormData();
         formData.append('image', image);
         formData.append('cartype', cartype);
         formData.append('status', status);
         this.props.AddCarType(formData);
   }
 }
 // Onchange events
 onChangeValue(event) {
   const name     = event.target.name;
   const value    = event.target.value;
   if(name ==='cartype') {
     if(value){
       this.setState({cartype:value,cartypeerror:false})
     } else {
       this.setState({cartype:value,cartypeerror:true})
     }
   }
   if(name ==='status') {
     if(value!=="") {
       this.setState({status:value})
     } else {
       this.setState({status:value})
     }
   }
   // Image
   if(name==='image') {
      this.setState({ imagedata : event.target.files[0] })
      this.setState({imageerror:false})
      var file            =  event.target.files[0];
      if(file){
      const imgvalidate   =  Imagevalidation(file);
      if(imgvalidate) {
        var reader          = new FileReader();
        var url             = reader.readAsDataURL(file);
        reader.onloadend    = function(e){
        this.setState({imgSrc : [reader.result],imagecheck:false})
      }.bind(this);
      } else {
        this.setState({imagecheck:true,imgSrc : "profile.jpg"});
      }}
   }
 }
render() {
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Add Car Types</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Add Car Types</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add Car Types</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Car Type</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="cartype" value={this.state.cartype} placeholder="Enter Car Type" pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.cartypeerror ? <label style={{color:"red"}}>Car Type is required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Image</label><span style={{color:"red"}}>*</span>
                    <div className="input-group">
                       <div className="custom-file">
                           <input type="file" name="image" className="custom-file-input" id="exampleInputFile" onChange={(e)=>this.onChangeValue(e)}/>
                           <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                       </div>
                       <div className="input-group-append">
                           <button className="input-group-text" id="">Upload</button>
                       </div>
                    </div>
                        {this.state.imageerror? <label style={{color:'red'}}>Image is Required</label>:""}
                        {this.state.imagecheck? <label style={{color:'red'}}>Image format is Invalid</label>:""}
                    <div>
                       <img src={this.state.imgSrc} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>
                     </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                     <select name = "status" className="form-control" value={this.state.status} onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1">Active</option>
                       <option value = "0">Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                       <button type="button" className="btn btn-primary" onClick={this.validate}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CarTypeReducer : state.CarTypeReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddCarType: AC_ADD_CARTYPE},dispatch)
}
const AddCarTypeComponent = connect(mapStateToProps,mapDispatchToProps)(AddCarType);

export default AddCarTypeComponent;
