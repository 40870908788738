import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import { AC_LIST_OWNER, AC_DELETE_USER, AC_USER_VERIFY, AC_SEND_EMAIL_CAMPAIGN } from '../../actions/useraction';
import Button from 'react-bootstrap/Button';

class OwnerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goBack: false,
      columnDefs: [
        {
          "header": "#", field: "#", valueGetter: "node.rowIndex+1", width: "50",
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          "headerName": "Prénoms", field: "firstname", width: "200",
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          "headerName": "Nom", field: "lastname", width: "200",
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          "headerName": "Adresse email", field: "email", width: "250",
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        {
          headerName: 'Nombre de véhicule', field: "cars.length", width: "250",
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          }
        },
        // { "header" : "status", field :"status",width:"100",
        //   cellStyle: function(params) {
        //        return {'font-size': '16px','margin-top': '5px'};
        //   },
        //   cellRenderer: function(params) {
        //    if(params.data.status) {
        //      return '<span class="badge badge-success">Active</span>';
        //    } else {
        //      return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);">Inactive</span>';
        //    }
        //  }
        // },
        {
          "headerName": "Statut", field: "userverified", width: "200",
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.verification === 1) {
              return '<span class="badge badge-success" style="font-size: 12px;">Verifié</span>';
            } else {
              return '<span class="badge badge-success" style="font-size: 12px;background-color: rgb(220, 53, 69);">Non vérifié</span>';
            }
          }
        },
        {
          headerName: 'Actions', width: "100", sortable: false, filter: false,
          template:
            `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px", font-size: "12px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           Voir
           </div>
           </div>`,
          cellStyle: function (params) {
            return { 'margin-top': '8px' };
          },
        },
      ]
    }
  }

  deleteUser(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          this.props.DeleteUser(id);
        } else {
          console.log('-=-=-=-=-=cancel-=-=-=-=',);
        }
      });
  }
  componentDidMount() {
    this.props.ownerList();
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event => {
    const rowValue = event.event.target;
    const value = rowValue.getAttribute('data-action-type');
    if (value === 'View') {
      this.setState({ redirect: 'View', id: event.data._id })
    }
    if (value === 'Edit') {
      this.setState({ redirect: 'Edit', id: event.data._id })
    }
    if (value === 'Delete') {
      this.deleteUser(event.data._id)
    }
    if (value === 'Verify') {
      this.updateVerify(event.data);
    }
  }
  render() {
    const ownerListCount = this.props.UserReducer.ownerListCount;
    const ownerList = this.props.UserReducer.ownerList;
    // const userListArray     = [];
    if (this.state.redirect === 'Edit') {
      return <Redirect push to={'/EditUser/' + this.state.id} />
    }
    if (this.state.redirect === 'View') {
      return <Redirect push to={'/ViewOwner/' + this.state.id} />
    }

    if (this.state.goBack) {
      return <Redirect push to={'/Dashboard'} />
    }
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Fiche propriétaire {ownerListCount}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item" onClick={() => this.setState({goBack: true})} style={{cursor: 'pointer'}}>Home</li>
                  <li className="breadcrumb-item active">Propriétaires</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Propriétaire</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
              <div className='container'>
                <div className="row" style={{justifyContent: 'right'}}>
                  <Button variant="light" style={{borderRadius: '25px', border: "1px solid #eee"}} onClick={() => {
                    // console.log("Envoyer un mail à " + ownerListCount + " propriétaires.")
                    this.props.SendSMSCampaign();
                    
                    }} className='my-2 mx-2'> Envoyer un mail aux {ownerListCount} propriétaires</Button>
                </div>
              </div>
              <div className="ag-theme-balham" style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={50}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  onRowClicked={(e) => this.onRowClicked(e)}
                  rowSelection={'single'}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={{ filter: true, floatingFilter: true }}
                  rowData={ownerList}>
                </AgGridReact>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    UserReducer: state.UserReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ownerList: AC_LIST_OWNER, DeleteUser: AC_DELETE_USER, SendSMSCampaign: AC_SEND_EMAIL_CAMPAIGN, UpdateUserVerify: AC_USER_VERIFY }, dispatch);
}
const OwnerListComponent = connect(mapStateToProps, mapDispatchToProps)(OwnerList);

export default OwnerListComponent;
