import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_NEWSLETTER,AC_EDIT_NEWSLETTER,AC_HANDLE_INPUT_CHANGE } from '../../actions/newsletteraction';

class EditNewsletter extends React.Component {

  constructor(props){
    super(props);
      this.state = {
        nameError           :false,
        subjectError        :false,
      }
    this.validateform=this.validateform.bind(this);
  }
    componentDidMount() {
      const id = this.props.match.params.id;
      this.props.ViewNewsLetter(id);
      window.$(function (){
        window.$('#compose-textarea').summernote({ height: 200,focus: true });
      })
    }
    //validation
    validateform(){
      var name         = this.props.NewsletterReducer.newsLetterInfo.templatename;
      var subject      = this.props.NewsletterReducer.newsLetterInfo.emailsubject;
      const content    = window.$('#compose-textarea').summernote('code');
      const id         = this.props.NewsletterReducer.newsLetterInfo.id;
      var checkdescription = 0;
      if(name){
        this.setState({nameError:false});
      }else{
        this.setState({nameError:true});
      }
      if(subject){
        this.setState({subjectError:false});
      }else{
        this.setState({subjectError:true});
      }
      if(content) {
        this.setState({descriptionError:false});
        checkdescription=1;
      } else {
        this.setState({descriptionError:true});
      }
      name           =  name.charAt(0).toUpperCase() + name.slice(1);
      subject        =  subject.charAt(0).toUpperCase() + subject.slice(1);
      if(name && subject &&checkdescription ) {
        // Formdata to send Api
          const newsLetterFormData = {
                                templatename : name,
                                emailsubject : subject,
                                emailcontent : content,
                                id           : id
                              }
          this.props.EditNewsLetter(newsLetterFormData);
      }
     }
    //onchange values
    onChangeValue(event) {
      const name          =  event.target.name;
      const value         =  event.target.value;
      this.props.HandleInputChange(name,value)
    }

  render() {
    const newsLetterInfo = this.props.NewsletterReducer.newsLetterInfo;
    const name = newsLetterInfo.templatename;
    const subject = newsLetterInfo.emailsubject;
    const description=newsLetterInfo.emailcontent;
    window.$('#compose-textarea').summernote('code',description);
      return(
          <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Edit News Letter</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">Edit News Letter</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-primary">
                        
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Template Name<span style={{color:"red"}}>*</span></label>
                              <input type="name" name="name"  className="form-control" placeholder="Enter email"  value={name}  onChange={(e)=>this.onChangeValue(e)}/>
                            </div>
                              {this.state.nameError?<label style={{color:"red"}}>Name is Required</label>:""}
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Email Subject<span style={{color:"red"}}>*</span></label>
                              <input type="email" name="subject"  className="form-control" id="exampleInputPassword1" placeholder="Email Subject"  value={subject}  onChange={(e)=>this.onChangeValue(e)}/>
                              {this.state.subjectError?<label style={{color:"red"}}>Email Subject is Required</label>:""}
                            </div>
                            <label htmlFor="exampleInputPassword1">Email Content<span style={{color:"red"}}>*</span></label><br/>
                            <div className="form-group">
                               <textarea type="textarea" id="compose-textarea" name="description" className="form-control" style={{height: "200px"}}/>
                               {this.state.descriptionError?<label style={{color:"red"}}>Email Content is Required</label>:""}
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary"  onClick={this.validateform} >Update</button>
                          </div>
                      
                      </div>
                   </div>
                 </div>
               </div>
             </section>
           </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    NewsletterReducer : state.NewsletterReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewNewsLetter: AC_VIEW_NEWSLETTER,EditNewsLetter : AC_EDIT_NEWSLETTER,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditNewsletterComponent = connect(mapStateToProps,mapDispatchToProps)(EditNewsletter);

export default EditNewsletterComponent;
