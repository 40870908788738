import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_LANGUAGE} from '../../actions/languageaction';


class ViewLanguage extends React.Component {

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewLanguage(id);
  }

  render() {
        const languageInfo   = this.props.LanguageReducer.languageInfo;
        const languagename   = languageInfo.languagename;
        const languagecode   = languageInfo.languagecode;
        const status         = languageInfo.status;
    return(
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>View Language</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">View Language</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">View Language</h3>
                      </div>
                      <form>
                        <div className="card-body">
                           <div className="form-group">
                              <label>Language Name</label>
                              <input type="text" disabled  className="form-control" name="name" placeholder="Enter Language Name" value={languagename} pattern="[A-Za-z]" />
                           </div>
                           <div className="form-group">
                              <label>Language Code</label>
                              <input type="text" disabled className="form-control" name="code" placeholder="Enter Language Code" value={languagecode} />
                             </div>
                           <div className="form-group">
                              <label>Status</label>
                              <select name = "status" disabled className="form-control" >
                                <option value = {1} selected={status === 1}>Active</option>
                                <option value = {0} selected={status === 0}>Inactive</option>
                              </select>
                             </div>

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
         )
       }
  }
function mapStateToProps(state) {
  return {
    LanguageReducer : state.LanguageReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewLanguage: AC_VIEW_LANGUAGE},dispatch)
}
const ViewLanguageComponent = connect(mapStateToProps,mapDispatchToProps)(ViewLanguage);

export default  ViewLanguageComponent;
