import React, { useState } from 'react';
import { Modal, Tabs, Tab, Card, Col, Container, Row, Image } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import API from '../../common/api';
const styleCard =
{
  fontSize: '12px',
  paddingBottom: '2px',
  paddingTop: "2px",
  textAlign: 'center',
  paddingLeft: "0",
  paddingRight: "0",
  fontWeight: '700'
};
const styleBody = { display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' };
const MyDialog = ({ show, onHide, data }) => {
  const [key, setKey] = useState('general');
  const [columnDef, setColumnDef] = useState([
    {
      headerName: "Nom client", field: "customerId.firstname", width: '120',
    },
    {
      headerName: "Prénom client", field: "customerId.lastname", width: '120',
    },
    {
      headerName: "email client", field: "customerId.email", width: '120',
    },
    {
      headerName: "Montant location", field: "price", width: '120',
    },
    {
      headerName: "Date de début", field: "checkin", width: '120',
    },
    {
      headerName: "Date de fin", field: "checkout", width: '120',
    },
    {
      headerName: "Statut", field: "tripStatus", width: '120',
      cellRenderer: function (params) {
        if (params.data.tripStatus === 1) {
          return '<span class="badge badge-success">En cours</span>';
        } else if (params.data.tripStatus === 2) {
          return `<span class="badge badge-success">Payée</span>`;
        } else if (params.data.tripStatus === 3) {
          return `<span class="badge badge-success">Acceptée</span>`;
        }
        else if (params.data.tripStatus === 4) {
          return `<span class="badge badge-danger">Annulée</span>`;
        }
        else if (params.data.tripStatus === 0) {
          return `<span class="badge badge-warning">Refusée</span>`;
        }
      }
    },


  ]

  )
  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="lg" fullscreen={'xl-down'}>
      <Modal.Header closeButton>
        <Modal.Title>Details sur le véhicule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          {/* <Tab eventKey="general" title="General">
            <p>Contenu de l'onglet General</p>
          </Tab> */}
          <Tab eventKey="image" title="Image">
            <Container>
              <Row>
                 <Col xs={2} md={4} >
                  <Image src={`${API.IMAGEURL}${data?.carimage?.mainimage}`} width={171} height={171} roundedCircle /> 
                </Col> 
                <Col xs={2} md={4} >
                  <Image src={`${API.IMAGEURL}${data?.carimage?.backimage}`} width={171} height={171} roundedCircle />
                </Col>
              
                <Col xs={2} md={4} >
                  <Image src={`${API.IMAGEURL}${data?.carimage?.interiorimage}`} width={171} height={171} roundedCircle />
                </Col>
                <Col xs={2} md={4} >
                  <Image src={`${API.IMAGEURL}${data?.carimage?.extraimage}`} width={171} height={171} roundedCircle />
                </Col>
                
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="locations" title={`Locations (${data?.tripsInfo?.trips.length})`}>
            <Container className='mt-2'>
              <Row>
                <Col md={2}>
                  <Card>
                    <Card.Body className='text-center' style={styleBody}>
                      <Card.Title style={styleCard} className='text-center'
                      >
                        Acceptées
                      </Card.Title>
                      <Card.Text className='text-center'>{data?.tripsInfo?.accepted}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}><Card>
                  <Card.Body className='text-center' style={styleBody}>
                    <Card.Title style={styleCard}>
                      Réfusées
                    </Card.Title>
                    <Card.Text className='text-center'>{data?.tripsInfo?.refused}</Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                <Col md={2}>
                  <Card>
                    <Card.Body style={styleBody}>
                      <Card.Title style={styleCard}>
                        Annulées
                      </Card.Title>
                      <Card.Text className='text-center'>{data?.tripsInfo?.canceled}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card>
                    <Card.Body style={styleBody}>
                      <Card.Title style={styleCard}>
                        En cours
                      </Card.Title>
                      <Card.Text className='text-center'>{data?.tripsInfo?.inProgress}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card>
                    <Card.Body style={styleBody}>
                      <Card.Title style={styleCard}>
                        Payéés
                      </Card.Title>
                      <Card.Text className='text-center'>{data?.tripsInfo?.paid}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <div
                  className="ag-theme-balham"
                  style={{ width: '100%' }}>
                  <AgGridReact
                    alwaysShowVerticalScroll
                    rowHeight={50}
                    enableCharts
                    gridAutoHeight
                    rowClass={'centerAlign'}
                    // onSortChanged={this.onSortChanged}
                    rowSelection={'single'}
                    // onRowClicked={(e) => this.onRowClicked(e)}
                    enableSorting={false}
                    suppressCellSelection={true}
                    enableFilter={false}
                    enableColResize={true}
                    paginationAutoPageSize={true}
                    columnDefs={columnDef}
                    defaultColDef={{ filter: false, floatingFilter: false }}
                    rowData={data?.tripsInfo?.trips}>
                  </AgGridReact>
                </div >
              </Row>
            </Container>

          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default MyDialog;