import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE,AC_UPDATE_ADMIN_SETTINGS } from '../../actions/settings';

class GoogleWebmaster extends React.Component {
  constructor(props){
    super(props);
    this.validateForm = this.validateForm.bind(this);
  }

  // Validation
  validateForm() {
    const webMasterInfo = this.props.SettingsReducer.webMasterInfo;
    const id            = this.props.SettingsReducer.settingId;
    this.props.updateAdminSettings(webMasterInfo,id,"Web master");
 }

 // Onchange Values
 onChangevalue(event){
   const name = event.target.name;
   const value = event.target.value;
   this.props.handleInputChange(name,value,'webmaster')
 }
  render() {
    const webMasterInfo = this.props.SettingsReducer.webMasterInfo;
    var metatitle                     = "";
    var metakeyword                   = "";
    var metadescription               = "";
    var googleanalyticscode           = "";
    var googlehTMLmetaverifcationcode = "";
    if(webMasterInfo) {
      metatitle                     = webMasterInfo.metatitle;
      metakeyword                   = webMasterInfo.metakeyword;
      metadescription               = webMasterInfo.metadescription;
      googleanalyticscode           = webMasterInfo.googleanalyticscode;
      googlehTMLmetaverifcationcode = webMasterInfo.googlehTMLmetaverifcationcode;
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Google Webmaster</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Google webmaster</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Google Webmaster & SEO</h3>
                  </div>
                  
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleMetaTitle">Meta Title</label>
                        <input type="text" className="form-control" name="metatitle" placeholder="Enter meta title" value={metatitle} onChange={(e) => this.onChangevalue(e)}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleMetaKeyword">Meta Keyword</label>
                        <input type="text" className="form-control" name="metakeyword" placeholder="Enter meta keyword" value={metakeyword} onChange={(e) => this.onChangevalue(e)}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleMetaDescription">Meta Description</label>
                        <textarea type="text" className="form-control" name="metadescription" placeholder="Enter meta description" value={metadescription} onChange={(e) => this.onChangevalue(e)}></textarea>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleGoogleAnalyticsCode">Google Analytics Code</label>
                        <textarea type="text" className="form-control" name="googleanalyticscode" placeholder="Enter google analytics code" value={googleanalyticscode} onChange={(e) => this.onChangevalue(e)}></textarea>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleGoogleHTMLMetaVerifcationCode">Google HTML Meta Verifcation Code</label>
                        <input type="text" className="form-control" name="googlehTMLmetaverifcationcode" placeholder="Enter google HTML meta verifcation code" value={googlehTMLmetaverifcationcode} onChange={(e) => this.onChangevalue(e)}/>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="button" className="btn btn-primary" onClick={this.validateForm}>Submit</button>
                    </div>
               
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({handleInputChange:AC_HANDLE_INPUT_CHANGE,updateAdminSettings:AC_UPDATE_ADMIN_SETTINGS},dispatch)
}
const GoogleWebmasterComponent = connect(mapStateToProps,mapDispatchToProps)(GoogleWebmaster);

export default GoogleWebmasterComponent;
