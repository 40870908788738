import { combineReducers } from 'redux';
import FaqReducer from './reducers/faqreducer';
import LanguageReducer from './reducers/languagereducer';
import CountryReducer from './reducers/countryreducer';
import CarTypeReducer from './reducers/cartypereducer';
import NewsletterReducer from './reducers/newsletterreducer';
import BrandReducer from './reducers/brandreducer';
import HostCarReducer from  './reducers/carlistreducer';
import TripReducer from  './reducers/tripreducer';
import UserReducer from './reducers/userreducer';
import CarReducer from './reducers/carlistsreducer';
import SettingsReducer from './reducers/settingsreducer';
import SubscriptionReducer from './reducers/subscriptionreducer';
import RentReducer from  './reducers/rentreducer';
import ConfigurationReducer from  './reducers/configurationreducer';
import CarfeaturesReducer from  './reducers/carfeaturesreducer';
import NotificationReducer from  './reducers/notificationreducer';
import PagesReducer from  './reducers/pagesreducer';
import PayementgatewayReducer from  './reducers/payementgatewayreducer';
import CurrencyReducer from  './reducers/currencyreducer';
import DashboardNotifsReduccer from './reducers/dashboardnotifsreducer';

export default combineReducers({
  FaqReducer,
  CountryReducer,
  CarTypeReducer,
  NewsletterReducer,
  LanguageReducer,
  BrandReducer,
  HostCarReducer,
  TripReducer,
  UserReducer,
  CarReducer,
  SettingsReducer,
  SubscriptionReducer,
  RentReducer,
  ConfigurationReducer,
  CarfeaturesReducer,
  NotificationReducer,
  PagesReducer,
  PayementgatewayReducer,
  CurrencyReducer,
  DashboardNotifsReduccer
})
