import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success, Loader, Error  } from '../common/swal';

const LIST_PAYEMENTGATEWAY        = 'LIST_PAYEMENTGATEWAY';
const ADD_PAYEMENTGATEWAY         = 'ADD_PAYEMENTGATEWAY';
const EDIT_PAYEMENTGATEWAY        = 'EDIT_PAYEMENTGATEWAY';
const VIEW_PAYEMENTGATEWAY        = 'VIEW_PAYEMENTGATEWAY';
const UPDATE_PAYEMENTGATEWAY_DATA = 'UPDATE_PAYEMENTGATEWAY_DATA';
const DELETE_PAYEMENTGATEWAY      = 'DELETE_PAYEMENTGATEWAY';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})
export function AC_UPDATE_PAYMENTGATRWAYSTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdatePaymentGatewayStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: DELETE_PAYEMENTGATEWAY, payload:data});
       }
    });
  };
}

export function AC_DELETE_PAYEMENTGATEWAY(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeletePayementgateway,formData)
      .then(({ data }) => {
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
      } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_PAYEMENTGATEWAY, payload:data});
      }
    });
  }
}

export function AC_ADD_PAYEMENTGATEWAY(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddPayementgateway,formData)
      .then(({ data }) => {
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
      } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_PAYEMENTGATEWAY, payload:data});
      }
    });
  };
}

export function AC_LIST_PAYEMENTGATEWAY() {
  return function(dispatch) {
       Loader();
    return authAxios.get(URL.API.ListPayementgateway)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
        }
        dispatch({type: LIST_PAYEMENTGATEWAY, payload:data});
    });
  };
}

export function AC_VIEW_PAYEMENTGATEWAY(formData) {
   Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewPayementgateway,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
        }
        dispatch({type: VIEW_PAYEMENTGATEWAY, payload:data});
    });
  };
}

export function AC_EDIT_PAYEMENTGATEWAY(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditPayementgateway,formData)
      .then(({ data }) => {
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
        }
        else{
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_PAYEMENTGATEWAY, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_PAYEMENTGATEWAY_DATA, name:name,value:value})
  };
}
