import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_USER,AC_EDIT_USER,AC_HANDLE_INPUT_CHANGE} from '../../actions/useraction';
class EditUser extends React.Component {
  constructor(props){
    super(props);
    this.state={
        firstnameError      :   false,
        lastnameError       :   false,
        genderError         :   false,
        ageError            :   false,
        countryError        :   false,
        mobileError         :   false,
        statusError         :   false,
    }
        this.validateform = this.validateform.bind(this);
  }
  componentWillMount() {
    const id = this.props.match.params.id;
    this.props.ViewUser(id);
  }

  validateform(){
    var firstname     =   this.props.UserReducer.userInfo.firstname;
    var lastname      =   this.props.UserReducer.userInfo.lastname;
    var gender        =   this.props.UserReducer.userInfo.gender;
    const age         =   this.props.UserReducer.userInfo.age;
    var country       =   this.props.UserReducer.userInfo.country;
    const mobile      =   this.props.UserReducer.userInfo.mobile;
    const status      =   this.props.UserReducer.userInfo.status;
    const id          =   this.props.UserReducer.userInfo.id;

    if(firstname)
    {
        this.setState({firstnameError:false})
    }
    else{
        this.setState({firstnameError:true})
    }
    if(lastname)
    {
        this.setState({lastnameError:false})
    }
    else{
        this.setState({lastnameError:true})
    }
    if(gender)
    {
        this.setState({genderError:false})
    }
    else{
        this.setState({genderError:true})
    }
    if(age)
    {
        this.setState({ageError:false})
    }
    else{
        this.setState({ageError:true})
    }
    if(country)
    {
        this.setState({countryError:false})
    }
    else{
        this.setState({countryError:true})
    }
    if(mobile)
    {
        this.setState({mobileError:false})
    }
    else{
        this.setState({mobileError:true})
    }
    firstname  =  firstname.charAt(0).toUpperCase() + firstname.slice(1);
    lastname   =  lastname.charAt(0).toUpperCase() + lastname.slice(1);
    gender     =  gender.charAt(0).toUpperCase() + gender.slice(1);
    country    =  country.charAt(0).toUpperCase() + country.slice(1);
    if(firstname && lastname && gender && country && age && mobile) {
    // Formdata to send Api
    const userFormData = {
       firstname     :  firstname,
       lastname      :  lastname,
       gender        :  gender,
       age           :  age,
       country       :  country,
       mobile        :  mobile,
       status        :  status,
       id            :  id
                        }
    this.props.EditUser(userFormData);
  }
  }
  onChangeValue(event){
    const name          =  event.target.name;
    const value         =  event.target.value;
    this.props.HandleInputChange(name,value)
  }
  render(){
      const userInfo      = this.props.UserReducer.userInfo;
      const firstname     = userInfo.firstname;
      const lastname      = userInfo.lastname;
      const gender        = userInfo.gender;
      const age           = userInfo.age;
      const country       = userInfo.country;
      const mobile        = userInfo.mobile;
      const status        = userInfo.status;
      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                 <div className="col-sm-6">
                    <h1>Edit User</h1>
                 </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">Edit User</li>
                    </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View User</h3>
                  </div>
                    
                      <div className="card-body">
                        <div className="form-group">
                          <label >First Name</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="firstname" placeholder="First Name" value={firstname} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.firstnameError?<label style={{color:'red'}}>First name is Required</label>:""}
                        </div>
                        <div className="form-group">
                          <label >Last Name</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="lastname" id="lastname" placeholder="Last Name" value={lastname} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.lastnameError?<label style={{color:'red'}}>Last name is Required</label>:""}
                        </div>
                        <div className="form-group">
                          <label >Gender</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="gender" id="gender" placeholder="Gender" value={gender} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.genderError?<label style={{color:'red'}}>Gender is Required</label>:""}
                        </div>
                        <div className="form-group">
                          <label >Age</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="age" id="age" placeholder="age" value={age} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.ageError?<label style={{color:'red'}}>Age is Required</label>:""}
                        </div>
                        <div className="form-group">
                          <label >Country</label><span style={{color:"red"}}>*</span>
                          <input type="text" className="form-control" name="country" id="country" placeholder="Country" value={country} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.countryError?<label style={{color:'red'}}>Country is Required</label>:""}
                        </div>
                        <div className="form-group">
                          <label >Mobile Number</label><span style={{color:"red"}}>*</span>
                          <input type="number" className="form-control" name="mobile" id="mobile" placeholder="Mobile Number" value={mobile} onChange={(e)=> this.onChangeValue(e)}/>
                          {this.state.mobileError?<label style={{color:'red'}}>Mobile Number is Required</label>:""}
                        </div>
                        <div className="form-group">
                           <label>Status</label>
                           <select name = "status" className="form-control" onChange ={(e) => this.onChangeValue(e)}s>
                             <option value = "1" selected={status === 1}>Active</option>
                             <option value = "0" selected={status === 0}>Inactive</option>
                           </select>
                           {this.state.statuserror ? <label style={{color:"red"}}>Status is Required</label> : ""}
                        </div>
                        <div className="card-footer">
                          <button type="button" className="btn btn-primary" onClick={this.validateform}>Update</button>
                        </div>
                        </div>
                       
                      </div>
                    </div>
                </div>
             </div>
        </section>
    </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    UserReducer : state.UserReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewUser: AC_VIEW_USER,EditUser : AC_EDIT_USER,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditUserComponent = connect(mapStateToProps,mapDispatchToProps)(EditUser);
export default EditUserComponent;
