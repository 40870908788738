import React from 'react';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { AC_LIST_SUBSCRIPTION,AC_EMAIL_SUBSCRIPTION } from '../../actions/subscriptionaction'
import { AC_LIST_NEWSLETTER } from '../../actions/newsletteraction';

class SubscriptionsList extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      selectedId    : '',
      selectedEmail : '',
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '150',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Email", field: "email", width: '500',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        { headerName: "Status", field: "status", width: '400',
          cellStyle: function(params) {
          return {'font-size': '16px','margin-top': '5px'};
        },
          cellRenderer: function(params) {
           if(params.data.status) {
             return '<span class="badge badge-success">Active</span>';
           } else {
             return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);">Inactive</span>';
           }
         }
        },
         {
          headerName: 'Sendmail', field: 'sendmail',filter:false,sortable:false,
          cellRenderer: function (params) {
              return `<button class="btn btn-primary btn-sm" data-toggle="modal" data-target="#exampleModal" data-action-type="sendmail" style={{ width: "51px", height:"30px", padding:"10px" }}>Send mail</button>`
          }
        }
      ]
    }
     this.sendMail = this.sendMail.bind(this);
  }
  componentDidMount() {
    this.props.ListSubscription();
    this.props.ListNewsletter();
  }
  sendMail(){
    // const newsLetterList = this.props.NewsletterReducer.newsLetterList;
    const tomail         = this.state.selectedEmail;
    const id             = this.state.selectedId;
    if(id){
        const mailData = {
          tomail       : tomail,
          id           : id
        }
        this.props.EmailSubscription(mailData);
   }
   else{
     swal("","Select a Template","error");
   }
  }

  setValue(event){
    this.setState({selectedEmail: event.target.value});
  }

  onchangeValue(event) {
    const value        = event.target.value;
    this.setState({selectedId:value})
   }
   onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event =>{
    // const rowValue = event.event.target;
    // const value    = rowValue.getAttribute('data-action-type');
    this.setState({selectedEmail: event.data.email})
  }
  render(){
    // const  subscriptionCount     = this.props.SubscriptionReducer.subscriptionCount;
    const  subscriptionList      = this.props.SubscriptionReducer.subscriptionList;
    const  newsletterList        = this.props.NewsletterReducer.newsLetterList;
    // const  subscriptionListArray = [];
    const  newsletterListArray   = [];
    if(newsletterList.length >= 0) {
      for (var j = 0; j < newsletterList.length; j++) {
        newsletterListArray.push(<option value={newsletterList[j]._id}>{newsletterList[j].templatename}</option>)
      }
    }
      return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Subscriptions List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Subscriptions List</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Subscriptions</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <select class="btn" onChange={(e) => this.onchangeValue(e)}>
                                    <option>Select Template</option>
                                    {newsletterListArray}
                                </select>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={this.sendMail}>Send mail</button>
                            </div>
                        </div>
                    </div>
                </div>
            <div className="card-body p-0">
            <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  enableFilter
                  suppressCellSelection={true}
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  rowData={subscriptionList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    SubscriptionReducer : state.SubscriptionReducer,
    NewsletterReducer   : state.NewsletterReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ListSubscription: AC_LIST_SUBSCRIPTION,EmailSubscription: AC_EMAIL_SUBSCRIPTION,ListNewsletter:AC_LIST_NEWSLETTER},dispatch)
}
const SubscriptionsListComponent = connect(mapStateToProps,mapDispatchToProps)(SubscriptionsList);

export default SubscriptionsListComponent;
