import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_PAGES } from '../../actions/pagesaction';


class ViewPages extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewPages(id);
    window.$(function (){
      window.$('#compose-textarea').summernote({ height: 200,focus: true });
      window.$('#compose-textarea').summernote('disable');

    })
  }

  render() {
    const pagesInfo    = this.props.PagesReducer.pagesInfo;
    const name         = pagesInfo.name;
    const slug         = pagesInfo.slug;
    const description  = pagesInfo.description;
    window.$('#compose-textarea').summernote('code',description);
    return(
          <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>View Pages</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">View Pages</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-primary">
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Pages Name</label>
                              <input type="name" name="name"  disabled className="form-control" placeholder="Pages Name"  value={name} />
                            </div>
                            <div className="form-group">
                              <label >Slug</label>
                              <input  className="form-control" rows="4" name="slug" value={slug} disabled></input>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Description</label>
                              <div className="form-group">
                                 <textarea type="textarea" id="compose-textarea" disabled name="description" className="form-control" style={{height: "200px"}}/>
                              </div>
                            </div>
                          </div>
                      </div>
                   </div>
                 </div>
               </div>
             </section>
           </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    PagesReducer : state.PagesReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewPages: AC_VIEW_PAGES},dispatch)
}
const ViewPagesComponent = connect(mapStateToProps,mapDispatchToProps)(ViewPages);

export default ViewPagesComponent;
