import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE,AC_UPDATE_ADMIN_SETTINGS } from '../../actions/settings';
import {Imagevalidation} from '../../common/validate';
import API from '../../common/api';

class ProfileSettings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      faviconvalue      : '',
      imgSrc            : 'user.png',
      emailError        : false,
      numberError       : false,
      faviconError      : false,
      imagevalue        : false,
      imagecheck        : false
    }
    this.validateform = this.validateform.bind(this);
  }
  validateform() {
      const profileInfo    = this.props.SettingsReducer.profileInfo;
      const id             = this.props.SettingsReducer.settingId;
      const imagecheck     = this.state.imagecheck;
      var favicon          = profileInfo.favicon;
      if(!imagecheck) {
          const formData = new FormData();
          formData.append('name', profileInfo.name);
          formData.append('contactemail', profileInfo.contactemail);
          formData.append('contactnumber', profileInfo.contactnumber);
          formData.append('favicon', favicon);
          this.props.updateAdminSettings(formData,id,'Profile');
     }
  }

  handleKeyPress(event){
    const value       =  event.target.value;
    if((value.toString().length)>=10){
      event.preventDefault();
    }
  }

  onChangeValue(event)  {
     const name   = event.target.name;
     var value    = event.target.value;
     if(name==="favicon"){
       value = event.target.files[0];
       this.setState({faviconvalue:value});
       if(value){
         const imgvalidate   =  Imagevalidation(value);
         if(imgvalidate) {
             var reader          = new FileReader();
             var url             = reader.readAsDataURL(value);
             reader.onloadend    = function(e){
             this.setState({imgSrc : [reader.result],imagevalue:true,imagecheck:false})
             }.bind(this);
         } else {
         this.setState({imagecheck:true,imgSrc : ""});
         }
       }
     }
     this.props.handleInputChange(name,value,'Profile');
  }

  render() {
    const profileInfo  = this.props.SettingsReducer.profileInfo;
    var name       = "";
    var email      = "";
    var number     = "";
    var favicon    = "";

    if(profileInfo) {
       name       = profileInfo.name;
       email      = profileInfo.contactemail;
       number     = profileInfo.contactnumber;
       favicon    = profileInfo.favicon;
    }
    return(
      <div className="content-wrapper">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                       <div className="col-sm-6">
                          <h1>Profile Settings</h1>
                       </div>
                      <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-right">
                              <li className="breadcrumb-item">Home</li>
                              <li className="breadcrumb-item active">Profile Settings</li>
                          </ol>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card card-primary">

                            <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Name </label>
                                <input type="text" name="name" className="form-control" value={name} placeholder="Enter Name" onChange={(e)=>this.onChangeValue(e)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="text" name="contactemail" className="form-control" value={email} placeholder="Enter Email address" onChange={(e)=>this.onChangeValue(e)}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Contact Number</label>
                                <input type="number" name="contactnumber" className="form-control" value={number} placeholder="Enter Contact Number" onKeyPress={(e)=>this.handleKeyPress(e)} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=>this.onChangeValue(e)}/>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputFile">Image </label>
                              <div className="input-group">
                                <div className="custom-file">
                                  <input type="file" name="favicon" className="custom-file-input" id="exampleInputFile" onChange={(e)=>this.onChangeValue(e)}/>
                                  <label className="custom-file-label" htmlFor="exampleInputFile">Choose image</label>
                                </div>
                                <div className="input-group-append">
                                  <span className="input-group-text" id="">Upload</span>
                                </div>
                              </div>
                            <div>
                            {this.state.imagevalue? <img src={this.state.imgSrc} style={{height:"120px",width:"150px",padding:"10px 1px"}} alt=""/> : favicon?<img src={API.LIVEURL+"uploads/"+favicon} style={{height:"120px",width:"150px",padding:"10px 1px"}} alt=""/>:<img src="user.png" style={{height:"120px",width:"150px",padding:"10px 1px"}} alt=""/>}
                            {this.state.imagecheck? <label style={{color:'red'}}>Image format is Invalid</label>:""}
                        </div>
                       </div>
                     <div className="card-footer">
                      <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                    </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      )
   }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({handleInputChange:AC_HANDLE_INPUT_CHANGE,updateAdminSettings:AC_UPDATE_ADMIN_SETTINGS},dispatch)
}
const ProfileSettingsComponent = connect(mapStateToProps,mapDispatchToProps)(ProfileSettings);

export default ProfileSettingsComponent;
