import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import API from '../../common/api';
import { AC_VIEW_CARFEATURES} from '../../actions/carfeaturesaction';

class ViewCarfeatures extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewCarfeatures(id);
  }
render()
{
      const carfeaturesInfo   = this.props.CarfeaturesReducer.carfeaturesInfo;
      const name              = carfeaturesInfo.name;
      const icon              = carfeaturesInfo.icon;
      const status            = carfeaturesInfo.status;
      const LIVEURL           = API.LIVEURL;
      return(
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1>View CarFeatures</h1>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">Home</li>
                    <li class="breadcrumb-item active">View CarFeatures</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="row">
              <div class="col-md-6">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 className="card-title">View CarFeatures</h3>
                    <div class="card-tools">
                      <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i class="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label >Name</label>
                      <input type="text" id="inputName" class="form-control"  name="name" value={name} disabled />
                    </div>
                    <div class="form-group">
                      <label  htmlFor="exampleInputFile">Icon</label>
                      <div>
                           <img src={LIVEURL+icon} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>
                         </div>
                         <div className="form-group">
                        <label htmlFor="exampleInputFile">Status</label>
                        <select name = "status"  className="form-control" disabled>
                          <option  selected={status == 1}>Active</option>
                          <option  selected={status == 0}>InActive</option>
                       </select>
                         </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

    )
  }
}
function mapStateToProps(state) {
  return {
    CarfeaturesReducer : state.CarfeaturesReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCarfeatures: AC_VIEW_CARFEATURES},dispatch)
}
const ViewCarfeaturesComponent = connect(mapStateToProps,mapDispatchToProps)(ViewCarfeatures);

export default ViewCarfeaturesComponent;
