import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success , Loader , Error} from '../common/swal';

// Action types for Faq management
const LIST_SUBSCRIPTION      = 'LIST_SUBSCRIPTION';
const EMAIL_SUBCRIPTION      = 'EMAIL_SUBCRIPTION';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_LIST_SUBSCRIPTION() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListSubscription)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_SUBSCRIPTION, payload:data});
    });
  };
}

export function AC_EMAIL_SUBSCRIPTION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EmailSubscription,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EMAIL_SUBCRIPTION, payload:data});
      }
    });
  };
}
