import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_FAQ } from '../../actions/faqaction';

class ViewFaq extends React.Component {

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewFaq(id);
  }

  render() {
      const faqInfo  = this.props.FaqReducer.faqInfo;
      const question = faqInfo.question;
      const answer   = faqInfo.answer;
      // const faqtype  = faqInfo.faqtype;
      const status   = faqInfo.status;
      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>View FAQ</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">View FAQ</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View FAQ</h3>
                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label >FAQ Name</label>
                      <input type="text" id="inputName" className="form-control"  name="faq" value={question} disabled />
                    </div>
                    <div className="form-group">
                      <label >Description</label>
                      <textarea id="inputDescription" className="form-control" rows="4" name="description" value={answer} disabled></textarea>
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                        <select name = "status" disabled className="form-control" >
                          <option value = "1" selected={status===1}>Driver Help</option>
                            <option value = "0" selected={status===0}>Owner Help</option>
                        </select>
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                        <select name = "status" disabled className="form-control" >
                          <option value={1} selected={status === 1}>Active</option>
                            <option value ={0} selected={status === 0}>Inactive</option>
                        </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    FaqReducer : state.FaqReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewFaq: AC_VIEW_FAQ},dispatch)
}
const ViewFaqComponent = connect(mapStateToProps,mapDispatchToProps)(ViewFaq);

export default ViewFaqComponent;
