import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_PAYEMENTGATEWAY } from '../../actions/payementgatewayaction';

class AddPayementgateway extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    name        : '',
    mode        : '',
    apikey      : '',
    status      : true,
    nameerror   : false,
    modeerror   : false,
    apikeyerror : false,
  }
  this.validateForm=this.validateForm.bind(this);
}
 //Validation
 validateForm() {
    const name   = this.state.name;
    const mode   = this.state.mode;
    const apikey = this.state.apikey;
    const status = this.state.status;
    if(name) {
       this.setState({nameerror : false})
    }
    else{
       this.setState({nameerror : true})
    }
    if(mode){
        this.setState({modeerror:false});
    }
    else{
        this.setState({modeerror:true});
     }
     if(apikey) {
        this.setState({apikeyerror : false})
     }
     else{
        this.setState({apikeyerror : true})
     }
    if(name && mode && apikey) {
        const formData = {
                      name   : name,
                      mode   : mode,
                      apikey : apikey,
                      status : status
        }
        this.props.AddPayementgateway(formData);
    }
 }
 // Onchange events
 onChangeValue(event){
   const name=event.target.name;
   const value=event.target.value;
   if(name ==='name'){
     if(value){
       this.setState({name:value,nameerror:false})
     } else {
       this.setState({name:value,nameerror:true})
     }
   }
   if(name ==='mode'){
     if(value){
       this.setState({mode:value,modeerror:false})
     } else {
       this.setState({mode:value,modeerror:true})
     }
   }
   if(name ==='apikey'){
     if(value){
       this.setState({apikey:value,apikeyerror:false})
     } else {
       this.setState({apikey:value,apikeyerror:true})
     }
   }
   if(name ==='status'){
     if(value){
       this.setState({status:value})
     }
     else{
       this.setState({status:value})
     }
   }
 }
render() {
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Add Payement Gateway</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Add Payement Gateway</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add Payement Gateway</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="name" placeholder="Enter Name" pattern="[A-Za-z]"  value={this.state.name} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.nameerror ? <label style={{color:"red"}}>Name is Required</label> : ""}
                    </div>
                    <div className="form-group">
                        <label>Payement Mode<span style={{color:'red'}}>*</span></label>
                       <select className="form-control" name = "mode" value={this.state.mode} onChange={(e) => this.onChangeValue(e)}>
                         <option value = "0">Select Mode</option>
                         <option value = "Sandbox">Sandbox</option>
                         <option value = "Live">Live</option>
                       </select>
                       <div>
                       {this.state.modeerror ? <label style={{color:"red"}}>Payement Mode is required</label> : ""}
                       </div>
                       </div>
                    <div className="form-group">
                       <label class="acc-label mt-3">Api Key</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="apikey" placeholder="Enter apikey" pattern="[A-Za-z]"  value={this.state.apikey} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.apikeyerror ? <label style={{color:"red"}}>Apikey is Required</label> : ""}
                    </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label><span style={{color:"red"}}>*</span>
                     <select name = "status" className="form-control" value={this.state.status} onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1">Active</option>
                       <option value = "0">Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateForm}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    PayementgatewayReducer : state.PayementgatewayReducer
  };
 }
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddPayementgateway: AC_ADD_PAYEMENTGATEWAY},dispatch)
}
const AddPayementgatewayComponent = connect(mapStateToProps,mapDispatchToProps)(AddPayementgateway);

export default AddPayementgatewayComponent
