import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_LANGUAGE } from '../../actions/languageaction';


class AddLanguage extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    name        : '',
    code        : '',
    nameerror   : false,
    codeerror   : false,
    nameinvalid : false,
    status      : '1',
    statuserror : '',
  }
  this.validate = this.validate.bind(this);
 }
 // Validation
 validate() {
   var name        = this.state.name;
   var code        = this.state.code;
   const status    = this.state.status;
   if(name) {
     this.setState({nameerror : false})
   }
   else{
     this.setState({nameerror : true})
   }
   if(code) {
     this.setState({codeerror : false})
   }
   else{
     this.setState({codeerror : true})
   }
   name    =  name.charAt(0).toUpperCase() + name.slice(1);
   code    =  code.toUpperCase();
   if(name && code && status) {
     // Formdata to send Api
       this.setState({name:"",code:"",status:"1"});
       const languageFormData = {
                             languagename   : name,
                             languagecode   : code,
                             status         : status
                           }
       this.props.AddLanguage(languageFormData);
   }
 }
   // Onchange
   onChangeValue(event){
   const name  = event.target.name;
   const value = event.target.value;
   if(name ==='name'){
     if(value){
       this.setState({name:value,nameerror:false})
     } else {
      this.setState({name:value,nameerror:true})
     }
   }

     if(name ==='code'){
       if(value){
         this.setState({code:value,codeerror:false})
       } else {
          this.setState({code:value,codeerror:true})
       }
    }
     if(name ==='status'){
       if(value!==""){
         this.setState({status:value})
       }
       else{
         this.setState({status:value})
       }
     }
     this.setState({[name]:value});
   }
render() {
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Language</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Add Language</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add Language</h3>
                </div>
                <form>
                  <div className="card-body">
                     <div className="form-group">
                        <label>Language Name</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="name" value={this.state.name} placeholder="Enter Language Name" pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                        {this.state.nameerror ? <label style={{color:"red"}}>Language name is Required</label> : ""}
                        {this.state.nameinvalid ? <label style={{color:"red"}}>Language name is Invalid</label> : ""}
                     </div>
                     <div className="form-group">
                        <label>Language Code</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="code" value={this.state.code} placeholder="Enter Language Code" onChange ={(e) => this.onChangeValue(e)}/>
                        {this.state.codeerror ? <label style={{color:"red"}}>Language code is Required</label> : ""}
                     </div>
                     <div className="form-group">
                        <label>Status</label><span style={{color:"red"}}>*</span>
                        <select name = "status" className="form-control" value={this.state.status}  onChange ={(e) => this.onChangeValue(e)}>
                          <option value = "1">Active</option>
                          <option value = "0">Inactive</option>
                        </select>
                     </div>
                     <div className="card-footer">
                        <button type="button" className="btn btn-primary" onClick={this.validate}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
   )
 }
}

function mapStateToProps(state) {
  return {
    LanguageReducer : state.LanguageReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddLanguage: AC_ADD_LANGUAGE},dispatch)
}
const AddlanguagesComponent = connect(mapStateToProps,mapDispatchToProps)(AddLanguage);

export default AddlanguagesComponent;
