import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from "react-router-dom";
// import swal from 'sweetalert';
// import API from '../../common/api';
import { AC_ADD_LICENSE, AC_VIEW_OWNER, AC_VIEW_PARKING, AC_VIEW_CAR_DETAILS } from '../../actions/useraction';
import 'antd/dist/antd.css';
import './ViewUser.css'
import FileSaver from "file-saver";
import { AgGridReact } from 'ag-grid-react';
import MyDialog from './MyDialog';

class ViewOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      goBack: false,
      extend: false,
      decline: "",
      declineerror: false,
      visible: false,
      showDialog: false,
      redirect: '',
      currentData: {},
      columnDef: [
        {
          headerName: "Marque", field: "carmodel.make.brandname", width: '220',
          cellStyle: function (params) {
            return { 'fontSize': '16px', 'marginTop': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.carmodel.make.brandname == null) {
              return '<span class="badge badge-success">-</span>';
            } else {
              return `<span class="badge badge-success">${params.data.carmodel.make.brandname}</span>`;
            }
          }
        },
        {
          headerName: "Modèle", field: "carmodel.model.name", width: '220',
          cellStyle: function (params) {
            return { 'fontSize': '16px', 'marginTop': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.carmodel.model.name == null) {
              return '<span class="badge badge-warning">-</span>';
            } else {
              return `<span class="badge badge-success">${params.data.carmodel.model.name}</span>`;
            }
          }
        },
        {
          headerName: "Location", field: "tripsInfo.trips.length", width: '220',
          cellStyle: function (params) {
            return { 'fontSize': '16px', 'marginTop': '5px' };
          },
          // cellRenderer: function (params) {
          //   if (params.data.carmodel.model.name == null) {
          //     return '<span class="badge badge-warning">-</span>';
          //   } else {
          //     return `<span class="badge badge-success">${params.data.carmodel.model.name}</span>`;
          //   }
          // }
        },


        {
          headerName: "Type de véhicule", field: "carmodel.type.cartype", width: '150',
          cellStyle: function (params) {
            if (params.data.carmodel.type)
              return { 'fontSize': '16px', 'marginTop': '5px' };
            return { 'fontSize': '16px', 'marginTop': '5px', "color": "red" };
          },
          cellRenderer: function (params) {
            if (!params.data.carmodel.type) {
              return '<span class="badge badge-warning">-</span>';
            } else {
              return `<span class="badge badge-success">${params.data.carmodel.type.cartype}</span>`;
            }
          }
        },
        // {
        //   headerName: "Propriétaire", field: "userId.mobilenumber", width: '150',
        //   cellStyle: function (params) {
        //     return { 'fontSize': '16px', 'marginTop': '5px' };
        //   }
        // },
        {
          headerName: "Immatriculation", field: "number.number", width: '150',
          cellStyle: function (params) {
            return { 'fontSize': '16px', 'marginTop': '5px' };
          }
        },
        // {
        //   headerName: "Année d'immatriculation", field: "number.year", width: '150',
        //   cellStyle: function (params) {
        //     return { 'fontSize': '16px', 'marginTop': '5px' };
        //   }
        // },
        // {
        //   headerName: "Contact", field: "contactnumber.mobilenumber", width: '150',
        //   cellStyle: function (params) {
        //     return { 'fontSize': '16px', 'marginTop': '5px' };
        //   }
        // },
        // {
        //   headerName: "Country", field: "number.country", width: '150',
        //   cellStyle: function (params) {
        //     return { 'fontSize': '16px', 'marginTop': '5px' };
        //   }
        // },
        {
          headerName: "Status", field: "status", width: '200',
          cellStyle: function (params) {
            return { 'fontSize': '16px', 'marginTop': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.status === 1) {
              return '<span class="badge badge-success">Approved</span>';
            } else if (params.data.status === 2) {
              return '<span class="badge badge-success" style="background-color: #ffc107!important;color:black;" data-action-type="Status">Pending</span>';
            } else {
              return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Declined</span>';
            }
          }
        },
        // { headerName: 'Actions', width: "100",sortable: false,filter:false,
        //    template:
        //    `<div>
        //    <div class="btn btn-primary btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="Approval">
        //    Verify
        //    </div>
        //    </div>`,
        //    cellStyle: function(params) {
        //           return {'marginTop' :'8px'};
        //   },
        // },
        {
          headerName: 'Actions', width: "200", sortable: false, filter: false,
          template:
            `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           Voir
           </div>
           
           </div>`,
          cellStyle: function (params) {
            return { 'marginTop': '8px' };
          },
        },

      ],
    }
  }

  

  onRowClicked = event => {

    const rowValue = event.event.target;
    const carId = event.data._id;
    const value = rowValue.getAttribute('data-action-type');
    // console.log(event.data)
    const currentData = event.data;
    if (value === 'View') {

      this.setState({ redirect: 'View', id: carId, showDialog: true, currentData: currentData });
      // this.props.GetCarDetails(carId);
    }
    // if (value === 'Edit') {
    //   this.setState({ redirect: 'Edit', id: carId })
    // }
    // if (value === 'Delete') {
    //   this.deleteCar(event.data._id)
    // }
    // if(value=='Approval'){
    //   const data = {
    //     status : 1,
    //     id     : id
    //   }
    //   this.props.UpdateHostdetails(data)
    // }
  }
  componentDidMount() {

    const id = this.props.match.params.id;
    // Récupérer les informations sur le propriétaire
    this.props.ViewOwner(id);

    // Récupérer les informations sur les véhicules du propriétaire
    this.props.GetParking(id);
  }




  saveFile = (path, filename) => {
    console.log(path + ' ' + filename)
    FileSaver.saveAs(
      path,
      filename
    );
  }

  // accept() {
  //   // const declineerror = this.state.declineerror;
  //   const ownerInfo = this.props.UserReducer.ownerInfo;
  //   const data = {
  //     status: 1,
  //     id: ownerInfo._id,
  //     email: ownerInfo.email
  //   }
  //   swal({
  //     title: "Are you sure?",
  //     text: "The profile status will be set to verified!",
  //     buttons: true,
  //     dangerMode: false,
  //   })
  //     .then((willDelete) => {
  //       if (willDelete) {
  //         this.props.AddLicense(data);
  //       }
  //     });
  // }
  // decline() {
  //   this.setState({ extend: true })
  // }
  // validateform() {
  //   const decline = this.state.decline;
  //   // const declineerror = this.state.declineerror;
  //   const ownerInfo = this.props.UserReducer.ownerInfo;
  //   if (decline) {
  //     this.setState({ declineerror: false })
  //   } else {
  //     this.setState({ declineerror: true })
  //   }
  //   if (decline) {
  //     var data = {
  //       comments: decline,
  //       status: 0,
  //       id: ownerInfo?._id,
  //       email: ownerInfo?.email
  //     }
  //     swal({
  //       title: "Are you sure?",
  //       text: "The profile status will be set to not accepted!",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     })
  //       .then((willDelete) => {
  //         if (willDelete) {
  //           this.props.AddLicense(data);
  //           this.setState({ extend: false })
  //         }
  //       });
  //   }
  // }
  // onChangeValue(event) {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   if (name === 'decline') {
  //     if (value) {
  //       this.setState({ decline: value, declineerror: false })
  //     } else {
  //       this.setState({ decline: value, declineerror: true })
  //     }
  //   }
  // }
  // this.handleHideDialog = this.handleHideDialog.bind(this);
  // handleHideDialog = () => {
  //   // this.setState({ showDialog: true });
  //   console.log("Fermer");
  // }
  handleShowDialog = () => {
    this.setState({ showDialog: true });
  }

  handleHideDialog = () => {
    this.setState({ showDialog: false });
  }

  render() {

    if(this.state.goBack){
      return <Redirect push to={'/OwnerList'} />
    }
    // console.log(this.props.UserReducer.ownerInfo)
    const ownerInfo = this.props.UserReducer.ownerInfo;
    const parking = this.props.UserReducer.parking;
    const carCount = this.props.UserReducer.carCount;

    const firstname = ownerInfo?.firstname;
    const lastname = ownerInfo?.lastname;
    // const gender = ownerInfo?.gender;
    // const age = ownerInfo?.age;
    const country = ownerInfo?.country;
    const email = ownerInfo?.email;
    // const mobile = ownerInfo?.mobile;
    // const status = ownerInfo?.status;
    // const nomProprietaire = ownerInfo.ownercard;
    const iban = ownerInfo?.iban;
    const mobilemoney = ownerInfo?.mobilemoney;
    // const LIVEURL = API.LIVEURL;
    // const IMAGEURL = API.IMAGEURL
    // var licenseimage = "/noimage.jpg";

    // let imageList = []
    // let licenseimageRecto = ''
    // let licenseimageVerso = ''
    // let idimageRecto = ''
    // let idimageVerso = ''
    // let billingimage = ''
    // let selfie = ''

    // if(this.state.redirect === 'View' ){
    //   return (<MyDialog show={this.state.showDialog} onHide={this.handleHideDialog} />)
    // }

    return (
      <div className="content-wrapper">
        <MyDialog show={this.state.showDialog}
          onHide={this.handleHideDialog}
          data={this.state.currentData}
        />
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Information Propriétaire</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item" onClick={() => this.setState({goBack: true})} style={{cursor: 'pointer'}}>Liste propriétaire</li>
                  <li className="breadcrumb-item active">Information Propriétaire</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title text-white">Information propriétaire</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="firstname">Nom</label>
                          <input type="text" name="firstname" id="firstname" className="form-control" disabled defaultValue={firstname} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="lastname">Prénom</label>
                          <input type="text" name="lastname" id="lastname" className="form-control" disabled defaultValue={lastname} />
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="lastname">Adresse email</label>
                          <input type="text" name="email" id="email" className="form-control" disabled defaultValue={email} />
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="gender">Genre</label>
                          <input type="text" name="gender" id="gender" className="form-control" disabled value={gender} />
                        </div>
                      </div> */}

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="mobile">Iban</label>
                          <input type="text" name="mobile" id="mobile" className="form-control" disabled defaultValue={iban} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="mobile">Mobilemoney</label>
                          <input type="text" name="mobile" id="mobile" className="form-control" disabled defaultValue={mobilemoney} />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="country">Pays</label>
                          <input type="text" name="country" id="country" className="form-control" disabled defaultValue={country} />
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title text-white">Park Automobile - {carCount} Véhicules</h3>
                  </div>
                  <div className="card-body">
                    
                    <div
                      className="ag-theme-balham"
                      style={{ width: '100%' }}>
                      <AgGridReact
                        alwaysShowVerticalScroll
                        rowHeight={50}
                        enableCharts
                        gridAutoHeight
                        rowClass={'centerAlign'}
                        onSortChanged={this.onSortChanged}
                        rowSelection={'single'}
                        onRowClicked={(e) => this.onRowClicked(e)}
                        enableSorting={false}
                        suppressCellSelection={true}
                        enableFilter={false}
                        enableColResize={true}
                        paginationAutoPageSize={true}
                        columnDefs={this.state.columnDef}
                        defaultColDef={{ filter: false, floatingFilter: false }}
                        rowData={parking}>
                      </AgGridReact>
                    </div >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    UserReducer: state.UserReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ GetParking: AC_VIEW_PARKING, GetCarDetails: AC_VIEW_CAR_DETAILS, ViewOwner: AC_VIEW_OWNER, AddLicense: AC_ADD_LICENSE }, dispatch)
}
const ViewOwnerComponent = connect(mapStateToProps, mapDispatchToProps)(ViewOwner);

export default ViewOwnerComponent;
