import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_PAGES } from '../../actions/pagesaction';

class AddPages extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      name                : '',
      slug                : '',
      description         : '',
      nameError           : false,
      descriptionError    : false
    }
    this.validateform = this.validateform.bind(this);
  }

    componentDidMount() {
      window.$(function () {
        window.$('#compose-textarea').summernote({ height: 200,focus: true });
      })
    }

    //validation
    validateform(){
      var name             =  this.state.name;
      const description    =  window.$('#compose-textarea').summernote('code');
      var checkdescription = 0;
      if(name) {
        this.setState({nameError:false});
      } else {
        this.setState({nameError:true});
      }
      if(description!=="<p><br></p>") {
        this.setState({descriptionError:false});
        checkdescription=1;
      } else {
        this.setState({descriptionError:true});
      }
      var slug = this.state.name;
      slug     = slug.replace(/[^\w\-]+/g, "-");
      slug     = slug.toLowerCase();
      name     =  name.charAt(0).toUpperCase() + name.slice(1);
      if(name&&checkdescription ) {
        this.setState({ name:"",subject:""});
        window.$('#compose-textarea').summernote('reset');
        const pagesFormData = {
                      name          : name,
                      slug          : slug,
                      description   : description,
                      status        : 1
                    }
        this.props.Addpages(pagesFormData);
      }
  }
    //onchange values
    onChangeValue(event){
      const name          =  event.target.name;
      const value         =  event.target.value;

      if(name ==='name'){
        if(value){
          this.setState({name:value,nameError:false})
        } else {
          this.setState({name:value,nameError:true})
        }
      }
    }

  render() {
    var slug = this.state.name;
    slug = slug.replace(/[^\w\-]+/g, "-");
    slug = slug.toLowerCase();
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Pages</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Pages</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Page Name<span style={{color:"red"}}>*</span></label>
                        <input type="name" name="name" className="form-control" placeholder="Enter Page Name"  value={this.state.name}  onChange={(e)=>this.onChangeValue(e)}/>
                        {this.state.nameError?<label style={{color:"red"}}>Pages Name is Required</label>:""}
                          </div>
                          <div className="form-group">
                             <label>Slug</label><span style={{color:"red"}}>*</span>
                             <input type="text" className="form-control"  placeholder="Enter Slug" pattern="[A-Za-z]"  value={slug} />
                          </div>
                          <label htmlFor="exampleInputPassword1">Description<span style={{color:"red"}}>*</span></label><br/>
                            <div className="form-group">
                               <textarea type="textarea" id="compose-textarea" name="description" className="form-control" value={this.state.description}  onChange={(e)=>this.onChangeValue(e)} style={{height: "200px"}}/>
                               {this.state.descriptionError?<label style={{color:"red"}}>Description is Required</label>:""}
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary"  onClick={this.validateform} >Submit</button>
                          </div>
                      </div>
                   </div>
                 </div>
               </div>
             </section>
           </div>
      )
   }
}

function mapStateToProps(state) {
  return {
    PagesReducer : state.PagesReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({Addpages: AC_ADD_PAGES},dispatch)
}
const AddPagesComponents = connect(mapStateToProps,mapDispatchToProps)(AddPages);

export default AddPagesComponents;
