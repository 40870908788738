import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_CURRENCY } from '../../actions/currencyaction';

class AddCurrency extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    currencyname      : '',
    currencynameerror : false,
    status            : '1',
    symbol            : '',
    symbolerror       : false,
  }
  this.validate=this.validate.bind(this);
 }
 // validation
 validate() {
    var currencyname   = this.state.currencyname;
    const symbol       = this.state.symbol;
    const status       = this.state.status;
    if(currencyname) {
       this.setState({currencynameerror : false})
    } else {
       this.setState({currencynameerror : true})
    }
    if(symbol) {
       this.setState({symbolerror : false})
    } else {
       this.setState({symbolerror : true})
    }
    currencyname = currencyname.toUpperCase()
    if(currencyname && symbol) {
         this.setState({ currencyname:"",symbol:"",status:"1"});
         const currencyFormData = {
                               currencyname : currencyname,
                               symbol       : symbol,
                               status       : status
                             }
         this.props.AddCurrency(currencyFormData);
   }
 }
 // Onchange events
 onChangeValue(event) {
   const name     = event.target.name;
   const value    = event.target.value;
   if(name ==='currencyname') {
     if(value){
       this.setState({currencyname:value,currencynameerror:false})
     } else {
       this.setState({currencyname:value,currencynameerror:true})
     }
   }
   if(name ==='status') {
     if(value!=="") {
       this.setState({status:value})
     } else {
       this.setState({status:value})
     }
   }
   // Symbol
   if(name ==='symbol') {
     if(value) {
       this.setState({symbol:value,symbolerror:false})
     } else {
       this.setState({symbol:value,symbolerror:false})
     }
   }
 }
render() {
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Add Currency</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Add Currency</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add Currency</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Currency Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="currencyname" value={this.state.currencyname} placeholder="Enter Currency Name" pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.currencynameerror ? <label style={{color:"red"}}>Currency Name is Required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Symbol</label><span style={{color:"red"}}>*</span>
                    <div className="form-group">
                       <input type="text" className="form-control" name="symbol" value={this.state.symbol} placeholder="Enter Currency Symbol" pattern="[A-Za-z]" onChange ={(e) => this.onChangeValue(e)}/>
                    </div>
                        {this.state.symbolerror? <label style={{color:'red'}}>Currency Symbol is Required</label>:""}
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                     <select name = "status" className="form-control" value={this.state.status} onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1">Active</option>
                       <option value = "0">Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                       <button type="button" className="btn btn-primary" onClick={this.validate}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CurrencyReducer : state.CurrencyReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddCurrency: AC_ADD_CURRENCY},dispatch)
}
const AddCurrencyComponent = connect(mapStateToProps,mapDispatchToProps)(AddCurrency);

export default AddCurrencyComponent;
