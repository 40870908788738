import axios from 'axios';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Error } from '../common/swal';

// Action types for admin settings management
const GET_ADMIN_SETTINGS        = 'GET_ADMIN_SETTINGS';
const HANDLE_INPUT_CHANGE       = 'HANDLE_INPUT_CHANGE';
const UPDATE_ADMIN_SETTINGS     = 'UPDATE_ADMIN_SETTINGS';
const CHANGE_PASSWORD           = 'CHANGE_PASSWORD';
const AUTHORIZATION             = "AUTHORIZATION";

const redirect = redirectUrl => {
  window.location = redirectUrl;
};
const accessToken = localStorage.getItem("token");
const authAxios = axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})
export function AC_GET_ADMIN_SETTINGS() {
  return function(dispatch) {
    return authAxios.get(URL.API.GetAdminSettings,{})
      .then(response => {
        if(response.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
      	dispatch({type: GET_ADMIN_SETTINGS, payload:response.data.data});
      })
      .catch(error => {
          Error('Network error');
      });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value,module) {
  return function(dispatch) {
    dispatch({type: HANDLE_INPUT_CHANGE,name:name,value:value,module:module})
  };
}

export function AC_UPDATE_ADMIN_SETTINGS(formData,id,module) {
  return function(dispatch) {
    return axios.post(
      URL.API.UpdateAdminSettings,
      formData,
      { headers : { authorization : id, module:module, } },
      )
      .then(({ data }) => {
        Success(data.message)
        dispatch({type: UPDATE_ADMIN_SETTINGS, payload:data.data, module:module});
    });
  };
}

export function AC_CHANGE_PASSWORD(formData,id) {
  return function(dispatch) {
    return axios.post(
      URL.API.ChangePassword,
      formData,
      { headers : { authorization : id } },
      )
      .then(({ data }) => {
        if(data.status === 0) {
          Error(data.message)
        } else {
          Success(data.message);
          dispatch({type: CHANGE_PASSWORD, payload:data.data});
        }
    });
  };
}

export function AC_AUTHORIZATION(formData) {
  return function(dispatch) {
    return axios.post(
      URL.API.Authorization,
      formData,
      )
      .then(({ data }) => {
        console.log(URL.API.Authorization)
        if(!data.status){
          Error(data.message);
        }
        else{
          localStorage.setItem("token",data.token);
          dispatch({type: AUTHORIZATION, payload:data});
        }
    });
  };
}

export function AC_RECOVER_PASSWORD(formData,id) {
  return function(dispatch) {
    return axios.post(
      URL.API.RecoverPassword,
      formData,
      { headers : { authorization : id } },
      )
      .then(({ data }) => {
        if(data.status === 0) {
          Error(data.message);
        } else {
          Success(data.message);
          return redirect('/');
        }
    });
  };
}

export function AC_FORGOT_PASSWORD(formData) {
  return function(dispatch) {
    return axios.post(
      URL.API.ForgotPassword,
      formData,
    )
      .then(({ data }) => {
        if(data.status){
          swal("Done",data.message,"success");
        }
        else {
          swal("Oops",data.message,"error");
        }
    });
  };
}
