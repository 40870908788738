import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect } from "react-router-dom";
import { AC_TRIPS_LIST } from '../../actions/tripaction';

// function withDriverRender(params) {
//   return (
//       <span className="my-renderer">
//           {params.value != null &&
//               <React.Fragment>                  
//                   <span class="my-renderer-value">
//                       {params.value === true ? "Oui" :  "Non"}
//                   </span>
//               </React.Fragment>
//           }
//       </span>
//   );
// }

class NewTrips extends React.Component {

  constructor(props) {
    super(props);
  
    this.state = {
      tripStatus: {
        paid: "Payée", // 2
        accepted: 'Accepté', // 3
        pending: 'En attente', // 1
        rejected: 'Refusée', // 0
        usercanceled: 'Annulée' //4
      },
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '40',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          }
        },
        // {
        //   headerName: "Email propriétaire", field: "ownerId.email", width: '200',
        //   cellStyle: function (params) {
        //     return { 'font-size': '12px', 'margin-top': '5px', 'text-transform': 'lowercase', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
        //   }
        // },
        {
          headerName: "Nom du propriétaire", field: "ownerId.lastname", width: '200',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'text-transform': 'lowercase', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          }
        },
        {
          headerName: "Prénom du propriétaire", field: "ownerId.firstname", width: '200',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'text-transform': 'lowercase', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          }
        },
        // {
        //   headerName: "Email client", field: "customerId.email", width: '200',
        //   cellStyle: function (params) {
        //     return { 'font-size': '12px', 'margin-top': '5px', 'text-transform': 'lowercase', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
        //   }
        // },
        {
          headerName: "Nom du client", field: "customerId.lastname", width: '200',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'text-transform': 'lowercase', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          }
        },
        {
          headerName: "Prénom du client", field: "customerId.firstname", width: '200',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'text-transform': 'lowercase', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          }
        },

        {
          headerName: "Date de début", field: "checkin", width: '200',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          }
        },
        {
          headerName: "Date de fin", field: "checkout", width: '200',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };

          },

        },
        {
          headerName: "Statut", field: "tripStatus", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.tripStatus === 3) {
              return `<span class="badge badge-success">Acceptée</span>`;              
            } 
            
            else if(params.data.tripStatus === 2){
              return `<span class="badge badge-success">Payé</span>`;
            }
            else if(params.data.tripStatus === 1){
              return `<span class="badge badge-primary">En Attente</span>`;
            }
            else if(params.data.tripStatus === 0){
              return `<span class="badge badge-danger">Refusée</span>`;
            }
            else if(params.data.tripStatus === 4){
              return `<span class="badge badge-danger">Annulée</span>`;
            }
            // else {
            //   return '<span class="badge badge-success" style="background-color: #ffc107!important;color:black;" data-action-type="Status">Pending</span>';
            // }
          }
        },{
          headerName: "Enregistré depuis", field: "registrationPlatform", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px' };
          },
          cellRenderer: function (params) {
            if (params.data.registrationPlatform === 'mobile') {
              return `<span class="badge badge-success">${params.data.registrationPlatform}</span>`;
            } 
            else {
              return `<span class="badge badge-primary" >${params.data.registrationPlatform}</span>`;
            }
          }
        },

        //  { headerName: "Avec chauffeur", field: "checkout", width: '200',
        //   cellStyle: function(params) {
        //        return {'font-size': '12px','margin-top': '5px'};
        //  }},
        {
          headerName: "Avec chauffeur", field: "withDriver", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          },
          valueGetter: function (params) {
            return params.data.withDriver ? 'Oui' : 'Non'
          }
        },
        {
          headerName: "Hors de la ville", field: "goOut", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          },
          valueGetter: function (params) {
            return params.data.goOut === true ? 'Oui' : 'Non'
          }

        },
        {
          headerName: "Avec livraison", field: "withDelivery", width: '100',
          cellStyle: function (params) {
            return { 'font-size': '12px', 'margin-top': '5px', 'font-weight': '600', 'color': "#1E1E1E", 'opacity': .9 };
          },
          valueGetter: function (params) {
            return params.data.withDelivery ? 'Oui' : 'Non'
          }
        },
        {
          width: "90", sortable: false, filter: false, headerName: "Actions",
          template:
            `<div>
              <div class="btn btn-info btn-xs" href='#' style={{marginRight:"10px", font-size: "12px"}} data-action-type="View">
                <i class="fas fa-folder"  style={{padding: "10px", font-size: "12px"}} data-action-type="View"></i>
                Voir
              </div>
            </div>`,
          cellStyle: function (params) {
            return { 'margin-top': '8px' };
          },
        },
      ],
    }
  }

  componentDidMount() {
    var tripStatus = { tripStatus: 1 };
    this.props.TripsList(tripStatus);

  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event => {
    const rowValue = event.event.target;
    const value = rowValue.getAttribute('data-action-type');
    if (value === 'View') {
      this.setState({ redirect: 'View', id: event.data._id })
    }
  }
  render() {
    const tripList = this.props.TripReducer.tripList;
    console.log(tripList[0])
    // const tableTripList = [];
    if (this.props.TripReducer.tripList) {
      // const tripList      = this.props.TripReducer.tripList;
      if (this.state.redirect === 'View') {
        return <Redirect to={'/ViewTrip/' + this.state.id} />
      }
    }

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Liste des demandes </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Nouvelles Locations</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Locations en cours</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
              <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  alwaysShowHorizontalScroll={true}
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e) => this.onRowClicked(e)}
                  suppressCellSelection={true}
                  enableSorting
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  defaultColDef={{ filter: true, floatingFilter: true }}
                  rowData={tripList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    TripReducer: state.TripReducer
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ TripsList: AC_TRIPS_LIST }, dispatch)
}

const NewTripsComponent = connect(mapStateToProps, mapDispatchToProps)(NewTrips);

export default NewTripsComponent;
