import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE,AC_UPDATE_ADMIN_SETTINGS } from '../../actions/settings';

class SocialMedia extends React.Component {
  constructor(props) {
      super(props);
      this.validateform = this.validateform.bind(this);
  }
  validateform() {
      const socialMediaInfo    = this.props.SettingsReducer.socialMediaInfo;
      const id                 = this.props.SettingsReducer.settingId;
      this.props.updateAdminSettings(socialMediaInfo,id,'Social media');
  }
  onChangeValue(event)  {
     const name   = event.target.name;
     const value  = event.target.value;
     this.props.handleInputChange(name,value,'Social media')
  }
render() {
  const socialMediaInfo  = this.props.SettingsReducer.socialMediaInfo;
  var facebooklink       = "";
  var twitterlink        = "";
  var googlepluslink     = "";
  var facebookappid      = "";
  var facebookappsecret  = "";
  var googleclientid     = "";
  var googleredirecturl  = "";
  var googlesecretkey    = "";
  var googledeveloperkey = "";
  var facebookapikey     = "";
  var googlemapsapikey   = "";
  if(socialMediaInfo) {
     facebooklink       = socialMediaInfo.facebooklink;
     twitterlink        = socialMediaInfo.twitterlink;
     googlepluslink     = socialMediaInfo.googlepluslink;
     facebookappid      = socialMediaInfo.facebookappid;
     facebookappsecret  = socialMediaInfo.facebookappsecret;
     googleclientid     = socialMediaInfo.googleclientid;
     googleredirecturl  = socialMediaInfo.googleredirecturl;
     googlesecretkey    = socialMediaInfo.googlesecretkey;
     googledeveloperkey = socialMediaInfo.googledeveloperkey;
     facebookapikey     = socialMediaInfo.facebookapikey;
     googlemapsapikey   = socialMediaInfo.googlemapsapikey;
  }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Social Media Settings</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Social Media Settings</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Social Share Links</h3>
                  </div>
                 
                    <div className="card-body">
                      <div className="form-group">
                        <label >Facebook Link</label>
                        <input type="text" className="form-control" id="facebooklink" name="facebooklink" placeholder="Facebook Link" value={facebooklink} onChange={(e) =>this.onChangeValue(e)}/>
                      </div>
                      <div className="form-group">
                        <label >Twitter Link</label>
                        <input type="text" className="form-control" id="twitterlink" name="twitterlink" placeholder="Twitter Link" value={twitterlink} onChange={(e) =>this.onChangeValue(e)}/>
                      </div>
                      <div className="form-group">
                        <label >Google Plus Link</label>
                        <input type="text" className="form-control" id="googlepluslink" name="googlepluslink" placeholder="Google Plus Link" value={googlepluslink} onChange={(e) =>this.onChangeValue(e)}/>
                      </div>
                    </div>
                  
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h1 className="card-title">Facebook App Login Credential</h1>
                  </div>
                  
                    <div className="card-body">
                      <div className="form-group">
                          <label >Facebook App Id</label>
                          <input type="text" className="form-control" id="facebookappid" name="facebookappid" placeholder="Facebook App Id" value={facebookappid} onChange={(e) =>this.onChangeValue(e)}/>
                        </div>
                        <div className="form-group">
                          <label >Facebook App Secret</label>
                          <input type="text" className="form-control" id="facebookappsecret" name="facebookappsecret"placeholder="Facebook App Secret" value={facebookappsecret} onChange={(e) =>this.onChangeValue(e)}/>
                        </div>
                      </div>
                    
                  </div>
                </div>
            </div>
          </div>
        </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card card-primary">
                      <div className="card-header">
                          <h3 className="card-title">Google Plus App Login Credential</h3>
                      </div>
                      
                          <div className="card-body">
                              <div className="form-group">
                                  <label >Google Client Id</label>
                                  <input type="text" className="form-control" id="googleclientid" name="googleclientid" placeholder="Google Client Id" value={googleclientid} onChange={(e) =>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                  <label >Google Redirect Url</label>
                                  <input type="text" className="form-control" id="googleredirecturl" name="googleredirecturl" placeholder="Google Redirect Url" value={googleredirecturl} onChange={(e) =>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                  <label >Google Secret Key</label>
                                  <input type="text" className="form-control" id="googlesecretkey" name="googlesecretkey" placeholder="Google Secret Key" value={googlesecretkey} onChange={(e) =>this.onChangeValue(e)}/>
                              </div>
                              <div className="form-group">
                                  <label >Google Developer Key</label>
                                  <input type="text" className="form-control" id="googledeveloperkey" name="googledeveloperkey" placeholder="Google Developer Key" value={googledeveloperkey} onChange={(e) =>this.onChangeValue(e)} />
                                </div>
                              </div>
                           
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">API Key Settings</h3>
                            </div>
                          
                              <div className="card-body">
                                <div className="form-group">
                                    <label >Facebook API Key</label>
                                    <input type="text" className="form-control" id="facebookapikey" name="facebookapikey" placeholder="Facebook API Key" value={facebookapikey} onChange={(e) =>this.onChangeValue(e)}/>
                                  </div>
                                  <div className="form-group">
                                      <label >Google Maps API Key</label>
                                      <input type="text" className="form-control" id="googlemapsapikey" name="googlemapsapikey" placeholder="Google Maps API Key" value={googlemapsapikey} onChange={(e) =>this.onChangeValue(e)}/>
                                  </div>
                                </div>
                             
                            </div>
                         </div>
                      </div>
                    </div>
                </section>
              <div className="card-footer">
                  <button type="submit" className="btn btn-primary" onClick={this.validateform}>Submit</button>
              </div>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({handleInputChange:AC_HANDLE_INPUT_CHANGE,updateAdminSettings:AC_UPDATE_ADMIN_SETTINGS},dispatch)
}
const SocialMediaComponent = connect(mapStateToProps,mapDispatchToProps)(SocialMedia);

export default SocialMediaComponent;
