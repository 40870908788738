import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_NEWSLETTER } from '../../actions/newsletteraction';

class AddNewsletter extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      name                : '',
      subject             : '',
      description         : '',
      nameError           : false,
      subjectError        : false,
      descriptionError    : false
    }
    this.validateform = this.validateform.bind(this);
  }

    componentDidMount() {
      window.$(function () {
        window.$('#compose-textarea').summernote({ height: 200,focus: true });
      })
    }

    //validation
    validateform(){
      var name             =  this.state.name;
      var subject          =  this.state.subject;
      const description    =  window.$('#compose-textarea').summernote('code');
      var checkdescription = 0;
      if(name) {
        this.setState({nameError:false});
      } else {
        this.setState({nameError:true});
      }
      if(subject) {
        this.setState({subjectError:false});
      } else {
        this.setState({subjectError:true});
      }
      if(description!=="<p><br></p>") {
        this.setState({descriptionError:false});
        checkdescription=1;
      } else {
        this.setState({descriptionError:true});
      }
      name           =  name.charAt(0).toUpperCase() + name.slice(1);
      subject        =  subject.charAt(0).toUpperCase() + subject.slice(1);
      if(name && subject&&checkdescription ) {
        this.setState({ name:"",subject:""});
        window.$('#compose-textarea').summernote('reset');
        const newsLetterFormData = {
                      templatename   : name,
                      emailsubject   : subject,
                      emailcontent   : description,
                      status         : 1
                    }
        this.props.AddNewsletter(newsLetterFormData);
      }
  }
    //onchange values
    onChangeValue(event){
      const name          =  event.target.name;
      const value         =  event.target.value;

      if(name ==='name'){
        if(value){
          this.setState({name:value,nameError:false})
        } else {
          this.setState({name:value,nameError:true})
        }
      }
      if(name ==='subject'){
        if(value){
          this.setState({subject:value,subjectError:false})
        } else {
          this.setState({subject:value,subjectError:true})
        }
      }
    }

  render() {
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>News Letter</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">News Letter</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">

                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Template Name<span style={{color:"red"}}>*</span></label>
                        <input type="name" name="name" className="form-control" placeholder="Enter email"  value={this.state.name}  onChange={(e)=>this.onChangeValue(e)}/>
                        {this.state.nameError?<label style={{color:"red"}}>Name is Required</label>:""}
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Email Subject<span style={{color:"red"}}>*</span></label>
                            <input type="email" name="subject" className="form-control" id="exampleInputPassword1" placeholder="Email Subject"  value={this.state.subject}  onChange={(e)=>this.onChangeValue(e)}/>
                            {this.state.subjectError?<label style={{color:"red"}}>Email Subject is Required</label>:""}
                          </div>
                            <label htmlFor="exampleInputPassword1">Email Content<span style={{color:"red"}}>*</span></label><br/>
                            <div className="form-group">
                               <textarea type="textarea" id="compose-textarea" name="description" className="form-control" value={this.state.description}  onChange={(e)=>this.onChangeValue(e)} style={{height: "200px"}}/>
                               {this.state.descriptionError?<label style={{color:"red"}}>Email Content is Required</label>:""}
                            </div>
                          </div>
                          <div className="card-footer">
                            <button type="button" className="btn btn-primary"  onClick={this.validateform} >Submit</button>
                          </div>

                      </div>
                   </div>
                 </div>
               </div>
             </section>
           </div>
      )
   }
}

function mapStateToProps(state) {
  return {
    NewsletterReducer : state.NewsletterReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddNewsletter: AC_ADD_NEWSLETTER},dispatch)
}
const AddNewsletterComponents = connect(mapStateToProps,mapDispatchToProps)(AddNewsletter);

export default AddNewsletterComponents;
