import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_CONFIGURATION } from '../../actions/configurationaction';

class ViewConfiguration extends React.Component {

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewConfiguration(id);
  }

  render() {
      const configurationInfo  = this.props.ConfigurationReducer.configurationInfo;

      const name               = configurationInfo.name;
      const slug               = configurationInfo.slug;
      const description        = configurationInfo.description;

      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>View Configuration</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">View Configuration</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View Configuration</h3>
                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label >Configuration Name</label>
                      <input type="text" id="inputName" className="form-control"  name="name" value={name} disabled />
                    </div>
                    <div className="form-group">
                      <label >Slug</label>
                      <textarea id="inputDescription" className="form-control" rows="4" name="slug" value={slug} disabled></textarea>
                    </div>
                    <div className="form-group">
                      <label >Description</label>
                      <textarea id="inputDescription" className="form-control" rows="4" name="description" value={description} disabled></textarea>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    ConfigurationReducer : state.ConfigurationReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewConfiguration: AC_VIEW_CONFIGURATION},dispatch)
}
const ViewConfigurationComponent = connect(mapStateToProps,mapDispatchToProps)(ViewConfiguration);

export default ViewConfigurationComponent;
