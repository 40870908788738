import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_LIST_FAQ } from '../../actions/faqaction';
import { AC_LIST_CARTYPE } from '../../actions/cartypeaction';
import { AC_LIST_COUNTRY } from '../../actions/countryaction';
import { AC_LIST_LANGUAGE } from '../../actions/languageaction';
import { useEffect } from 'react';
// import MonthlyStatsChart from '../charts/MonthlyStatsChart';


const Dashboard = ({
  listCartype,
  ListFaq,
  ListCountry,
  ListLanguage,
  FaqReducer,
  CarTypeReducer,
  CountryReducer,
  LanguageReducer,
}) => {

  useEffect(() => {
    ListFaq();
    listCartype();
    ListCountry();
    ListLanguage();
  }, [ListFaq, listCartype, ListCountry, ListLanguage]);


  const faqCount = FaqReducer.faqCount;
  const carTypesCount = CarTypeReducer.cartypeListCount;
  const countryCount = CountryReducer.countryCount;
  const languageCount = LanguageReducer.languageCount;

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Dashboard v1</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  {faqCount ? <h3>{faqCount}</h3> : <h3>0</h3>}
                  <p>FAQ</p>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"></i>
                </div>
                <Link to="/listFaq" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  {carTypesCount ? <h3>{carTypesCount}</h3> : <h3>0</h3>}
                  <p>Car Types</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"></i>
                </div>
                <Link to='/listCarType' className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  {countryCount ? <h3>{countryCount}</h3> : <h3>0</h3>}
                  <p>Countries</p>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"></i>
                </div>
                <Link to="/listCountry" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  {languageCount ? <h3>{languageCount}</h3> : <h3>0</h3>}
                  <p>Languages</p>
                </div>
                <div className="icon">
                  <i className="ion ion-pie-graph"></i>
                </div>
                <Link to="/listLanguage" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* <MonthlyStatsChart chartData={{}} /> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

const mapStateToProps = (state) => ({
  SettingsReducer: state.SettingsReducer,
  FaqReducer: state.FaqReducer,
  CarTypeReducer: state.CarTypeReducer,
  CountryReducer: state.CountryReducer,
  LanguageReducer: state.LanguageReducer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listCartype: AC_LIST_CARTYPE,
      ListFaq: AC_LIST_FAQ,
      ListCountry: AC_LIST_COUNTRY,
      ListLanguage: AC_LIST_LANGUAGE,
    },
    dispatch
  );

const DashboardComponent = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardComponent;
