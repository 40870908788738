import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_COUNTRY,AC_EDIT_COUNTRY,AC_HANDLE_INPUT_CHANGE} from '../../actions/countryaction';
class EditCountry extends React.Component {
  constructor(props) {
     super(props);
     this.state = {
                     countrynameError     : false,
                     countrycodeError     : false,
                     statuserror          : false,
                   }
      this.validateform=this.validateform.bind(this);
   }
       componentWillMount() {
       const id = this.props.match.params.id;
       this.props.ViewCountry(id);
   }
   //validtion
  validateform(){
    var country_name       = this.props.CountryReducer.countryInfo.countryname;
    var country_code       = this.props.CountryReducer.countryInfo.countrycode;
    const status           = this.props.CountryReducer.countryInfo.status;
    const id               = this.props.CountryReducer.countryInfo.id;
    if(country_name)
    {
        this.setState({countrynameError:false})
    }
    else{
        this.setState({countrynameError:true})
    }
    if(country_code)
    {
        this.setState({countrycodeError:false})
    }
    else{
        this.setState({countrycodeError:true})
    }
    country_name  =  country_name.charAt(0).toUpperCase() + country_name.slice(1);
    country_code  =  country_code.toUpperCase();
    if(country_name && country_code ) {
      // Formdata to send Api
      const countryFormData = {
                            countryname   : country_name,
                            countrycode   : country_code,
                            status        : status,
                            id            : id
                          }
      this.props.EditCountry(countryFormData);
      this.props.ViewCountry(id);
    }
  }
  //onchange values
  onChangeValue(event){
    const name        =  event.target.name;
    const value       =  event.target.value;
    this.props.HandleInputChange(name,value)
   }
   render() {

     const countryInfo   = this.props.CountryReducer.countryInfo;
     const countryname   = countryInfo.countryname;
     const countrycode   = countryInfo.countrycode;
     const status        = countryInfo.status;

     return(
        <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit Country</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Edit Country</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Edit Country</h3>
                  </div>
                  
                    <div className="card-body">
                      <div className="form-group">
                        <label >Country Name</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="country_name" id="inputname" placeholder="Country Name" value={countryname} onChange={(e)=> this.onChangeValue(e)}/>
                        {this.state.countrynameError?<label style={{color:'red'}}>Country name is Required</label>:""}
                      </div>
                      <div className="form-group">
                        <label>Country Code</label><span style={{color:"red"}}>*</span>
                        <input type="text" className="form-control" name="country_code" id="inputcode" placeholder="Country Code" value={countrycode} onChange={(e)=> this.onChangeValue(e)}/>
                        {this.state.countrycodeError?<label style={{color:'red'}}>Country code is Required</label>:""}
                      </div>
                      <div className="form-group">
                         <label>Status</label><span style={{color:"red"}}>*</span>
                         <select name = "status" className="form-control" onChange ={(e) => this.onChangeValue(e)}>
                         <option value="1" selected={status === 1}>Active</option>
                         <option value="0" selected={status === 0}>Inactive</option>
                         </select>
                         {this.state.statuserror ? <label style={{color:"red"}}>Status is Required</label> : ""}
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="button" className="btn btn-primary" onClick={this.validateform}>Update</button>
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CountryReducer : state.CountryReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCountry: AC_VIEW_COUNTRY,EditCountry:AC_EDIT_COUNTRY,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditCountryComponent = connect(mapStateToProps,mapDispatchToProps)(EditCountry);

export default EditCountryComponent;
