import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';

// Action types for Cartype management
const LIST_CARTYPE         = 'LIST_CARTYPE';
const ADD_CARTYPE          = 'ADD_CARTYPE';
const EDIT_CARTYPE         = 'EDIT_CARTYPE';
const VIEW_CARTYPE         = 'VIEW_CARTYPE';
const UPDATE_CARTYPE_DATA  = 'UPDATE_CARTYPE_DATA';
const DELETE_CARTYPE       = 'DELETE_CARTYPE';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_UPDATE_CARTYPESTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateCarTypeStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: DELETE_CARTYPE, payload:data});
       }
    });
  };
}
export function AC_LIST_CARTYPE() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListCarType,{})
      .then(({ data }) => {
        swal.close();
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_CARTYPE, payload:data});
    });
  };
}

export function AC_DELETE_CARTYPE(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteCarType,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_CARTYPE, payload:data});
      }
    });
  };
}

export function AC_ADD_CARTYPE(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddCarType,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_CARTYPE, payload:data});
      }
    });
  };
}
export function AC_VIEW_CARTYPE(formData) {
  const id = { id : formData }
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewCarType,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_CARTYPE, payload:data});
    });
  };
}
export function AC_EDIT_CARTYPE(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditCarType,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_CARTYPE, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_CARTYPE_DATA, name:name,value:value})
  };
}
