import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';
// Action types for Language management
const LIST_LANGUAGE        = 'LIST_LANGUAGE';
const ADD_LANGUAGE         = 'ADD_LANGUAGE';
const EDIT_LANGUAGE        = 'EDIT_LANGUAGE';
const VIEW_LANGUAGE        = 'VIEW_LANGUAGE';
const UPDATE_LANGUAGE_DATA = 'UPDATE_LANGUAGE_DATA';
const DELETE_LANGUAGE      = 'DELETE_LANGUAGE';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_UPDATE_LANGUAGESTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateLanguageStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: DELETE_LANGUAGE, payload:data});
       }
    });
  };
}
export function AC_DELETE_LANGUAGE(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteLanguage,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_LANGUAGE, payload:data});
      }
    });
  };
}

export function AC_ADD_LANGUAGE (formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddLanguage,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_LANGUAGE,payload:data});
      }
    });
  };
}

export function AC_LIST_LANGUAGE() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListLanguage)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_LANGUAGE, payload:data});
    });
  };
}


export function AC_VIEW_LANGUAGE(formData) {
  const id = { id : formData }
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewLanguage,id)
      .then(({ data }) => {
        swal.close();
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_LANGUAGE, payload:data});
    });
  };
}

export function AC_EDIT_LANGUAGE(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditLanguage,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_LANGUAGE, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_LANGUAGE_DATA, name:name,value:value})
  };
}
