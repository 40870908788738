const initialState = {
  tripList  : [],
  tripCount : 0,
  tripInfo  : {
    firstname : '',
    lastname  : '',
    cartype   : '',
    checkin   : '',
    checkout  : '',
    status    : '',
    id        : ''
  }
}

function TripReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_TRIP":
      return Object.assign({}, state, {
        tripList  : action.payload.data,
        tripCount : action.payload.count
      })
    case "VIEW_TRIP":
      return Object.assign({}, state, {
        tripInfo  : action.payload.data,
      })
    case "DELETE_TRIP":
        return Object.assign({}, state, {
          tripList : action.payload.data,
          tripCount : action.payload.count
      })
    case "EDIT_TRIP":
        return Object.assign({}, state, {
          tripInfo : action.payload.data,
      })
    case "UPDATE_TRIP_DATA":
      console.log(action)
      return edittripInfo(action,state);

    default:
      return state
  }
}

// functions to update edit TRIP data
function edittripInfo(action,state) {
  if(action.name === 'firstname') {
    return Object.assign({}, state, {
        tripInfo  : {
                    ...state.tripInfo,
                     firstname  : action.value
                   }
    })
  }
  if(action.name === 'lastname') {
    return Object.assign({}, state, {
        tripInfo : {
                    ...state.tripInfo,
                     lastname : action.value
                   }
    })
  }
  if(action.name === 'cartype') {
    return Object.assign({}, state, {
        tripInfo  : {
                    ...state.tripInfo,
                      cartype : action.value
                   }
    })
  }
  if(action.name === 'checkin') {
    return Object.assign({}, state, {
        tripInfo  : {
                    ...state.tripInfo,
                      checkin : action.value
                   }
    })
  }
  if(action.name === 'checkout') {
    return Object.assign({}, state, {
        tripInfo  : {
                    ...state.tripInfo,
                      checkout : action.value
                   }
    })
  }

  return 1;
}

export default TripReducer;
