import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_FAQ,AC_EDIT_FAQ,AC_HANDLE_INPUT_CHANGE} from '../../actions/faqaction';

class EditFaq extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
                      faqError             : false,
                      descrptionerror      : false,
                      faqtypeerror         : false,
                      statuserror          : false,
                    }
                    this.validateform = this.validateform.bind(this);
    }

    componentWillMount() {
      const id = this.props.match.params.id;
      this.props.ViewFaq(id);
    }

    //validtion
    validateform() {
      var faq                = this.props.FaqReducer.faqInfo.question;
      var description        = this.props.FaqReducer.faqInfo.answer;
      const faqtype          = this.props.FaqReducer.faqInfo.faqtype;
      const status           = this.props.FaqReducer.faqInfo.status;
      const id               = this.props.FaqReducer.faqInfo.id;
      if(faq) {
        this.setState({faqError:false})
      } else {
        this.setState({faqError:true})
      }
      if(description) {
         this.setState({descrptionerror:false})
      } else {
         this.setState({descrptionerror:true})
      }
      faq           =  faq.charAt(0).toUpperCase() + faq.slice(1);
      description   =  description.charAt(0).toUpperCase() + description.slice(1);
      if(faq && description) {
        // Formdata to send Api
          const faqFormData = {
                                question : faq,
                                answer   : description,
                                faqtype  : faqtype,
                                status   : status,
                                id       : id
                              }
          this.props.EditFaq(faqFormData);
      }
    }

    //onchange values
    onchangeValue(event) {
       const name        = event.target.name;
       const value       = event.target.value;
       this.props.HandleInputChange(name,value)
    }
  render() {
    const faqInfo  = this.props.FaqReducer.faqInfo;
    const question = faqInfo.question;
    const answer   = faqInfo.answer;
    // const faqtype  = faqInfo.faqtype;
    const status   = faqInfo.status;
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit FAQ</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Edit FAQ</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Edit FAQ</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                      <i className="fas fa-minus"></i></button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="inputName">FAQ Name</label><span style={{color:"red"}}>*</span>
                    <input type="text" id="inputName" className="form-control" name="faq" value={question} onChange={(e)=>this.onchangeValue(e)}/>
                    {this.state.faqError?<label style={{color:"red"}}>Question is Required</label>:""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="inputDescription">Description</label>
                    <textarea id="inputDescription" className="form-control" rows="4" name="description" value={answer} onChange={(e)=>this.onchangeValue(e)}></textarea>
                    {this.state.descrptionerror?<label style={{color:"red"}}>Description is Required</label>:""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">faqtype</label><span style={{color:"red"}}>*</span>
                     <select name = "faqtype" className="form-control" value={this.state.faqtype}onChange ={(e) => this.onchangeValue(e)}>
                       <option value = "1">Driver Help</option>
                       <option value = "0">Owner Help</option>
                     </select>
                   </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                     <select name = "status" className="form-control" onChange ={(e) => this.onchangeValue(e)}>
                       <option value="1" selected={status === 1}>Active</option>
                       <option value="0" selected={status === 0}>Inactive</option>
                     </select>
                   </div>
                  <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateform}>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    FaqReducer : state.FaqReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewFaq: AC_VIEW_FAQ,EditFaq:AC_EDIT_FAQ,HandleInputChange:AC_HANDLE_INPUT_CHANGE},dispatch)
}
const EditFaqComponent = connect(mapStateToProps,mapDispatchToProps)(EditFaq);

export default EditFaqComponent;
