import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_NEWSLETTER } from '../../actions/newsletteraction';


class ViewNewsletter extends React.Component {
  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewNewsLetter(id);
    window.$(function (){
      window.$('#compose-textarea').summernote({ height: 200,focus: true });
      window.$('#compose-textarea').summernote('disable');

    })
  }

  render() {
    const newsLetterInfo = this.props.NewsletterReducer.newsLetterInfo;
    const name           = newsLetterInfo.templatename;
    const subject        = newsLetterInfo.emailsubject;
    const description=newsLetterInfo.emailcontent;
    window.$('#compose-textarea').summernote('code',description);
    return(
          <div className="content-wrapper">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>View NewsLetter</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">Home</li>
                        <li className="breadcrumb-item active">View NewsLetter</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card card-primary">
                       
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Template Name</label>
                              <input type="name" name="name"  disabled className="form-control" placeholder="Enter email"  value={name} />
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Email Subject</label>
                              <input type="email" name="subject"  disabled className="form-control" id="exampleInputPassword1" placeholder="Email Subject"  value={subject} />
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Email Content</label>
                              <div className="form-group">
                                 <textarea type="textarea" id="compose-textarea" disabled name="description" className="form-control" style={{height: "200px"}}/>
                              </div>
                            </div>
                          </div>
                      
                      </div>
                   </div>
                 </div>
               </div>
             </section>
           </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    NewsletterReducer : state.NewsletterReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewNewsLetter: AC_VIEW_NEWSLETTER},dispatch)
}
const ViewNewsletterComponent = connect(mapStateToProps,mapDispatchToProps)(ViewNewsletter);

export default ViewNewsletterComponent;
