import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_CARTYPE,AC_EDIT_CARTYPE, AC_HANDLE_INPUT_CHANGE } from '../../actions/cartypeaction';
import { Imagevalidation } from '../../common/validate';
import API from '../../common/api';

class EditCarType extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
    cartype      : 'Sports car',
    cartypeerror : false,
    status       : '1',
    statuserror  : '',
    image        : '',
    imgSrc       : 'dist/img/car2.jpg',
    imageerror   : false,
    imagecheck   : false,
    imagevalue   : false
  }
  this.validate=this.validate.bind(this);
 }
 componentDidMount() {
 const id = this.props.match.params.id;
 this.props.viewCartype(id);
}

 validate() {
   const carInfo = this.props.CarTypeReducer.cartypeInfo;
   const cartype = carInfo.cartype;
   const image   = carInfo.image;
   const status  = carInfo.status;
   const imagecheck  = this.state.imagecheck;
   const id      = carInfo.id;
  //const statuserror = this.state.statuserror;
    if(cartype) {
       this.setState({cartypeerror : false})
    } else {
       this.setState({cartypeerror : true})
    }
    if(cartype && !imagecheck) {
         const formData = new FormData();
         formData.append('image', image);
         formData.append('id', id);
         formData.append('cartype', cartype);
         formData.append('status', status);
         this.props.editCarType(formData);
   }
 }
 //Onchange events
 onChangeValue(event){
   const name=event.target.name;
   var value=event.target.value;
   if(name ==='cartype'){
     this.setState({cartype:value,cartypeerror:false,})
   }

   // Image
   if(name==='image'){
     this.setState({ imagedata : event.target.files[0] })
        var file            =  event.target.files[0];
        if(file){
        const imgvalidate   =  Imagevalidation(file);
        if(imgvalidate){
        var reader          = new FileReader();
        var url             = reader.readAsDataURL(file);
        reader.onloadend    = function(e){
        this.setState({imgSrc : [reader.result],imagevalue:true,imagecheck:false})
        }.bind(this);
        }
        else{
          this.setState({image:value,imagecheck:true,})
          this.setState({imgSrc : ""})
        }
      }
        value = event.target.files[0];
   }
   this.props.HandleInputChange(name,value)
 }
render() {
  const carInfo = this.props.CarTypeReducer.cartypeInfo;
  const cartype = carInfo.cartype;
  const image   = carInfo.image;
  const status  = carInfo.status;
  const LIVEURL = API.LIVEURL;
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Edit Car Types</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Edit Car Types</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Edit Car Types</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Car Type</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="cartype"  pattern="[A-Za-z]" value={cartype} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.cartypeerror ? <label style={{color:"red"}}>Car Type is required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Image</label><span style={{color:"red"}}>*</span>
                    <div className="input-group">
                       <div className="custom-file">
                           <input type="file" name="image" className="custom-file-input" id="exampleInputFile" value='' onChange={(e)=>this.onChangeValue(e)}/>
                           <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                       </div>
                       <div className="input-group-append">
                           <button className="input-group-text" id="">Upload</button>
                       </div>
                    </div>
                      {this.state.imagecheck? <label style={{color:'red'}}>Image format is Invalid</label>:""}
                    <div>
                      {this.state.imagevalue? <img src={this.state.imgSrc} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/> : <img src={LIVEURL+image} style={{height:"100px",width:"150px",padding:'10px'}} alt=""/>}
                     </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                     <select name = "status" className="form-control"  onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1" selected={status == 1} >Active</option>
                       <option value = "0" selected={status == 0}>Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                       <button type="button" className="btn btn-primary" onClick={this.validate}>Update</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CarTypeReducer : state.CarTypeReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({viewCartype: AC_VIEW_CARTYPE, editCarType: AC_EDIT_CARTYPE,HandleInputChange: AC_HANDLE_INPUT_CHANGE },dispatch)
}
const EditCarTypeComponent = connect(mapStateToProps,mapDispatchToProps)(EditCarType);

export default EditCarTypeComponent;
