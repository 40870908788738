const initialstate = {
  newsLetterList : [],
  newsLetterCount : 0,
  newsLetterInfo  : {
    templatename  :'',
    emailsubject  :'',
    emailcontent  :'',
    id            :''
  }
}
// Newsletter Reducer
function NewsletterReducer (state = initialstate, action) {
  switch (action.type) {
    case "VIEW_NEWSLETTER":
      return Object.assign({}, state, {
        newsLetterInfo  : action.payload.data,
      });

    case 'LIST_NEWSLETTER':
    return Object.assign({}, state, {
      newsLetterList  : action.payload.data,
      newsLetterCount : action.payload.count
    });

    case 'EDIT_NEWSLETTER':
      return Object.assign({}, state, {
        newsLetterInfo  : action.payload.data,
      });

    case "DELETE_NEWSLETTER":
      return Object.assign({}, state, {
        newsLetterList  : action.payload.data,
        newsLetterCount : action.payload.count
      })
    case "UPDATE_NEWSLETTER_DATA":
      return editNewsLetterInfo(action,state);

    default:
      return state
  }
}
function editNewsLetterInfo(action,state) {
  if(action.name === 'name') {
    return Object.assign({}, state, {
        newsLetterInfo  : {
                    ...state.newsLetterInfo,
                     templatename : action.value
                   }
    })
  }
  if(action.name === 'subject') {
    return Object.assign({}, state, {
        newsLetterInfo  : {
                    ...state.newsLetterInfo,
                     emailsubject : action.value
                   }
    })
  }
  if(action.name === 'description') {
    return Object.assign({}, state, {
        newsLetterInfo  : {
                    ...state.newsLetterInfo,
                     emailcontent : action.value
                   }
    })
  }

  return 1;
}

export default NewsletterReducer;
