import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_CURRENCY,AC_EDIT_CURRENCY, AC_HANDLE_INPUT_CHANGE } from '../../actions/currencyaction';

class EditCurrency extends React.Component {
 constructor(props) {
  super(props);
  this.state = {
    currencyname      : 'Sports car',
    currencynameerror : false,
    status            : 1,
    statuserror       : '',
    symbol            : '',
    symbolerror       : false,
  }
  this.validate=this.validate.bind(this);
 }
 componentDidMount() {
 const id = this.props.match.params.id;
 this.props.ViewCurrency(id);
}

 validate() {
   const currencyInfo = this.props.CurrencyReducer.currencyInfo;
   var currencyname   = currencyInfo.currencyname;
   const symbol       = currencyInfo.symbol;
   const status       = currencyInfo.status;
   const id           = currencyInfo.id;
    if(currencyname) {
       this.setState({currencynameerror : false})
    } else {
       this.setState({currencynameerror : true})
    }
    if(symbol) {
       this.setState({symbolerror : false})
    } else {
       this.setState({symbolerror : true})
    }
    currencyname = currencyname.toUpperCase()
    if(currencyname && symbol) {
      const currencyFormData = {
                            currencyname : currencyname,
                            symbol       : symbol,
                            status       : status,
                            id           : id
                          }
         this.props.EditCurrency(currencyFormData);
   }
 }
 //Onchange events
 onChangeValue(event){
   const name=event.target.name;
   var value=event.target.value;
   if(name ==='currencyname'){
     this.setState({currencyname:value,currencynameerror:false,})
   }

   // Symbol
   if(name ==='symbol'){
     this.setState({symbol:value,symbolerror:false,})
   }
   this.props.HandleInputChange(name,value)
 }
render() {
  const currencyInfo = this.props.CurrencyReducer.currencyInfo;
  const currencyname = currencyInfo.currencyname;
  const symbol       = currencyInfo.symbol;
  const status       = currencyInfo.status;
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Edit Currency</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Edit Currency</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Edit Currency</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Currency Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="currencyname" placeholder="Currency Name" pattern="[A-Za-z]" value={currencyname} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.currencynameerror ? <label style={{color:"red"}}>Currency Name is Required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Symbol</label><span style={{color:"red"}}>*</span>
                    <div className="form-group">
                       <input type="text" className="form-control" name="symbol" placeholder="Currency Symbol" pattern="[A-Za-z]" value={symbol} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.symbolerror ? <label style={{color:"red"}}>Currency Symbol is Required</label> : ""}
                    </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label>
                     <select name = "status" className="form-control"  onChange ={(e) => this.onChangeValue(e)}>
                       <option value = {1} selected={status === 1} >Active</option>
                       <option value = {0} selected={status === 0}>Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                       <button type="button" className="btn btn-primary" onClick={this.validate}>Update</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CurrencyReducer : state.CurrencyReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewCurrency: AC_VIEW_CURRENCY, EditCurrency: AC_EDIT_CURRENCY,HandleInputChange: AC_HANDLE_INPUT_CHANGE },dispatch)
}
const EditCurrencyComponent = connect(mapStateToProps,mapDispatchToProps)(EditCurrency);

export default EditCurrencyComponent;
