import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_CONFIGURATION } from '../../actions/configurationaction';

class AddConfiguration extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    name              : '',
    slug              : '',
    temp              : '',
    description       : '',
    nameerror         : false,
    slugerror         : false,
    descriptionerror  : false
  }
  this.validateForm=this.validateForm.bind(this);
}
//Validation
 validateForm() {
    const name              = this.state.name;
    const description       = this.state.description;
    if(name) {
       this.setState({nameerror : false})
    }
    else{
       this.setState({nameerror : true})
    }
    if(description){
        this.setState({descriptionerror:false});
    }
    else{
        this.setState({descriptionerror:true});
    }
   var slug = this.state.name.toLowerCase();
   slug = slug.replace(/[^\w-]+/g, "-");
   if(name && slug && description) {
    //Formdata to send Api
      this.setState({name:"",description:"",slug:""});
      const configurationFormData = {
                            name          : name,
                            description   : description,
                            slug          : slug,
                          }
     this.props.AddConfiguration(configurationFormData);
   }
 }
 // Onchange events
 onChangeValue(event){
   const name=event.target.name;
   const value=event.target.value;
   if(name ==='name'){
     if(value){
       this.setState({name:value,nameerror:false})
     } else {
       this.setState({name:value,nameerror:true})
     }
   }
  if(name ==='description'){
    if(value){
      this.setState({description:value,descriptionerror:false})
    } else {
      this.setState({description:value,descriptionerror:true})
    }
  }
 }
 render() {
  var slug = this.state. name.toLowerCase();
  slug = slug.replace(/[^\w-]+/g, "-");
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Add Configuration</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Configuration</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Configuration</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Configuration Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="name" placeholder="Enter Name" pattern="[A-Za-z]"  value={this.state.name} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.nameerror ? <label style={{color:"red"}}>Configuration Name is Required</label> : ""}
                    </div>
                    <div className="form-group">
                       <label>Slug</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control"  placeholder="Enter Slug" pattern="[A-Za-z]"  value={slug} />
                    </div>
                    <div className="form-group">
                       <label>Description</label><span style={{color:"red"}}>*</span>
                       <input type="textarea" className="form-control" name="description" placeholder="Enter Description" pattern="[A-Za-z]"  value={this.state.description} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.descriptionerror ? <label style={{color:"red"}}>Description is Required</label> : ""}
                    </div>
                     <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateForm}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    ConfigurationReducer : state.ConfigurationReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddConfiguration: AC_ADD_CONFIGURATION},dispatch)
}
const AddConfigurationComponent = connect(mapStateToProps,mapDispatchToProps)(AddConfiguration);

export default AddConfigurationComponent;
