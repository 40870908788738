import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_CARFEATURES } from '../../actions/carfeaturesaction';
import { Imagevalidation } from '../../common/validate';

class AddCarfeatures extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    name         : '',
    icon         : '',
    status       : '1',
    iconSrc      : 'logo2.png',
    nameerror    : false,
    iconerror    : false,
    iconcheck    : false
  }
  this.validateForm=this.validateForm.bind(this);
}
 //Validation
 validateForm() {
    const carfeatures_name     = this.state.name;
    const carfeatures_icon     = this.state.icondata;
    const status               = this.state.status;
    const iconcheck            = this.state.iconcheck;
    if(carfeatures_name) {
       this.setState({nameerror : false})
    }
    else{
       this.setState({nameerror : true})
    }
    if(carfeatures_icon){
        this.setState({iconerror:false});
    }
    else{
        this.setState({iconerror:true});
     }
    const name   =  carfeatures_name.charAt(0).toUpperCase() + carfeatures_name.slice(1);
    if(name && carfeatures_icon && !iconcheck) {
      // Formdata to send Api
      this.setState({ name:"",icondata:"",status:"1",iconSrc : "profile.jpg"});
      const formData = new FormData();
       formData.append('icon', carfeatures_icon);
       formData.append('name', name);
       formData.append('status', status);
       this.props.AddCarfeatures(formData);
   }
 }
 // Onchange events
 onChangeValue(event){
   const name  = event.target.name;
   const value = event.target.value;
   if(name ==='name'){
     if(value){
       this.setState({name:value,nameerror:false})
     } else {
       this.setState({name:value,nameerror:true})
     }
   }
   if(name ==='status'){
     if(value){
       this.setState({status:value})
     }
     else{
       this.setState({status:value})
     }
   }
   // icon
   if(name==='icon') {
    this.setState({ icondata : event.target.files[0] })
    this.setState({iconerror:false})
    var file            =  event.target.files[0];
    const iconvalidate   =  Imagevalidation(file);
    if(iconvalidate) {
      var reader          = new FileReader();
      var url             = reader.readAsDataURL(file);
      reader.onloadend    = function(e){
      this.setState({iconSrc : [reader.result],iconcheck:false})
    }.bind(this);
    } else {
      this.setState({iconSrc : "profile.jpg",iconcheck:true,})
    }
 }
 }
render() {
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Add CarFeatures</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Add CarFeatures</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Add CarFeatures</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="name" placeholder="Enter Name" pattern="[A-Za-z]"  value={this.state.name} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.nameerror ? <label style={{color:"red"}}>Name is Required</label> : ""}
                    </div>
                    <label htmlFor="exampleInputFile">Icon</label><span style={{color:"red"}}>*</span>
                    <div className="input-group">
                       <div className="custom-file">
                           <input type="file" name="icon" className="custom-file-input" id="exampleInputFile"  onChange={(e)=>this.onChangeValue(e)}/>
                           <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                       </div>
                       <div className="input-group-append">
                           <button className="input-group-text" id="">Upload</button>
                       </div>
                    </div>
                        {this.state.iconerror? <label style={{color:'red'}}>Icon is Required</label>:""}
                        {this.state.iconcheck? <label style={{color:'red'}}>Icon format is Invalid</label>:""}
                    <div>
                       <img src={this.state.iconSrc} style={{height:"110px",width:"160px",padding:'10px'}} alt=""/>
                     </div>
                    <div className="form-group">
                    <label htmlFor="exampleInputFile">Status</label><span style={{color:"red"}}>*</span>
                     <select name = "status" className="form-control" value={this.state.status} onChange ={(e) => this.onChangeValue(e)}>
                       <option value = "1">Active</option>
                       <option value = "0">Inactive</option>
                     </select>
                     </div>
                     <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateForm}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    CarfeaturesReducer : state.CarfeaturesReducer
  };
 }
function mapDispatchToProps(dispatch) {
  return bindActionCreators({AddCarfeatures: AC_ADD_CARFEATURES},dispatch)
}
const AddCarfeaturesComponent = connect(mapStateToProps,mapDispatchToProps)(AddCarfeatures);

export default AddCarfeaturesComponent
