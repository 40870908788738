const initialState = {
  brandList  : [],
  brandCount : 0,
  modeloptionList   : [],
  modeloptionCount  : 0,
  brandInfo  : {
                  brandname : '',
                  logo      : '',
                  status    : '',
                  id        :''
                }
}

function BrandReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_BRAND":
      return Object.assign({}, state, {
        brandList  : action.payload.data,
        brandCount : action.payload.count
      })
    case "VIEW_BRAND":
      return Object.assign({}, state, {
        brandInfo        : action.payload.data,
        modeloptionList  : action.payload.optionList,
        modeloptionCount : action.payload.count
      })
    case "DELETE_BRAND":
      return Object.assign({}, state, {
        brandList  : action.payload.data,
        brandCount : action.payload.count,

      })
    case "EDIT_BRAND":
      return Object.assign({}, state, {
        brandInfo  : action.payload.data,
      })
    case "UPDATE_BRAND_DATA":
      return editBrandInfo(action,state);

      case "EDIT_MODELOPTION":
        return Object.assign({}, state, {
            modeloptionList  : action.payload.data,
            modeloptionCount : action.payload.count
        })
      case "ADD_MODELOPTION":
        return Object.assign({}, state, {
          modeloptionList  : action.payload.data,
          modeloptionCount : action.payload.count
        })
      case "LIST_MODELOPTION":
        console.log("==========in reducer",action.payload.data);

        return Object.assign({}, state, {
            modeloptionList  : action.payload.data,
            modeloptionCount : action.payload.count
        })
      case "DELETE_MODELOPTION":
      return Object.assign({}, state, {
        modeloptionList  : action.payload.data,
        modeloptionCount : action.payload.count
      })
    default:
      return state
  }
}

// functions to update edit brand management
function editBrandInfo(action,state) {
  if(action.name === 'brandname') {
    return Object.assign({}, state, {
        brandInfo  : {
                    ...state.brandInfo,
                     brandname : action.value
                   }
    })
  }
  if(action.name === 'image') {
    return Object.assign({}, state, {
        brandInfo  : {
                    ...state.brandInfo,
                    logo : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        brandInfo  : {
                    ...state.brandInfo,
                     status : action.value
                   }
    })
  }

  return 1;
}

export default BrandReducer;
