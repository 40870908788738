import React from 'react';
import {Emailvalidate,Phonenumber,Zip} from '../../common/validate.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_CAR_LIST } from '../../actions/carlistsaction';


class Listyourcar extends React.Component {

  constructor(props){
    super(props);
    this.state=
    {
      firstname            :'',
      lastname             :'',
      email                :'',
      number               :'',
      country              :'',
      zip                  :'',
      caryear              :'',
      mileage              :'',
      emailvalue           :'',
      numbervalue          :'',
      zipvalue             :'',
      yearvalue            :'',
      validemailError      :'',
      validnumberError     :'',
      validzipError        :'',
      validyearError       :'',
      firstnameError       :false,
      lastnameError        :false,
      emailError           :false,
      numberError          :false,
      countryError         :false,
      zipError             :false,
      caryearError         :false,
      mileageError         :false,

    }
    this.validateform=this.validateform.bind(this);
  }
    //validation
    validateform(){
      var firstname   =  this.state.firstname;
      var lastname    =  this.state.lastname;
      const email     =  this.state.email;
      const number    =  this.state.number;
      var country     =  this.state.country;
      const zip       =  this.state.zip;
      const caryear   =  this.state.caryear;
      const mileage   =  this.state.mileage;
      if(firstname){
        this.setState({firstnameError:false});
      }else{
        this.setState({firstnameError:true});
      }
      if(lastname){
        this.setState({lastnameError:false});
      }else{
        this.setState({lastnameError:true});
      }if(email){
        this.setState({emailError:false});

      }else{
        this.setState({emailError:true});
      }if(number){
        this.setState({numberError:false});
      }else{
        this.setState({numberError:true});
      }if(country){
        this.setState({countryError:false});
      }else{
        this.setState({countryError:true});
      }if(zip){
        this.setState({zipError:false});
      }else{
        this.setState({zipError:true});
      }if(caryear){
        this.setState({caryearError:false});
      }else{
        this.setState({caryearError:true});
      }if(mileage){
        this.setState({mileageError:false});
      }else{
        this.setState({mileageError:true});
      }
      firstname  =  firstname.charAt(0).toUpperCase() + firstname.slice(1);
      lastname   =  lastname.charAt(0).toUpperCase() + lastname.slice(1);
      country    =  country.charAt(0).toUpperCase() + country.slice(1);
      if(firstname && lastname && email && number && country && zip && caryear && mileage) {
          // Formdata to send Api
          this.setState({firstname:"",lastname:"",email:"",number:"",country:"",zip:"",caryear:"",mileage:""});
          const listyourcarFormData = {
                                firstname : firstname,
                                lastname  : lastname,
                                email     : email,
                                number    : number,
                                country   : country,
                                zip       : zip,
                                caryear   : caryear,
                                mileage   : mileage
                            }
          this.props.ListyourCar(listyourcarFormData);
      }
}
      //onchange values
      onChangeValue(event){
        const name          =  event.target.name;
        const value         =  event.target.value;
        const isvalid       =  Emailvalidate(value);
        const number        =  Phonenumber(value);
        const zip           =  Zip(value);
        if(name === 'firstname'){
          if(value){
            this.setState({firstname:value,firstnameError:false})
          } else {
            this.setState({firstname:value,firstnameError:true})
          }
        }
        if(name === 'lastname'){
          if(value){
            this.setState({lastname:value,lastnameError:false})
          } else {
            this.setState({lastname:value,lastnameError:true})
          }
        }
        //email
        if(name === 'email'){
             this.setState({email:value,emailError:false});
             if(!isvalid){
              this.setState({email:value,emailError:false,validemailError:"Email is invalid",emailvalue:false})
              }else{
              this.setState({email:value,validemailError:"",emailvalue:true})
              }
        }
        //number
        if(name === 'number'){
              if(!number){
               this.setState({number:value,numberError:false,validnumberError:"Number is Invalid",numbervalue:false})
             }else{
               this.setState({number:value,numberError:false,validnumberError:"",numbervalue:true})
             }
        }
        if(name === 'country'){
          if(value){
            this.setState({country:value,countryError:false})
          } else {
            this.setState({country:value,countryError:true})
          }
        }
        if(name === 'zip'){
             this.setState({zip:value,zipError:false})
             if(!zip){
               this.setState({zip:value,zipError:false,validzipError:"Zip is Invalid",zipvalue:false})
             }else{
               this.setState({zip:value,validzipError:"",zipvalue:true})
             }
        }
        if(name === 'caryear'){
              if(value.length===4){
               this.setState({caryear:value,caryearError:false,validyearError:"",yearvalue:true})
             }else{
                this.setState({caryear:value,caryearError:false,validyearError:"Year is Invalid",yearvalue:false})
             }
        }
        if(name === 'mileage'){
          if(value){
            this.setState({mileage:value,mileageError:false})
          } else {
            this.setState({mileage:value,mileageError:true})
          }
        }
      }
      render() {
            return(
                  <div className="content-wrapper">
                    <section className="content-header">
                      <div className="container-fluid">
                        <div className="row mb-2">
                           <div className="col-sm-6">
                              <h1>List Your Car</h1>
                           </div>
                          <div className="col-sm-6">
                              <ol className="breadcrumb float-sm-right">
                                  <li className="breadcrumb-item">Home</li>
                                  <li className="breadcrumb-item active">List Your Car</li>
                              </ol>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="content">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card card-primary">
                            <div className="card-header">
                              <h3 className="card-title">List Your Car</h3>
                            </div>
                             
                                <div className="card-body">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Firstname<span style={{color:"red"}}>*</span></label>
                                    <input type="text" name="firstname" className="form-control"  placeholder="Firstname" value={this.state.firstname}  onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.firstnameError?<label style={{color:"red"}}>Firstname is Required</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Lastname<span style={{color:"red"}}>*</span></label>
                                    <input type="text" name="lastname" className="form-control"  placeholder="Lastname" value={this.state.lastname}  onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.lastnameError?<label style={{color:"red"}}>Lastname is Required</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email<span style={{color:"red"}}>*</span></label>
                                    <input type="text" name="email" className="form-control"  placeholder="Email" value={this.state.email}  onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.emailError?<label style={{color:"red"}}>Email is Required</label>:""}
                                    {this.state.validemailError!==""?<label style={{color:"red"}}>{this.state.validemailError}</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Phone Number <span style={{color:"red"}}>*</span></label>
                                    <input type="number" name="number" className="form-control"  placeholder="Phone Number " value={this.state.number} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.numberError? <label style={{color:'red'}}>Number is Required</label>:""}
                                    {this.state.validnumberError!==""?<label style={{color:"red"}}>{this.state.validnumberError}</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Country <span style={{color:"red"}}>*</span></label>
                                    <input type="text" name="country" className="form-control"  placeholder="Country" value={this.state.country}  onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.countryError?<label style={{color:"red"}}>Country is Required</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">ZIP <span style={{color:"red"}}>*</span></label>
                                    <input type="number" name="zip" className="form-control"  placeholder="Zip" value={this.state.zip} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.zipError?<label style={{color:"red"}}>ZIP is Required</label>:""}
                                    {this.state.validzipError!==""?<label style={{color:"red"}}>{this.state.validzipError}</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Car Year<span style={{color:"red"}}>*</span></label>
                                    <input type="number" name="caryear" className="form-control"  placeholder="Car Year" value={this.state.caryear} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.caryearError? <label style={{color:'red'}}>Number is Required</label>:""}
                                    {this.state.validyearError!==""?<label style={{color:"red"}}>{this.state.validyearError}</label>:""}
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Mileage <span style={{color:"red"}}>*</span></label>
                                    <input type="number" name="mileage" className="form-control"  placeholder="Mileage" value={this.state.mileage}  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=>this.onChangeValue(e)}/>
                                    {this.state.mileageError?<label style={{color:"red"}}>Mileage is Required</label>:""}
                                  </div>
                                    <div className="card-footer">
                                     <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                                    </div>
                                  </div>
                                 
                                </div>
                              </div>
                          </div>
                       </div>
                 </section>
              </div>
        )
     }
  }
  function mapStateToProps(state) {
    return {
    CarReducer : state.CarReducer
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ListyourCar: AC_CAR_LIST},dispatch)
  }
  const CarListsComponent = connect(mapStateToProps,mapDispatchToProps)(Listyourcar);

  export default CarListsComponent;
