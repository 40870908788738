const initialState = {
  languageList : [],
  languageCount : 0,
  languageInfo  : {
    languagename   : '',
    languagecode   : '',
    status         : '',
    id             : ''
  }
}

function LanguageReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_LANGUAGE":
      return Object.assign({}, state, {
        languageList  : action.payload.data,
        languageCount : action.payload.count
      })
    case "VIEW_LANGUAGE":
      return Object.assign({}, state, {
        languageInfo  : action.payload.data,
      })
    case "DELETE_LANGUAGE":
      return Object.assign({}, state, {
      languageList  : action.payload.data,
      languageCount : action.payload.count
      })
    case "EDIT_LANGUAGE":
      return Object.assign({}, state, {
        languageInfo  : action.payload.data,
      })
    case "UPDATE_LANGUAGE_DATA":
      return editLanguageInfo(action,state);
    default:
      return state
  }
}

// functions to update edit languageInfo data
function editLanguageInfo(action,state) {
  if(action.name === 'name') {
    return Object.assign({}, state, {
        languageInfo  : {
                    ...state.languageInfo,
                     languagename : action.value
                   }
    })
  }
  if(action.name === 'code') {
    return Object.assign({}, state, {
        languageInfo  : {
                    ...state.languageInfo,
                     languagecode : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        languageInfo  : {
                    ...state.languageInfo,
                     status : action.value
                   }
    })
  }

  return 1;
}


export default LanguageReducer;
