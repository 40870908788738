import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE,AC_UPDATE_ADMIN_SETTINGS } from '../../actions/settings';

class AppSettings extends React.Component {
  constructor(props){
    super(props);
    this.validateform=this.validateform.bind(this);
  }

  // Validation
  validateform(){
    const appSettingInfo    = this.props.SettingsReducer.appSettingInfo;
    const id                = this.props.SettingsReducer.settingId;
    this.props.updateAdminSettings(appSettingInfo,id,'Appsetting');
  }

  // Onchange Values
  onChangeValue(event){
    const name =event.target.name;
    const value=event.target.value;
    this.props.handleInputChange(name,value,'Appsetting')
  }
  render() {
    const appSettingInfo  = this.props.SettingsReducer.appSettingInfo;
    var customerservicenumber       = "";
    var welcomeamount               = "";
    var amountperreferal            = "";
    var referalcredit               = "";
    if(appSettingInfo) {
       customerservicenumber = appSettingInfo.customerservicenumber;
       welcomeamount         = appSettingInfo.welcomeamount;
       amountperreferal      = appSettingInfo.amountperreferal;
       referalcredit         = appSettingInfo.referalcredit;
    }
    return(
        <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>App Settings</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">App Settings</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">App Settings</h3>
                  </div>
                  
                    <div className="card-body">
                      <div className="form-group">
                        <label >Customer service number</label>
                        <input type="number" className="form-control" name="customerservicenumber" id="customerservicenumber"  placeholder="Customer service number" value={customerservicenumber} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=> this.onChangeValue(e)}/>
                      </div>
                      <div className="form-group">
                        <label>Welcome Amount</label>
                        <input type="number" className="form-control" name="welcomeamount" id="welcomeamount" placeholder="Welcome Amount"  value={welcomeamount} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=> this.onChangeValue(e)}/>
                      </div>
                      <div className="form-group">
                        <label>Amount per Referal</label>
                        <input type="number" className="form-control" name="amountperreferal" id="amountperreferal" placeholder="Amount per Referal"  value={amountperreferal} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=> this.onChangeValue(e)}/>
                      </div>
                      <div className="form-group">
                        <label>Referal Credit</label>
                        <select className="form-control" name="referalcredit" id="referalcredit" placeholder="Referal Credit" value={referalcredit} onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()} onChange={(e)=> this.onChangeValue(e)}>
                          <option value = "0">None</option>
                          <option value = "1">Instant</option>
                          <option value = "2">On First Job</option>
                        </select>
                      </div>
                      <div className="card-footer">
                        <button type="button" className="btn btn-primary" onClick={this.validateform}>Submit</button>
                      </div>
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({handleInputChange:AC_HANDLE_INPUT_CHANGE,updateAdminSettings:AC_UPDATE_ADMIN_SETTINGS},dispatch)
}
const AppSettingsComponent = connect(mapStateToProps,mapDispatchToProps)(AppSettings);

export default AppSettingsComponent;
