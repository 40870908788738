const initialState = {
  cartypeList : [],
  cartypeListCount : 0,
  cartypeInfo : {
    cartype :'',
    image   :'',
    status :'',
    id     : ''

  }
}

function CarTypeReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_CARTYPE":
      return Object.assign({}, state, {
        cartypeList       : action.payload.data,
        cartypeListCount  : action.payload.count
      })
    case "VIEW_CARTYPE":
      return Object.assign({}, state, {
        cartypeInfo  : action.payload.data,
      })
      case "DELETE_CARTYPE":
        return Object.assign({}, state, {
          cartypeList      : action.payload.data,
          cartypeListCount : action.payload.count
        })
    case "EDIT_CARTYPE":
      return Object.assign({}, state, {
        cartypeInfo  : action.payload.data,
      })
    case "UPDATE_CARTYPE_DATA":
        return editCarTypeInfo(action,state);
    default:
      return state
  }
}

function editCarTypeInfo(action,state) {
  if(action.name === 'cartype') {
    return Object.assign({}, state, {
        cartypeInfo  : {
                    ...state.cartypeInfo,
                     cartype : action.value
                   }
    })
  }
  if(action.name === 'image') {
    return Object.assign({}, state, {
        cartypeInfo  : {
                    ...state.cartypeInfo,
                     image : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        cartypeInfo  : {
                    ...state.cartypeInfo,
                     status : action.value
                   }
    })
  }
}

export default CarTypeReducer;
