import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_VIEW_PAYEMENTGATEWAY } from '../../actions/payementgatewayaction';

class ViewPayementGateway extends React.Component {

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.ViewPayementGateway(id);
  }

  render() {
    const payementgatewayInfo  = this.props.PayementgatewayReducer.payementgatewayInfo;
    const name    = payementgatewayInfo.name;
    const mode    = payementgatewayInfo.mode;
    const apikey  = payementgatewayInfo.apikey;
    const status  = payementgatewayInfo.status;
      return(
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>View Payement Gateway</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">Home</li>
                    <li className="breadcrumb-item active">View Payement Gateway</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">View Payement Gateway</h3>
                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                        <i className="fas fa-minus"></i></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label >Name</label>
                      <input type="text" id="inputName" className="form-control"  name="name" value={name} disabled />
                    </div>
                    <div className="form-group">
                      <label >Payement Mode</label>
                      <input type="text" id="inputDescription" className="form-control" rows="4" name="mode" value={mode} disabled/>
                    </div>
                    <div className="form-group">
                      <label >Api Key</label>
                      <input type="text" id="inputName" className="form-control"  name="apikey" value={apikey} disabled />
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                        <select name = "status" disabled className="form-control" >
                          <option value = {1} selected={status===1}>Active</option>
                            <option value = {0} selected={status===0}>Inactive</option>
                        </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    PayementgatewayReducer : state.PayementgatewayReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ViewPayementGateway: AC_VIEW_PAYEMENTGATEWAY},dispatch)
}
const ViewPayementGatewayComponent = connect(mapStateToProps,mapDispatchToProps)(ViewPayementGateway);

export default ViewPayementGatewayComponent;
