import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';

// Action types for Carfeatures management
const LIST_CARFEATURES         = 'LIST_CARFEATURES';
const ADD_CARFEATURES          = 'ADD_CARFEATURES';
const EDIT_CARFEATURES         = 'EDIT_CARFEATURES';
const VIEW_CARFEATURES         = 'VIEW_CARFEATURES';
const UPDATE_CARFEATURES_DATA  = 'UPDATE_CARFEATURES_DATA';
const DELETE_CARFEATURES       = 'DELETE_CARFEATURES';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})
export function AC_UPDATE_CARFEATURESTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateCarFeaturesStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: DELETE_CARFEATURES, payload:data});
       }
    });
  };
}
export function AC_LIST_CARFEATURES() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListCarfeatures,{})
      .then(({ data }) => {
        swal.close();
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_CARFEATURES, payload:data});
    });
  };
}

export function AC_DELETE_CARFEATURES(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteCarfeatures,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_CARFEATURES, payload:data});
      }
    });
  };
}

export function AC_ADD_CARFEATURES(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddCarfeatures,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_CARFEATURES, payload:data});
      }
    });
  };
}
export function AC_VIEW_CARFEATURES(formData) {
  const id = { id : formData }
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.ViewCarfeatures,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_CARFEATURES, payload:data});
    });
  };
}
export function AC_EDIT_CARFEATURES(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditCarfeatures,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_CARFEATURES, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_CARFEATURES_DATA, name:name,value:value})
  };
}
