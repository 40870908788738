import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success , Loader ,Error} from '../common/swal';
// Action types for Configuration management
const LIST_CONFIGURATION        = 'LIST_CONFIGURATION';
const ADD_CONFIGURATION         = 'ADD_CONFIGURATION';
const EDIT_CONFIGURATION        = 'EDIT_CONFIGURATION';
const VIEW_CONFIGURATION        = 'VIEW_CONFIGURATION';
const UPDATE_CONFIGURATION_DATA = 'UPDATE_CONFIGURATION_DATA';
const DELETE_CONFIGURATION      = 'DELETE_CONFIGURATION';
const ADD_CONFIGOPTION          = 'ADD_CONFIGOPTION';
const DELETE_CONFIGOPTION       = 'DELETE_CONFIGOPTION';
const LIST_CONFIGOPTION         = 'LIST_CONFIGOPTION';
const EDIT_CONFIGOPTION         = 'EDIT_CONFIGOPTION';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_DELETE_CONFIGURATION(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteConfiguration,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_CONFIGURATION, payload:data});
      }
    });
  };
}
export function AC_DELETE_CONFIGOPTION(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteConfigoption,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_CONFIGOPTION, payload:data});
      }
    });
  };
}
export function AC_ADD_CONFIGURATION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddConfiguration,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_CONFIGURATION, payload:data});
      }
    });
  };
}
export function AC_ADD_CONFIGOPTION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddConfigoption,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status) {
          Success(data.message)
        } else {
          Error(data.message)
        }
        dispatch({type: ADD_CONFIGOPTION, payload:data});
      }
    });
  };
}
export function AC_LIST_CONFIGOPTION() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListConfigoption)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        dispatch({type: LIST_CONFIGOPTION, payload:data});
        }
    });
  };
}

export function AC_LIST_CONFIGURATION() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListConfiguration)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        dispatch({type: LIST_CONFIGURATION, payload:data});
        }
    });
  };
}

export function AC_VIEW_CONFIGURATION(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewConfiguration,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        dispatch({type: VIEW_CONFIGURATION, payload:data});
        }
    });
  };
}

export function AC_EDIT_CONFIGURATION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditConfiguration,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_CONFIGURATION, payload:data});
      }
    });
  };
}

export function AC_EDIT_CONFIGOPTION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditConfigoption,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_CONFIGOPTION, payload:data});
        }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_CONFIGURATION_DATA, name:name,value:value})
  };
}
