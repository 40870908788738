import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect  } from "react-router-dom";
import API from '../../common/api';
import { AC_RENTEDCAR_LIST } from '../../actions/rentaction';

class OldRentedCar extends React.Component {

  constructor(props) {
    super(props);
    const url=API.LIVEURL;
    this.state = {
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '50',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Brandname", field: "brandname", width: '150',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        { headerName: "Image", field: "image", width: '200',
          cellRenderer: function (params) {
           var image = url.concat("uploads/" ,params.value);
           return '<img src='+image+' alt="Image not found" style="width: 80%; height: 100%;"/>';
          }
        },
        { headerName: "Year", field: "year", width: '100',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { headerName: "Ratings", field: "rating", width: '100',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
         { headerName: "Country", field: "country", width: '150',
           cellStyle: function(params) {
                return {'font-size': '16px','margin-top': '5px'};
          }},
          { headerName: "Price", field: "price", width: '100',
            cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
          }},
          { "header" : "status", field :"status",width:"100",
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           },
           cellRenderer: function(params) {
             if(params.data.status) {
               return '<span class="badge badge-success">Active</span>';
             } else {
               return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);">Inactive</span>';
             }
           }
          },
          { headerName: 'Actions', width: "100",sortable: false,filter:false,
           template:
           `<div>
              <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
                <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
                View
              </div>
            </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ],
    }
  }

  componentDidMount() {
    var rentStatus = { rentStatus : 2};
    this.props.RentList(rentStatus);
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
  }
  render() {
    const rentList      = this.props.RentReducer.rentList;
    // const tableRentList = [];
    if(this.props.RentReducer.rentList) {
      // const rentList      = this.props.RentReducer.rentList;
      if(this.state.redirect === 'View'){
        return <Redirect to ={'/ViewRentedCar/'+this.state.id}/>
      }
    }

    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Old Rented Car List</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Old Rented Cars</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Old Rented Cars</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  defaultColDef={{filter:true,floatingFilter:true}}
                  rowData={rentList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
    return {
          RentReducer : state.RentReducer
    };
}

function mapDispatchToProps(dispatch) {
      return bindActionCreators({RentList: AC_RENTEDCAR_LIST },dispatch)
}

const OldRentedCarComponent = connect(mapStateToProps,mapDispatchToProps)(OldRentedCar);

export default OldRentedCarComponent;
