import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {AC_LIST_DASHBOARD_NOTIFS} from "../../actions/DashboardNotification"


class Header extends React.Component {


  constructor(props){
    super(props)
    this.state = {
      dahsBoardNotifs : [],
      unredNotifs : []
    }
  }

  async componentDidMount(){
    await this.props.DashbordNotifs()
    this.setState({
      dahsBoardNotifs:this.props.DashboardNotifsReduccer.dashboardNotifsList,
      unredNotifs:this.props.DashboardNotifsReduccer.dashboardNotifsList.filter( dshn => dshn.hasRead === false)
    })    
  }

  render() {

    return(
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <div className="nav-link" data-widget="pushmenu" ><i className="fas fa-bars"></i></div>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <div href="" className="nav-link">Home</div>
        </li>
      </ul>

      <form className="form-inline ml-3">
        <div className="input-group input-group-sm">
          <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search"/>
          <div className="input-group-append">
            <button className="btn btn-navbar" type="submit">
              <i className="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown">
          <div className="nav-link" data-toggle="dropdown" >
            <i className="far fa-comments"></i>
            <span className="badge badge-danger navbar-badge">3</span>
          </div>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <div  className="dropdown-item">
              <div className="media">
                <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle"/>
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Brad Diesel
                    <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                  </h3>
                  <p className="text-sm">Call me whenever you can...</p>
                  <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                </div>
              </div>
            </div>
            <div className="dropdown-divider"></div>
            <div  className="dropdown-item">
              <div className="media">
                <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3"/>
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    John Pierce
                    <span className="float-right text-sm text-muted"><i className="fas fa-star"></i></span>
                  </h3>
                  <p className="text-sm">I got your message bro</p>
                  <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                </div>
              </div>
            </div>
            <div className="dropdown-divider"></div>
            <div  className="dropdown-item">
              <div className="media">
                <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3"/>
                <div className="media-body">
                  <h3 className="dropdown-item-title">
                    Nora Silvester
                    <span className="float-right text-sm text-warning"><i className="fas fa-star"></i></span>
                  </h3>
                  <p className="text-sm">The subject goes here</p>
                  <p className="text-sm text-muted"><i className="far fa-clock mr-1"></i> 4 Hours Ago</p>
                </div>
              </div>
            </div>
            <div className="dropdown-divider"></div>
            <div  className="dropdown-item dropdown-footer">See All Messages</div>
          </div>
        </li>

        {/* ALERT NOTIFICATION */}
        <li className="nav-item dropdown">
          <div className="nav-link" data-toggle="dropdown" >
            <i className="far fa-bell"></i>
            <span className="badge badge-warning navbar-badge">{this.state.unredNotifs.length}</span>
          </div>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">{this.state.unredNotifs.length > 1 ? this.state.unredNotifs.length + " Notifications" : this.state.unredNotifs.length + " Notification"  }</span>
            <div className="dropdown-divider"></div>
            <div  className="dropdown-item">
              <i className="fas fa-envelope mr-2"></i>
              {this.state.unredNotifs.length > 1 ? this.state.unredNotifs.length + " Nouveaux messages" :  this.state.unredNotifs.length + " Nouveau message"  } 
              {/* <span className="float-right text-muted text-sm">3 mins</span> */}
            </div>
            <div className="dropdown-divider"></div>
            {/* <div  className="dropdown-item">
              <i className="fas fa-users mr-2"></i> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
            </div> */}
            {/* <div className="dropdown-divider"></div> */}
            {/* <div  className="dropdown-item">
              <i className="fas fa-file mr-2"></i> 3 new reports
              <span className="float-right text-muted text-sm">2 days</span>
            </div> */}
            {/* <div className="dropdown-divider"></div> */}
            <div  className="dropdown-item dropdown-footer">See All Notifications</div>
          </div>
        </li>

        {/* CONTROL SIDEBAR */}
        <li className="nav-item">
          <div className="nav-link" data-widget="control-sidebar" data-slide="true" >
            <i className="fas fa-th-large"></i>
          </div>
        </li>
      </ul>
    </nav>
    )
  }
}

function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer,
    DashboardNotifsReduccer:state.DashboardNotifsReduccer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({DashbordNotifs : AC_LIST_DASHBOARD_NOTIFS},dispatch)
}
const HeaderComponent = connect(mapStateToProps,mapDispatchToProps)(Header);

export default HeaderComponent;
