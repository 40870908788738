import React from 'react';
import ReactTooltip from "react-tooltip";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Link } from "react-router-dom";
import { Validpassword } from '../../common/validate';
import { AC_RECOVER_PASSWORD } from '../../actions/settings';

class Recover extends React.Component {
  constructor(props){
    super(props);
    this.state=
        {
          password                     : '',
          confirmpassword              : '',
          passworderror                : false,
          confirmpassworderror         : false,
          validpassworderror           : "",
          validconfirmpassworderror    : "",
          passwordvalue                : false,
          confirmpasswordvalue         : false
        }
      }
      //validtion()
      validateform(){
        const email                 = localStorage.getItem("recoveremail");
        const password              = this.state.password;
        const confirmPassword       = this.state.confirmpassword;
        if(password){
          this.setState({passworderror:false})
        }
        else {
          this.setState({passworderror:true})
        }
        if(confirmPassword){
            this.setState({confirmpassworderror:false})
        }
          else {
            this.setState({confirmpassworderror:true})
        }
       if(password&&confirmPassword && password===confirmPassword){
          this.props.recoverPassword({password:password,email:email });
        }
      }
    //onchange values
      onchangeValue(event){
        const name        = event.target.name;
        const value       = event.target.value;
        //password
        if(name ==='password'){
         const isvalid=Validpassword(value);
         if(isvalid){
          this.setState({password:value,passworderror:false,validpassworderror:"",passwordvalue:true})

          }
        else{
          this.setState({password:value,passworderror:true,validpassworderror:"Enter valid Password",passwordvalue:false})
          }
         this.setState({password:value,passworderror:false})
        }
         //confirm password
        if(name ==='confirmPassword'){

           if(this.state.password===value){
             this.setState({confirmpassword:value,confirmpassworderror:false,validconfirmpassworderror:"",confirmpasswordvalue:true})

           }
           else{
             this.setState({confirmpassword:value,confirmpassworderror:false,validconfirmpassworderror:"Password does not match",confirmpasswordvalue:false})
           }
           this.setState({confirmpassword:value,confirmpassworderror:false})
        }
      }
  render() {
    return(
          <body className="hold-transition login-page">
            <div className="login-box">
              <div className="login-logo">
                <b>Car</b>&nbsp;Rental
              </div>
              <div className="card">
                <div className="card-body login-card-body">
                  <p className="login-box-msg">You are only one step a way from your new password, recover your password now.</p>
                    <div className="input-group mb-3">
                      <div style={{width:"85%"}}data-tip="Password pattern should be minimum 8 characters,one uppercase,one lowercase,one number,one special character" data-for='toolTip1' data-place='top'>
                      <input type="password" className="form-control"name="password" placeholder="Password" onChange={(e)=>this.onchangeValue(e)}/>
                      </div>
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    {this.state.passworderror?<label style={{color:"red"}}>Enter Password</label>:""}
                    {this.state.validpassworderror!==""?<label style={{color:"red"}}>{this.state.validpassworderror}</label>:""}
                    <div className="input-group mb-3">
                      <div data-tip="Confirm passsword should be same as new password" style={{width:"85%"}} data-for='toolTip1' data-place='top'>
                      <input type="password" className="form-control" name="confirmPassword" placeholder="Confirm Password"  onChange={(e)=>this.onchangeValue(e)}/><ReactTooltip id="toolTip1" />
                      </div>
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    {this.state.confirmpassworderror?<label style={{color:"red"}}>Reenter Password</label>:""}
                    {this.state.validconfirmpassworderror!==""?<label style={{color:"red"}}>{this.state.validconfirmpassworderror}</label>:""}
                      <div className="col-12">
                       <button type="submit" className="btn btn-primary btn-block" onClick={this.validateform.bind(this)}>Change password</button>
                      </div>
                    <div className="mt-3 mb-1">
                    <Link to='/' className="mt-3 mb-1">
                      <a href="">Login</a>
                    </Link>
                    </div>
                </div>
              </div>
            </div>
          </body>
    )
  }
}
function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({recoverPassword:AC_RECOVER_PASSWORD},dispatch)
}
const RecoverComponent = connect(mapStateToProps,mapDispatchToProps)(Recover);

export default RecoverComponent;
