import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success, Loader, Error  } from '../common/swal';


//Brand management in action type
const LIST_BRAND        = 'LIST_BRAND';
const ADD_BRAND         = 'ADD_BRAND';
const EDIT_BRAND        = 'EDIT_BRAND';
const VIEW_BRAND       = 'VIEW_BRAND';
const UPDATE_BRAND_DATA = 'UPDATE_BRAND_DATA';
const DELETE_BRAND      = 'DELETE_BRAND';

const ADD_MODELOPTION          = 'ADD_MODELOPTION';
const DELETE_MODELOPTION       = 'DELETE_MODELOPTION';
const LIST_MODELOPTION         = 'LIST_MODELOPTION';
const EDIT_MODELOPTION         = 'EDIT_MODELOPTION';

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_UPDATE_BRANDSTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateBrandStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: DELETE_BRAND, payload:data});
       }
    });
  };
}
export function AC_DELETE_BRAND(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteBrand,formData)
      .then(({ data }) => {
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
      } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: DELETE_BRAND, payload:data});
      }
    });
  }
}

export function AC_ADD_BRAND(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddBrand,formData)
      .then(({ data }) => {
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
      } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: ADD_BRAND, payload:data});
      }
    });
  };
}

export function AC_LIST_BRAND() {
  return function(dispatch) {
       Loader();
    return authAxios.get(URL.API.ListBrand)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
        }
        dispatch({type: LIST_BRAND, payload:data});
    });
  };
}

export function AC_VIEW_BRAND(formData) {
   Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewBrand,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
        }
        dispatch({type: VIEW_BRAND, payload:data});
    });
  };
}

export function AC_EDIT_BRAND(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditBrand,formData)
      .then(({ data }) => {
        if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
        }
        else{
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_BRAND, payload:data});
      }
    });
  };
}

export function AC_DELETE_MODELOPTION(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteModeloption,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        } else {
          Error(data.message)
        }
        dispatch({type: DELETE_MODELOPTION, payload:data});
      }
    });
  };
}
export function AC_ADD_MODELOPTION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddModeloption,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
            if(data.status) {
              Success(data.message)
            } else {
              Error(data.message)
            }
            dispatch({type: ADD_MODELOPTION, payload:data});
      }
    });
  };
}
export function AC_LIST_MODELOPTION() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListModeloption)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        dispatch({type: LIST_MODELOPTION, payload:data});
        }
    });
  };
}

export function AC_EDIT_MODELOPTION(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditModeloption,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
        if(data.status){
          Success(data.message)
        }
        else {
          Error(data.message)
        }
        dispatch({type: EDIT_MODELOPTION, payload:data});
      }
    });
  };
}


export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_BRAND_DATA, name:name,value:value})
  };
}
