const initialState = {
  carfeaturesList : [],
  carfeaturesCount : 0,
  carfeaturesInfo : {
    name    : '',
    icon    : '',
    status  : '',
    id      : ''
  }
}

function CarfeaturesReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_CARFEATURES":
      return Object.assign({}, state, {
        carfeaturesList       : action.payload.data,
        carfeaturesCount  : action.payload.count
      })
    case "VIEW_CARFEATURES":
      return Object.assign({}, state, {
        carfeaturesInfo  : action.payload.data,
      })
      case "DELETE_CARFEATURES":
        return Object.assign({}, state, {
          carfeaturesList      : action.payload.data,
          carfeaturesCount     : action.payload.count
        })
    case "EDIT_CARFEATURES":
      return Object.assign({}, state, {
        carfeaturesInfo  : action.payload.data,
      })
    case "UPDATE_CARFEATURES_DATA":
        return editcarfeaturesInfo(action,state);
    default:
      return state
  }
}

function editcarfeaturesInfo(action,state) {
  if(action.name === 'name') {
    return Object.assign({}, state, {
        carfeaturesInfo  : {
                    ...state.carfeaturesInfo,
                     name : action.value
                   }
    })
  }
  if(action.name === 'icon') {
    return Object.assign({}, state, {
        carfeaturesInfo  : {
                    ...state.carfeaturesInfo,
                     icon : action.value
                   }
    })
  }
  if(action.name === 'status') {
    return Object.assign({}, state, {
        carfeaturesInfo  : {
                    ...state.carfeaturesInfo,
                     status : action.value
                   }
    })
  }
  return 1;
}

export default CarfeaturesReducer;
