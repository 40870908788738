const initialState = {
    carLists : [],
    carCount : 0,
    carInfo  : {
      firstname    : '',
      lastname     : '',
      email        : '',
      number       :'',
      country      :'',
      zip          :'',
      caryear      :'',
      mileage      :'',
      id           : ''
    }
  }

  function CarReducer(state = initialState, action) {
    switch (action.type) {
      case "CAR_LISTS":
        return Object.assign({}, state, {
          carLists : action.payload.data,
          carCount : action.payload.count
        })
      default:
        return state
    }
  }

  export default CarReducer;
