const initialState = {
  userList: [],
  userListCount: 0,
  userId: '',
  userInfo: {
    firstname: '',
    lastname: '',
    gender: '',
    age: '',
    country: '',
    mobile: '',
    status: '',
    id: ''
  }
}
function UserReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_USER":
      return Object.assign({}, state, {
        userList: action.payload.data,
        userListCount: action.payload.count
      })

    case "LIST_OWNER":
      return Object.assign({}, state, {
        ownerList: action.payload.data,
        ownerListCount: action.payload.count
      })

    case "VIEW_PARKING":
      return Object.assign({}, state, {
        parking: action.payload.data,
        carCount: action.payload.count
    });

      
    case "VIEW_USER":
      return Object.assign({}, state, {
        userInfo: action.payload.data,
      })

    case "VIEW_OWNER":
      return Object.assign({}, state, {
        ownerInfo: action.payload.data,
      })

    case "DELETE_USER":
      return Object.assign({}, state, {
        userList: action.payload.data,
        userCount: action.payload.count
      })
    case "UPDATE_VERIFY":
      return Object.assign({}, state, {
        userList: action.payload.data,
      })
    case "UPDATE_LICENSE":

      return Object.assign({}, state, {
        userList: action.payload.data,
      })
    case "EDIT_USER":
      return Object.assign({}, state, {
        userInfo: action.payload.data,
      })
    case "UPDATE_USER_DATA":
      return editUserInfo(action, state);

    default:
      return state;
  }
}
function editUserInfo(action, state) {
  if (action.name === 'firstname') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        firstname: action.value
      }
    })
  }
  if (action.name === 'lastname') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        lastname: action.value
      }
    })
  }
  if (action.name === 'gender') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        gender: action.value
      }
    })
  }
  if (action.name === 'age') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        age: action.value
      }
    })
  }
  if (action.name === 'country') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        country: action.value
      }
    })
  }

  if (action.name === 'mobile') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        mobile: action.value
      }
    })
  }
  if (action.name === 'status') {
    return Object.assign({}, state, {
      userInfo: {
        ...state.userInfo,
        status: action.value
      }
    })
  }
  return 1;
}



export default UserReducer;
