import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success,Loader,Error } from '../common/swal';
//import { Success} from '../common/swal';

const VIEW_TRIP        = 'VIEW_TRIP';
const LIST_TRIP        = 'LIST_TRIP';
const UPDATE_TRIP_DATA = 'UPDATE_TRIP_DATA';
const UPDATE_TRIP      = "UPDATE_TRIP";

const accessToken=localStorage.getItem("token");

const authAxios=axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})

export function AC_VIEW_TRIP(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewTrip,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_TRIP, payload:data});
    });
  };
}

export function AC_TRIPS_LIST(tripstatus) {
  return function(dispatch) {
    Loader();
    return authAxios.post(URL.API.TripList,tripstatus)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: LIST_TRIP, payload:data});
    });
  };
}

export function AC_TRIPS_ACCEPT(tripsId){
  return function(dispatch){
    return authAxios.post(URL.API.AcceptTrips,{tripID:tripsId})
    .then(({data})=>{
      if(data.status){
        Success(data.message)
      }else{
        Error(data.message)
      }
      if(data.status===403){
        localStorage.removeItem("token");
        window.location='/';
      } 
      // else {
      //   dispatch({type: UPDATE_TRIP_DATA, payload:data});
      // }

    })
  }
}


export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_TRIP_DATA, name:name,value:value})
  };
}
