import axios from 'axios';
import swal from 'sweetalert';
import URL from '../common/api';
import { Success , Loader ,Error} from '../common/swal';

// Action types for Faq management
const LIST_FAQ        = 'LIST_FAQ';
const ADD_FAQ         = 'ADD_FAQ';
const EDIT_FAQ        = 'EDIT_FAQ';
const VIEW_FAQ        = 'VIEW_FAQ';
const UPDATE_FAQ_DATA = 'UPDATE_FAQ_DATA';
const DELETE_FAQ      = 'DELETE_FAQ';
const UPDATE_STATUS   = 'UPDATE_STATUS';

const accessToken = localStorage.getItem("token");

const authAxios = axios.create({
  headers:{
    Authorization:'bearer '+accessToken
  }
})
const redirect = redirectUrl => {
  window.location = redirectUrl;
};
export function AC_DELETE_FAQ(id) {
  var formData = {id:id}
  return function(dispatch) {
    return authAxios.post(URL.API.DeleteFaq,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          if(data.status){
            Success(data.message)
          } else {
            Error(data.message)
          }
          dispatch({type: DELETE_FAQ, payload:data});
       }
    });
  };
}

export function AC_UPDATE_FAQSTATUS(data) {
  return function(dispatch) {
    return authAxios.post(URL.API.UpdateFaqStatus,data)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        } else {
          dispatch({type: UPDATE_STATUS, payload:data});
       }
    });
  };
}

export function AC_ADD_FAQ(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.AddFaq,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        else{
        if(data.status){
          Success(data.message)
        } else {
          Error(data.message)
        }
        dispatch({type: ADD_FAQ, payload:data});
      }
    });
  };
}
export function AC_LIST_FAQ() {
  return function(dispatch) {
    Loader();
    return authAxios.get(URL.API.ListFaq)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
        localStorage.removeItem("token");
        return redirect('/');
        }
        dispatch({type: LIST_FAQ, payload:data});
    });
  };
}

export function AC_VIEW_FAQ(formData) {
  Loader();
  const id = { id : formData }
  return function(dispatch) {
    return authAxios.post(URL.API.ViewFaq,id)
      .then(({ data }) => {
        swal.close()
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        dispatch({type: VIEW_FAQ, payload:data});
    });
  };
}

export function AC_EDIT_FAQ(formData) {
  return function(dispatch) {
    return authAxios.post(URL.API.EditFaq,formData)
      .then(({ data }) => {
        if(data.status===403){
          localStorage.removeItem("token");
          window.location='/';
        }
        else{
        if(data.status){
          Success(data.message)
        } else {
          Error(data.message)
        }
        dispatch({type: EDIT_FAQ, payload:data});
      }
    });
  };
}

export function AC_HANDLE_INPUT_CHANGE(name,value) {
  return function(dispatch) {
    dispatch({type: UPDATE_FAQ_DATA, name:name,value:value})
  };
}
