import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_ADD_NOTIFICATION } from '../../actions/notificationaction';

class AddNotification extends React.Component {
 // Constructor
 constructor(props) {
  super(props);
  this.state = {
    notificationname       : '',
    description            : '',
    emailnotifier          : false,
    smsnotifier            : false,
    appnotifier            : false,
    notificationnameerror  : false,
    descriptionerror       : false
  }
     this.validateForm=this.validateForm.bind(this);
}
//Validation
 validateForm() {
    const notificationname  = this.state.notificationname;
    const description       = this.state.description;
    const emailnotifier     = this.state.emailnotifier;
    const smsnotifier       = this.state.smsnotifier;
    const appnotifier       = this.state.appnotifier;
    if(notificationname) {
       this.setState({notificationnameerror : false})
    }
    else{
       this.setState({notificationnameerror : true})
    }
    if(description){
        this.setState({descriptionerror:false});
    }
    else{
        this.setState({descriptionerror:true});
    }
    const name          =  notificationname.charAt(0).toUpperCase() + notificationname.slice(1);
    const Description   =  description.charAt(0).toUpperCase() + description.slice(1);
   if(notificationname && description) {
    //Formdata to send Api
      this.setState({notificationname:"",description:"",});
      const notificationFormData = {
                            notificationname          : name,
                            description               : Description,
                            emailnotifier             : emailnotifier,
                            smsnotifier               : smsnotifier,
                            appnotifier               : appnotifier
                          }
     this.props.AddNotification(notificationFormData);
   }
 }
 // Onchange events
  onChangeValue(event){
    const name=event.target.name;
    const value=event.target.value;
    if(name ==='notificationname'){
      if(value){
        this.setState({notificationname:value,notificationnameerror:false})
      } else {
        this.setState({notificationname:value,notificationnameerror:true})
      }
    }
   if(name ==='description'){
     if(value){
       this.setState({description:value,descriptionerror:false})
     } else {
       this.setState({description:value,descriptionerror:true})
     }
   }
   if(name==='emailnotifier')
   {
    this.setState({emailnotifier:event.target.checked})
   }
   if(name==='smsnotifier')
   {
    this.setState({smsnotifier:event.target.checked})
   }
   if(name==='appnotifier')
   {
    this.setState({appnotifier:event.target.checked})
   }
  }
 render() {
  return(
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
            <h1>Add Notifications</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Home</li>
                <li className="breadcrumb-item active">Notifications</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Notifications</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                       <label>Notification Name</label><span style={{color:"red"}}>*</span>
                       <input type="text" className="form-control" name="notificationname" placeholder="Enter Name" pattern="[A-Za-z]" value={this.state.notificationname} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.notificationnameerror ? <label style={{color:"red"}}>Notification Name is Required</label> : ""}
                    </div>
                    <div className="form-group">
                       <label>Description</label><span style={{color:"red"}}>*</span>
                       <textarea className="form-control" name="description" placeholder="Enter Description" pattern="[A-Za-z]" value={this.state.description} onChange ={(e) => this.onChangeValue(e)}/>
                       {this.state.descriptionerror ? <label style={{color:"red"}}>Description is Required</label> : ""}
                    </div>
                    <label>Email Notifier<input type="checkbox" name="emailnotifier" className="form-control" style={{width:"25px"}} value={this.state.emailnotifier} onChange ={(e) => this.onChangeValue(e)}/></label>
                    <br/>
                    <label>SMS Notifier<input type="checkbox" name="smsnotifier" className="form-control" style={{width:"25px"}} value={this.state.smsnotifier} onChange ={(e) => this.onChangeValue(e)}/></label>
                    <br/>
                    <label>App Notifier<input type="checkbox" name="appnotifier" className="form-control" style={{width:"25px"}} value={this.state.appnotifier} onChange ={(e) => this.onChangeValue(e)}/></label>
                    <div>
                    </div>
                     <div className="card-footer">
                     <button type="button" className="btn btn-primary" onClick={this.validateForm}>Submit</button>
                     </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

function mapStateToProps(state) {
    return {
      NotificationReducer : state.NotificationReducer
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({AddNotification: AC_ADD_NOTIFICATION},dispatch)
  }
  const AddNotificationComponent = connect(mapStateToProps,mapDispatchToProps)(AddNotification);
  
  export default AddNotificationComponent;