import React from 'react';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_GET_ADMIN_SETTINGS } from './actions/settings';

// Layouts Components
import Header from './components/layouts/header';
import Sidebar from './components/layouts/sidebar';
import Footer from './components/layouts/footer';

// Dashboard Components
import Dashboard from './components/dashboard/dashboard';

// ag grid
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

// Admin Settings  Components
import SiteSettings from './components/adminSettings/sitesettings';
import SocialMedia from './components/adminSettings/socialmedia';
import SmtpSetting from './components/adminSettings/smtpsettings';
import GoogleWebmaster from './components/adminSettings/googlewebmaster';
import AppSettings from './components/adminSettings/appsettings';
import ProfileSettings from './components/adminSettings/profilesettings';

// Authorization
import Recover from './components/authorization/newpassword';
import Forgotpassword from './components/authorization/forgotpassword';
import Changepassword from './components/adminSettings/changepassword';
import Login from './components/authorization/login';

// Faq Components
import FaqList from './components/faq/faqlist';
import AddFaq from './components/faq/addfaq';
import ViewFaQ from './components/faq/viewfaq';
import EditFaq from './components/faq/editfaq';

// Contact Us components
import ContactUs from './components/contactus/contactus';

// Language Components
import LanguageList from './components/languages/languagelist';
import Addlanguages from './components/languages/addlanguage';
import ViewLanguage from './components/languages/viewlanguage';
import EditLanguage from './components/languages/editlanguage';

// CarTypes Components
import AddCarType from './components/carTypes/addcartype';
import ListCarType from './components/carTypes/listcartype';
import ViewCarType from './components/carTypes/viewcartype';
import EditCarType from './components/carTypes/editcartype';

//CarList Components
import CarList from './components/carlist/carlist';
import ViewCar from './components/carlist/viewcar';
import EditCar from './components/carlist/editcar';

//BrandName componentss
import AddBrand from './components/brand/addbrand';
import ListBrand from './components/brand/listbrand';
import ViewBrand from './components/brand/viewbrand';
import EditBrand from './components/brand/editbrand';

//Subscriptions List components
import SubscriptionsList from './components/subscriptionslist/subscriptionslist';

//Country Components
import AddCountry from './components/country/addcountry';
import ListCountry from './components/country/listcountry';
import ViewCountry from './components/country/viewcountry';
import EditCountry from './components/country/editcountry';

//User Components
import UserList from './components/userList/userlist';
import ViewUser from './components/userList/viewuser';
import ViewOwner from './components/userList/viewOwner';
import EditUser from './components/userList/edituser';
import OwnerList from './components/userList/ownerList';
//News letter Components
import AddNewsletter from './components/newsletter/addnewsletter';
import ListNewsletter from './components/newsletter/listnewsletter';
import ViewNewsletter from './components/newsletter/viewnewsletter';
import EditNewsletter from './components/newsletter/editnewsletter';

//List car Components
import ListyourCar from './components/listyourcar/listyourcar';

//Trips Components
import PastTrips from './components/trips/pasttrips';
import ViewTrips from './components/trips/viewtrips';
import NewTrips from './components/trips/newtrips';

//Rented car Components
import OldRentedCar from './components/rentedcar/oldrentedcar';
import ViewRentedCar from './components/rentedcar/viewrentedcar';
import NewRentedCar from './components/rentedcar/newrentedcar';

//Url Not found
import UrlNotFound from './components/urlnotfound/urlnotfound';

//Configuration Components
import AddConfiguration from './components/configuration/addconfiguration';
import ListConfiguration from './components/configuration/listconfiguration';
import ViewConfiguration from './components/configuration/viewconfiguration';
import EditConfiguration from './components/configuration/editconfiguration';

//configoption Components
import Addconfigoption from './components/configuration/addconfigoption';

//Carfeatures Components
import AddCarfeatures from './components/carfeatures/addcarfeatures';
import ListCarfeatures from './components/carfeatures/listcarfeatures';
import ViewCarfeatures from './components/carfeatures/viewcarfeatures';
import EditCarfeatures from './components/carfeatures/editcarfeatures';

//Notifications Components
import AddNotification from './components/notifications/addnotifications';
import ListNotification from './components/notifications/listnotifications';
import ViewNotification from './components/notifications/viewnotifications';
import EditNotification from './components/notifications/editnotifications';
import ListDashboardNotification from "./components/notifications/dashboardnotification"

//Pages Components
import AddPages from './components/pages/addpages';
import ListPages from './components/pages/listpages';
import ViewPages from './components/pages/viewpages';
import EditPages from './components/pages/editpages';

import AddPayementgateway from './components/payementgateway/addpayementgateway';
import ListPayementgateway from './components/payementgateway/listpayementgateway';
import ViewPayementgateway from './components/payementgateway/viewpayementgateway';
import EditPayementgateway from './components/payementgateway/editpayementgateway';
// Currency Components
import AddCurrency from './components/currency/addcurrency';
import ListCurrency from './components/currency/listcurrency';
import ViewCurrency from './components/currency/viewcurrency';
import EditCurrency from './components/currency/editcurrency';

let isAuth = localStorage.getItem("token");
/*eslint no-useless-constructor: "error"*/
/*eslint-env es6*/


class Home extends React.Component{
  componentDidMount(){
    const token    =   localStorage.getItem("token");
    if(token) {
      this.props.GetAdminSettings();
   }
  }
  render() {
    const token    =   this.props.SettingsReducer.token;
    if(!isAuth && !token) {
    return (
            <Router>
                <Route exact path="/" component={Login}/>
                <Route exact path="/forgotPassword" component={Forgotpassword}/>
                <Route exact path="/recover" component={Recover}/>
            </Router>
          )
    }
  return (
    <Router>
       <div className="wrapper">
        <Header/>
        <Sidebar/>
          <Switch>
              <Route exact path="/" component={Dashboard}/>
              <Route exact path="/dashboard" component={Dashboard}/>
              <Route exact path="/siteSettings" component={SiteSettings}/>
              <Route exact path="/socialMedia" component={SocialMedia}/>
              <Route exact path="/googleWebMaster" component={GoogleWebmaster}/>
              <Route exact path="/appSettings" component={AppSettings}/>
              <Route exact path="/changePassword" component={Changepassword}/>
              <Route exact path="/profileSettings" component={ProfileSettings}/>
              <Route exact path="/smtpSettings" component={SmtpSetting}/>
              <Route exact path="/listFaq" component={FaqList}/>
              <Route exact path="/addFaq" component={AddFaq}/>
              <Route exact path="/viewFaq/:id" component={ViewFaQ}/>
              <Route exact path="/editFaq/:id" component={EditFaq}/>
              <Route exact path="/listLanguage" component={LanguageList}/>
              <Route exact path="/addLanguage" component={Addlanguages}/>
              <Route exact path="/viewLanguage/:id" component={ViewLanguage}/>
              <Route exact path="/editLanguage/:id" component={EditLanguage}/>
              <Route exact path="/contactUs" component={ContactUs}/>
              <Route exact path="/addCarType" component={AddCarType}/>
              <Route exact path="/listCarType" component={ListCarType}/>
              <Route exact path="/viewCarType/:id" component={ViewCarType}/>
              <Route exact path="/editCarType/:id" component={EditCarType}/>
              <Route exact path="/carList" component={CarList}/>
              <Route exact path="/viewCar/:id" component={ViewCar}/>
              <Route exact path="/editCar/:id" component={EditCar}/>
              <Route exact path="/subscriptionsList" component={SubscriptionsList}/>
              <Route exact path="/addCountry" component={AddCountry}/>
              <Route exact path="/listCountry" component={ListCountry}/>
              <Route exact path="/viewCountry/:id" component={ViewCountry}/>
              <Route exact path="/editCountry/:id" component={EditCountry}/>
              <Route exact path="/userList" component={UserList}/>
              <Route exact path="/ownerList" component={OwnerList}/>
              <Route exact path="/viewUser/:id" component={ViewUser}/>
              <Route exact path="/editUser/:id" component={EditUser}/>
              <Route exact path="/viewOwner/:id" component={ViewOwner}/>
              <Route exact path="/addNewsletter" component={AddNewsletter}/>
              <Route exact path="/viewNewsletter/:id" component={ViewNewsletter}/>
              <Route exact path="/listNewsletter" component={ListNewsletter}/>
              <Route exact path="/editNewsletter/:id" component={EditNewsletter}/>
              <Route exact path="/listYourCar" component={ListyourCar}/>
              <Route exact path="/addBrand" component={AddBrand}/>
              <Route exact path="/listBrand" component={ListBrand}/>
              <Route exact path="/viewBrand/:id" component={ViewBrand}/>
              <Route exact path="/editBrand/:id" component={EditBrand}/>
              <Route exact path="/pastTrips" component={PastTrips}/>
              <Route exact path="/viewTrip/:id" component={ViewTrips}/>
              <Route exact path="/newTrips" component={NewTrips}/>
              <Route exact path="/newRentedCar" component={NewRentedCar}/>
              <Route exact path="/viewRentedCar/:id" component={ViewRentedCar}/>
              <Route exact path="/oldRentedCar" component={OldRentedCar}/>
              <Route exact path="/addConfiguration" component={AddConfiguration}/>
              <Route exact path="/listConfiguration" component={ListConfiguration}/>
              <Route exact path="/viewConfiguration/:id" component={ViewConfiguration}/>
              <Route exact path="/editConfiguration/:id" component={EditConfiguration}/>
              <Route exact path="/addconfigoption/:id" component={Addconfigoption}/>
              <Route exact path="/addCarfeatures" component={AddCarfeatures}/>
              <Route exact path="/listCarfeatures" component={ListCarfeatures}/>
              <Route exact path="/viewCarfeatures/:id" component={ViewCarfeatures}/>
              <Route exact path="/editCarfeatures/:id" component={EditCarfeatures}/>
              <Route exact path="/addNotification" component={AddNotification}/>
              <Route exact path="/listNotification" component={ListNotification}/>
              <Route exact path="/dashboardnotifications" component={ListDashboardNotification}/>
              <Route exact path="/viewNotification/:id" component={ViewNotification}/>
              <Route exact path="/editNotification/:id" component={EditNotification}/>
              <Route exact path="/addPages" component={AddPages}/>
              <Route exact path="/listPages" component={ListPages}/>
              <Route exact path="/viewPages/:id" component={ViewPages}/>
              <Route exact path="/editPages/:id" component={EditPages}/>
              <Route exact path="/addPayementgateway" component={AddPayementgateway}/>
              <Route exact path="/listPayementgateway" component={ListPayementgateway}/>
              <Route exact path="/viewPayementgateway/:id" component={ViewPayementgateway}/>
              <Route exact path="/editPayementgateway/:id" component={EditPayementgateway}/>
              <Route exact path="/addCurrency" component={AddCurrency}/>
              <Route exact path="/listCurrency" component={ListCurrency}/>
              <Route exact path="/viewCurrency/:id" component={ViewCurrency}/>
              <Route exact path="/editCurrency/:id" component={EditCurrency}/>
              <Route component={UrlNotFound}/>
          </Switch>
        <Footer/>
      </div>
    </Router>
  );
 }
}
function mapStateToProps(state) {
  return {
    SettingsReducer : state.SettingsReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({GetAdminSettings: AC_GET_ADMIN_SETTINGS},dispatch)
}
const App = connect(mapStateToProps,mapDispatchToProps)(Home);

export default App;
