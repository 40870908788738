import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect  } from "react-router-dom";
import { AC_TRIPS_LIST } from '../../actions/tripaction';

class PastTrips  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDef: [
        {
          headerName: "#", valueGetter: "node.rowIndex + 1", width: '50',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        {
          headerName: "Owner Name", field: "ownerId.firstname", width: '200',
          cellStyle: function(params) {
                 return {'font-size': '16px','margin-top': '5px'};
           }
        },
        { headerName: "Customer Name", field: "customerId.firstname", width: '200',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { headerName: "Check-in", field: "checkin", width: '200',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
        { headerName: "Check-out", field: "checkout", width: '200',
          cellStyle: function(params) {
               return {'font-size': '16px','margin-top': '5px'};
         }},
         { headerName: "Status", field: "status", width: '100',
           cellStyle: function(params) {
                return {'font-size': '16px','margin-top': '5px'};
           },
           cellRenderer: function(params) {
            if(params.data.tripStatus===2) {
              return '<span class="badge badge-success">completed</span>';
            } else if(params.data.tripStatus===4){
              return '<span class="badge badge-success" style="background-color: #ffc107!important;color:black;" data-action-type="Status">Pending</span>';
            } else {
              return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);" data-action-type="Status">Declined</span>';
            }
          }
         },
        { width: "300",sortable: false,filter:false,
         template:
           `<div>
              <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
                <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
                View
              </div>
            </div>`,
           cellStyle: function(params) {
                  return {'margin-top' :'8px'};
          },
       },
      ],

    }
  }

  componentDidMount() {
    var tripStatus = { tripStatus : 2};
    this.props.TripsList(tripStatus);
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
  }
  render() {
    const tripList = this.props.TripReducer.tripList;
    // const tableTripList = [];
    if(this.props.TripReducer.tripList) {
      // const tripList = this.props.TripReducer.tripList;
      if(this.state.redirect === 'View'){
        return <Redirect to ={'/viewTrip/'+this.state.id}/>
      }
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Past Trips</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Past Trips</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Trips</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div
                className="ag-theme-balham"
                style={{ height: '600px', width: 'auto' }}>
                <AgGridReact
                  rowHeight={55}
                  rowClass={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  rowSelection={'single'}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDef}
                  defaultColDef={{filter:true,floatingFilter:true}}
                  rowData={tripList}>
                </AgGridReact>
              </div >
            </div>
          </div>
        </section>
        </div>

    )
  }
}

function mapStateToProps(state) {
      return {
            TripReducer : state.TripReducer
      };
    }
function mapDispatchToProps(dispatch) {
      return bindActionCreators({TripsList: AC_TRIPS_LIST},dispatch)
}
const PastTripComponent = connect(mapStateToProps,mapDispatchToProps)(PastTrips);

export default PastTripComponent;
