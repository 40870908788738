import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Redirect  } from "react-router-dom";
import { bindActionCreators } from 'redux';
import swal from 'sweetalert';
import {AC_LIST_USER,AC_DELETE_USER,AC_USER_VERIFY} from '../../actions/useraction';

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs :[
        { "headerName" : "#",field:"#", valueGetter :"node.rowIndex+1",width:"50",
          cellStyle: function(params) {
               return {'fontSize': '16px','marginTop': '5px'};
         }},
        { "headerName" : "Prénoms", field :"firstname",width:"200",
          cellStyle: function(params) {
               return {'fontSize': '16px','marginTop': '5px'};
         }},
        { "headerName" : "Nom", field :"lastname",width:"200",
          cellStyle: function(params) {
               return {'fontSize': '16px','marginTop': '5px'};
         }},
        { "headerName" : "Adresse Email", field :"email",width:"250",
          cellStyle: function(params) {
               return {'fontSize': '16px','marginTop': '5px'};
         }},
        // { "headerName" : "status", field :"status",width:"100",
        //   cellStyle: function(params) {
        //        return {'fontSize': '16px','marginTop': '5px'};
        //   },
        //   cellRenderer: function(params) {
        //    if(params.data.status) {
        //      return '<span class="badge badge-success">Active</span>';
        //    } else {
        //      return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);">Inactive</span>';
        //    }
        //  }
        // },
        { "headerName" : "Statut", field :"userverified",width:"200",
          cellStyle: function(params) {
               return {'fontSize': '16px','marginTop': '5px'};
          },
          cellRenderer: function(params) {
           if(params.data.verification === 1) {
             return '<span class="badge badge-success">Verified</span>';
           } else {
             return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);">Not verified</span>';
           }
         }
        },
        { "headerName" : "Enregistré depuis", field :"registrationPlatform",width:"200",
          cellStyle: function(params) {
               return {'fontSize': '16px','marginTop': '5px'};
          },
          cellRenderer: function(params) {
           if(params.data.registrationPlatform != null) {
             return `<span class="badge badge-success">${params.data.registrationPlatform}</span>`;
           } else {
             return '<span class="badge badge-success" style="background-color: rgb(220, 53, 69);">-</span>';
           }
         }
        },        
        { headerName: 'Actions', width: "100",sortable: false,filter:false,
          template:
           `<div>
           <div class="btn btn-info btn-sm" href='#' style={{marginRight:"10px"}} data-action-type="View">
           <i class="fas fa-folder"  style={{padding: "10px"}} data-action-type="View"></i>
           Voir
           </div>
           </div>`,
           cellStyle: function(params) {
                  return {'marginTop' :'8px'};
          },
       },
      ]
    }
  }

    deleteUser(id) {
      swal({
           title: "Are you sure?",
           text: "Once deleted, you will not be able to recover this imaginary file!",
           icon: "warning",
           buttons: true,
           dangerMode: true,
         })
         .then((willDelete) => {
           if (willDelete) {
             this.props.DeleteUser(id);
           } else
           {
             console.log('-=-=-=-=-=cancel-=-=-=-=',);
           }
     });
    }
  componentDidMount(){
    this.props.UserList();
  }
  onSortChanged = e => {
    e.api.refreshCells();
  }
  onRowClicked = event =>{
    const rowValue = event.event.target;
    const value    = rowValue.getAttribute('data-action-type');
    if(value === 'View'){
      this.setState({redirect:'View',id:event.data._id})
    }
    if(value === 'Edit'){
      this.setState({redirect:'Edit',id:event.data._id})
    }
    if(value === 'Delete'){
      this.deleteUser(event.data._id)
    }
    if(value === 'Verify'){
      this.updateVerify(event.data);
    }
  }
  render() {
    // const userListCount     = this.props.UserReducer.userListCount;
    const userList          = this.props.UserReducer.userList;
    // const userListArray     = [];
    if(this.state.redirect === 'Edit'){
      return <Redirect to ={'/EditUser/'+this.state.id}/>
    }
    if(this.state.redirect === 'View'){
      return <Redirect to ={'/ViewUser/'+this.state.id}/>
    }
    return(
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Listes des utilisateurs</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active">Listes des utilisateurs</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Users</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                  <i className="fas fa-minus"></i></button>
                <button type="button" className="btn btn-tool" data-card-widget="remove" data-toggle="tooltip" title="Remove">
                  <i className="fas fa-times"></i></button>
              </div>
            </div>
            <div className="card-body p-0">
            <div className="ag-theme-balham" style={ {height: '600px', width: 'auto'} }>
              <AgGridReact
                  rowHeight={50}
                  rowClass ={'centerAlign'}
                  onSortChanged={this.onSortChanged}
                  onRowClicked={(e)=>this.onRowClicked(e)}
                  rowSelection={'single'}
                  enableSorting
                  suppressCellSelection={true}
                  enableFilter
                  enableColResize
                  pagination={true}
                  paginationAutoPageSize={true}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={{filter:true,floatingFilter:true}}
                  rowData={userList}>
              </AgGridReact>
            </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
function mapStateToProps(state) {
    return {
    UserReducer : state.UserReducer
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({UserList:AC_LIST_USER,DeleteUser:AC_DELETE_USER,UpdateUserVerify:AC_USER_VERIFY},dispatch);
}
const UserListComponent = connect(mapStateToProps,mapDispatchToProps)(UserList);

export default UserListComponent;
